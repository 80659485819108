import { takeEvery, takeLatest, put, select, call, cancelled } from "redux-saga/effects";
import axios from 'axios';
import log from 'loglevel';
import { logCentral } from '../services/log-central';
import md5 from 'md5';
import { isNumber } from 'lodash';

import PELatLngBounds from 'classes/PELatLngBounds';

import { actionTypes, actions } from 'actions/searchActions';
import { actionTypes as mapActionTypes, actions as mapActions } from 'actions/mapActions';
import { actions as locationActions } from 'actions/locationActions';
import { actions as rootActions } from 'actions/rootActions';

import { parseCoordinate } from  'hooks/coordinate-parser';
import { requestTimeZoneElevation } from 'sagas/mapSaga';
import { getCredentialsMap } from 'reselectors/map';
import { getGeocodingApiKey, getSearchResults } from 'selectors';

/** Returns an axios request for geocoding discovery details */
function getDiscoveryRequest(credentials) {
  
  return axios.request({
    method: 'get',
    url: process.env.REACT_APP_CROOKNECK_GEO_BASE_URL + '/discover/' + credentials.user,
    headers: {
      'Accept': 'application/json',
      'Authorization': credentials.token
    }
  });
}

/**
 * Creates an Axios request for the given parameters
 * @param {String} requestName must be one of [suggest|geocode|reverse]
 * @param {String} apiKey api key for crookneck-geo
 * @param {String} searchTerm term to search for; string for suggest and geocode requests, comma-separated lat/lng for reverse
 * @param {String} lang preferred language for results
 */
function getGeocodingRequest(requestName, apiKey, searchTerm, lang = 'en') {

  let params = { 
    'project' : process.env.REACT_APP_GEOCODING_PROJECT_ID // required in order to obtain a project key for crookneck-geo
  };

  return axios.request({
    method: 'get',
    url: process.env.REACT_APP_CROOKNECK_GEO_BASE_URL + '/' + requestName + '/' + searchTerm,
    headers: {
      'Accept': 'application/json',
      'Authorization': apiKey,
      'Accept-Language': lang
    },
    params: params,
    validateStatus: function (status) {
      return (status >= 200 && status < 300) || status === 404; // don't error on 404s
    }
  });
}

/** Returns an axios request for forward geocoding search */
function getLookupRequest(apiKey, placeId) {
  
  let params = { 
    'project' : process.env.REACT_APP_GEOCODING_PROJECT_ID // required in order to obtain a project key for crookneck-geo
  };

  return axios.request({
    method: 'get',
    url: process.env.REACT_APP_CROOKNECK_GEO_BASE_URL + '/lookup/' + placeId,
    headers: {
      'Accept': 'application/json',
      'Authorization': apiKey
    },
    params: params
  });
}

/////////////////////////////////
// Generators
/////////////////////////////////

/**
 * Issues a discovery request to Crookneck-Geo to obtain API key and service behaviour details
 * then dispatches an action to update redux state with the result, if successful.
 * If request fails, then state is not updated and no further action is dispatched.
 */
function* discoverServiceDetails() {

  try {

    log.debug('searchSaga.discoverServiceDetails');

    // get credentials for the timezone service
    let credentialsMap = yield select(getCredentialsMap);

    let discoveryCredentials = credentialsMap.get('geocoding');

    if (!discoveryCredentials) {
      throw new Error('Cannot discover geocoding service details: credentials required');
    }

    // data is obtained after axios call is resolved
    const discoveryResponse = yield call(getDiscoveryRequest, discoveryCredentials);
     
    if (discoveryResponse.data) {
      yield put(actions.receivedServiceCredentials(discoveryResponse.data));
    } else {
      log.error('searchSaga.discoverServiceDetails: discoveryResponse.data is missing');
    }

  } catch (e) {
    if (e.message === 'Network Error') {
      log.error(e);
    } else {
      logCentral.error('Search - discover service details failed', e);
    }
  } finally {
    // because we're using takeLatest, if the primary map pin is moved again before this generator completes, then 
    // redux-saga will cancel any previous calls that are still running. Hence, we should not need to worry about handling 
    // out-dated responses
    if (yield cancelled()) {
      log.debug('searchSaga.discoverServiceDetails: cancelled');
    }
  }  
}

function* suggestSaga(searchTerm) {

  try {

    log.debug('searchSaga.suggestSaga: %s', searchTerm);
    const apiKey = yield select(getGeocodingApiKey);

    if (!apiKey) {
      log.warn('No search api key available');
      return [];
    }

    // This request does not throw on a 404 (see getSuggestRequest), so make sure to handle that case below
    const autoCompleteResponse = yield call(getGeocodingRequest, 'suggest', apiKey, searchTerm, navigator.language);  

    if (autoCompleteResponse.status === 200 && Array.isArray(autoCompleteResponse.data)) {
      return autoCompleteResponse.data;
    } else {
      log.warn('No suggestions for ' + searchTerm);
      return [];
    }

  } catch (e) {
    if (e.message === 'Network Error') {
      log.error(e);
    } else {
      logCentral.error('Search autocomplete error', e);
    }
  } finally {
    if (yield cancelled()) {
      log.debug('searchSaga.suggestSaga cancelled');
    }
  }
}

function* forwardGeocodeSaga(searchTerm) {

  try {

    log.debug('searchSaga.forwardGeocodeSaga: %s', searchTerm);
    const apiKey = yield select(getGeocodingApiKey);

    if (!apiKey) {
      log.warn('No search api key available');
      return [];
    }

    // This request does not throw on a 404, so make sure to handle that case below
    const response = yield call(getGeocodingRequest, 'geocode', apiKey, searchTerm, navigator.language);  

    if (response.status === 200 && Array.isArray(response.data)) {
      return response.data;
    } else {
      log.warn('No results for ' + searchTerm);
      return [];
    }

  } catch (e) {
    if (e.message === 'Network Error') {
      log.error(e);
    } else {
      logCentral.error('Forward geocode error', e);
    }
  } finally {
    if (yield cancelled()) {
      log.debug('searchSaga.forwardGeocodeSaga cancelled');
    }
  }
}

function* reverseGeocodeSaga(searchTerm) {

  try {

    log.debug('searchSaga.reverseGeocodeSaga: %s', searchTerm);
    const apiKey = yield select(getGeocodingApiKey);

    if (!apiKey) {
      log.warn('No search api key available');
      return [];
    }

    // This request does not throw on a 404 , so make sure to handle that case below
    const response = yield call(getGeocodingRequest, 'reverse', apiKey, searchTerm, navigator.language);  

    if (response.status === 200 && Array.isArray(response.data)) {
      return response.data;
    } else {
      log.warn('No results for ' + searchTerm);
      return [];
    }

  } catch (e) {
    if (e.message === 'Network Error') {
      log.error(e);
    } else {
      logCentral.error('Reverse geocode error', e);
    }
  } finally {
    if (yield cancelled()) {
      log.debug('searchSaga.reverseGeocodeSaga cancelled');
    }
  }
}

function* handleSearchInputChanged(action) {

  yield put(actions.setSearchResultsPending(true));

  try {
    const { searchTerm, intents } = action.payload;
    
    let suggestions = [];

    if (!searchTerm || searchTerm.length === 0) {
      throw new Error('Missing search term - cannot handle search input');
    }

    if (intents.includes('parse')) {
      // Is it a coordinate we can parse?
      const parsed = yield call(parseCoordinate, searchTerm.trim());
      if (parsed) {
        suggestions.push({
          lat: parsed.lat,
          lon: parsed.lng,
          display_name: searchTerm,
          place_id: md5(parsed) // synthesize an id so we can preserve selection
        })
        
        logCentral.event({
          category: "Search",
          action: "search_coordinate_parsed"
        });
      }    
    }
    
    if (0 === suggestions.length) {  
      // Nothing was parsed, so make a network call
      if (intents.includes('suggest')) {
        // Get autocomplete suggestions
        suggestions = yield call(suggestSaga, searchTerm.trim());
        
        logCentral.event({
          category: "Search",
          action: "search_autocomplete",
        });
      } else if (intents.includes('forward')) {
        
        // Get forward geocoding results
        suggestions = yield call(forwardGeocodeSaga, searchTerm.trim());
        
        logCentral.event({
          category: "Search",
          action: "search_forward_geocoding"
        });
      }
    } else if (intents.includes('reverse')) {

      // Reverse geocode the first suggestion - which must have been a parsed coordiante - see above
      const coordString = suggestions[0].lat.toFixed(6) + ',' + suggestions[0].lon.toFixed(6)
      const reverseResults = yield call(reverseGeocodeSaga, coordString)
      if (suggestions) {
        suggestions = [ ...suggestions, ...reverseResults];
      } else {
        suggestions = reverseResults;
      }
      logCentral.event({
        category: "Search",
        action: "search_reverse_geocoding"
      });
    }
      
    // Get current selection, if any
    const currentResults = yield select(getSearchResults);
    let selectedResult = currentResults.find(result => result.isSelected);
    
    // Is the current selection still in our results?
    var selectedSuggestion;
    if (selectedResult && selectedResult.place_id) {
      selectedSuggestion = suggestions.find(suggestion => suggestion.place_id === selectedResult.place_id);
    }
    
    // If so, retain the selection
    if (selectedSuggestion) {
      selectedSuggestion.isSelected = true;
    } else if (suggestions && 0 < suggestions.length) {
      // Nothing was previously selected, or previous selection is no longer represented, so select the first new suggestion automatically
      suggestions[0].isSelected = true;
    }

    // action to handle the results
    yield put(actions.submitSearchResults(suggestions || []));

    // actions.selectSearchResult(props.result.place_id)
    
  } catch (e) {
    if (e.message === 'Network Error') {
      log.error(e);
    } else {
      logCentral.error('Handle search input changed failed', e);
    }
  } finally {
    if (yield cancelled()) {
      log.debug('searchSaga.handleSearchInputChanged: cancelled');
    }
    yield put(actions.setSearchResultsPending(false));
  }
  
}

/**
 * Query API for the coordinates corresponding to a the given placeId
 * @param {string} placeId a placeId
 * @returns 
 */
function* getCoordinateForPlaceId(placeId) {

  let result;

  // We need to make a call to obtain the lat/lng
  try {

    const apiKey = yield select(getGeocodingApiKey);

    if (!apiKey) {
      log.warn('No search api key available');
      return;
    }

    const lookupResponse = yield call(getLookupRequest, apiKey, placeId);  

    if (lookupResponse.data && Array.isArray(lookupResponse.data) && lookupResponse.data.length > 0) {
      // First result in response is our place - we should only expect a single result from the lookup call
      result = lookupResponse.data[0];
    }

    logCentral.event({
      category: "Search",
      action: "search_lookup",
    }, {
      results: lookupResponse.data.length // results count is the value
    });
  } catch (e) {
    if (e.message === 'Network Error') {
      log.error(e);
    } else {
      logCentral.error('Get coordinate for Place Id failed', e);
    }
  } finally {
    return result;
  }
}

function* handleMoveToSearchResult(action) {

  log.debug('searchSaga.handleMoveToSearchResult: %o', action);

  yield put(actions.setSearchResultsPending(true));

  try {

    const searchResults = yield select(getSearchResults);

    if (!searchResults) {
      throw new Error('searchSaga.handleMoveToSearchResult: no search results!');
    }

    var searchResult = searchResults.find(result => result.place_id === action.payload.placeId);

    if (!searchResult) {
      throw new Error('No search result avaiilable for placeId!');
    }

    // Use isNumber so that 0 lat/ 0 lng are valid
    if (!isNumber(searchResult.lat) || !isNumber(searchResult.lon)) {
      // We need to make a call to obtain the lat/lng
      // See https://github.com/redux-saga/redux-saga/issues/1701 Needs investigation
      searchResult = yield call(getCoordinateForPlaceId, searchResult.place_id);
    }
    
    if (!isNumber(searchResult?.lat) || !isNumber(searchResult?.lon)) {
      throw new Error('Lat or lng is missing from search result');
    }

    var mapState = {
      coordinate: { lat: searchResult.lat, lng: searchResult.lon },
      center: { lat: searchResult.lat, lng: searchResult.lon }
    };

    // Parse the bounding box, if any. Array of four floats, S, N, W, E
    let bounds = PELatLngBounds.fromBoundingBoxArray(searchResult.boundingbox);
    if (bounds) {
      mapState.center = bounds.center.toObject();
      mapState.span = bounds.span.toObject();
    }

    yield put(mapActions.updateMapState(mapState));
    
    // We need to query for new elevation and time zone here
    yield put(mapActions.queryTimeZoneElevation(mapState.coordinate));
    yield put(rootActions.deriveUpdatedQueryString());
    
    logCentral.event({
      category: "Search",
      action: "search_move_to_result"
    });
  } catch (e) {
    logCentral.error('Error moving to search result', e);
  } finally {
    yield put(actions.setSearchResultsPending(false));
  }
}

function* saveSearchResultSaga(action) {

  log.debug('searchSaga.saveSearchResultSaga: %o', action);

  yield put(actions.setSearchResultsPending(true));

  try {
    
    const searchResults = yield select(getSearchResults);

    if (!searchResults) {
      log.error('searchSaga.handleMoveToSearchResult: no search results!');
      return;
    }

    var searchResult = searchResults.find(result => result.place_id === action.payload.placeId);

    if (!searchResult) {
      throw new Error('No search result for placeId!');
    }

    if (!isNumber(searchResult.lat) || !isNumber(searchResult.lon)) {
      // We need to make a call to obtain the lat/lng
      // See https://github.com/redux-saga/redux-saga/issues/1701 Needs investigation
      searchResult = yield call(getCoordinateForPlaceId, searchResult.place_id);
    }
    
    if (!isNumber(searchResult.lat) || !isNumber(searchResult.lon)) {
      throw new Error('Lat or lng missing from search result %o', searchResult);
    }

    // Get the time zone and elevation - if the user is going to save the location for later reuse, this makes sense to do once now
    const tzEl = yield call(requestTimeZoneElevation, { lat: searchResult.lat, lng: searchResult.lon} );

    // Parse the bounding box, if any. Array of four floats, S, N, W, E
    let bounds = PELatLngBounds.fromBoundingBoxArray(searchResult.boundingbox);
    let span = bounds ? bounds.span.toObject() : { lat: 0.1, lng: 0.1 };

    // Construct a valid saved location object from searchResult
    const location = {
      coordinate: { latitude: searchResult.lat, longitude: searchResult.lon },
      span: { latitude: span.lat, longitude: span.lng },
      title: searchResult.display_name,
      notes: searchResult.display_subtitle || ''
    }

    if (tzEl) {
      location.timezoneId = tzEl.timeZoneId || '';
      if (tzEl.elevation && tzEl.elevation.value) {
        location.elevationAboveMSL = tzEl.elevation.value;
      }
    }

    // Add the location and mark it for editing
    yield put(locationActions.addLocation(true, location));

    // Switch to the locations page to complete the edit/save step
    // yield put(push('/locations'));

    // We need to query for new elevation and time zone here
    // yield put(mapActions.queryTimeZoneElevation({ lat: searchResult.lat, lng: searchResult.lon }));

    logCentral.event({
      category: "Search",
      action: "search_save_result"
    });
  } catch (e) {
    logCentral.error('Error saving search result', e);
  } finally {
    yield put(actions.setSearchResultsPending(false));
  }
}

/**
 * Root saga for map related actions
 */
export function* searchSagas() {
  yield takeLatest(mapActionTypes.REQUEST_SVC_CREDENTIALS_SUCCESS, discoverServiceDetails);
  yield takeLatest(actionTypes.SEARCH_INPUT_CHANGED, handleSearchInputChanged);
  yield takeLatest(actionTypes.MOVE_TO_SEARCH_RESULT, handleMoveToSearchResult);
  yield takeEvery(actionTypes.SAVE_SEARCH_RESULT, saveSearchResultSaga);
}