
import axios from 'axios';
import moment from 'moment-timezone';
import { call, put, select, fork, takeLatest } from 'redux-saga/effects';
import log from 'loglevel';

import i18n from 'i18n'

// import { browserHistory } from 'react-router';
// Inspired by https://auth0.com/blog/beyond-create-react-app-react-router-redux-saga-and-more/
import { makeURL } from '../config';
import { actions as authActions, actionTypes } from '../actions/authActions';
import { actions as settingsActions } from '../actions/settingsActions';
import { actions as userProfileActions } from '../actions/userProfileActions';
import { getProfileSaga } from './userProfileSaga';
import { getAuthToken, getDecodedToken } from '../selectors';

export class AuthorizedRequestError extends Error {
  
  constructor(message) {
    super(message); 
    this.name = "AuthorizedRequestError";
  }
}

/**
 * Requests a refreshed bearer JWT from the server. This call relies on passing cookies (i.e. withCredentials:true) to the 
 * server and assumes that the server has previously set a refresh token cookie at sign in.
 * See https://www.rdegges.com/2018/please-stop-using-local-storage/ for a good write up on why not to do it the
 * bad old way.
 */
export function requestFreshJWT() {
  
  const acceptLanguageValue = i18n.acceptLanguageHeaderValue();

  return axios.request({
    method: 'get',
    url: makeURL('/user/freshtoken/'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': acceptLanguageValue
    },
    withCredentials: true
  });
}

/** 
 * Refresh authorization saga: requests a refreshed token
 * If the refresh request fails (401), then the state is set to signed out
 */
export function* refreshTokenSaga() {

  try {

    let { data } = yield call(requestFreshJWT);
    
    // DON'T DO THIS: SENSITIVE INFORMATION
    //log.trace(result);
  
    // dispatch action to update the auth state
    yield put(authActions.updateAuthInfo(data));
    
    // Set a generic "a user has previously signed in on this browser" - we do this here also just in case browser has cleared the item
    // we set at sign in - it seems some setups clear local storage but not the http only cookie. So, belt and braces here...
    localStorage.setItem('userHasPreviouslySignedIn', 'true');

  } catch (e) {

    if (e.response && e.response.status === 401) {
      // Refresh cookie has expired
      log.warn('Refresh token expired: must sign in');
    } else {
      log.error(e.message, e.response || '');
    }

    // If we can't obtain a refresh token, then treat the user as signed out
    yield put(userProfileActions.signOut(false));    
  }
}

export function* authorizedRequest(options, shouldRefresh = true) {

  try {

    if (shouldRefresh) {

      // Check if the token is still valid before we request a new one
      const decodedToken = yield select(getDecodedToken);
      var expiresSoon = false;
      
      if (decodedToken && decodedToken.exp) {
        const expiry = moment.unix(decodedToken.exp);
      
        // If token has more than 30 seconds to run, refresh it before we make our call
        if (expiry.isBefore(moment().add(30, 'seconds'))) {
          expiresSoon = true;
          log.debug('authorizedRequest: token expires soon');
        }
      } else {
        log.debug('authorizedRequest: no token present');
      }

      if (!decodedToken || expiresSoon) {
        log.debug('authorizedRequest: requesting fresh token');
        // This will throw if request fails... - see below
        let { data } = yield call(requestFreshJWT);
        // dispatch action to update the auth state
        yield put(authActions.updateAuthInfo(data));  
      }
    }

    // Obtain the auth token from state (See select(selector, ...args) on https://redux-saga.js.org/docs/api/)
    const token = yield select(getAuthToken);
    if (token) {
      var authorizedOptions = options;
      authorizedOptions.headers['Authorization'] = 'Bearer ' + token;
      
      let fn = () => {
        return axios.request(authorizedOptions);
      } 

      return fn;
    }

    log.warn('No token available for authorized request');

    let fn = () => {
      return axios.request(options);
    } 

    return fn;

  } catch (e) {

    if (e.response && e.response.status === 401) {
      // Refresh cookie has expired
      log.warn('Refresh token expired: must sign in');
    } else {
      log.error(e.message, e.response || '');
    }

    // If we can't obtain a refresh token, then treat the user as signed out
    yield put(userProfileActions.signOut(false));

    throw new AuthorizedRequestError(e.message);
  }
}

/**
 * A saga that is run at app start-up to check we can obtain a fresh bearer token and, if we can, to refresh the user profile.
 */
function* restoreAuthAndProfile() {

  // See comments on 'userWasSignedIn' in ./userProfileSaga.js. This is set to false explicitly on sign out
  // and to true on sign in. In some browsers, after 7 days without visiting, local storage will be wiped 
  // (see https://webkit.org/blog/10218/full-third-party-cookie-blocking-and-more/), and so this item will return
  // null. In that case, we should optimistically try to obtain a fresh token and the user profile.
  const userWasSignedIn = localStorage.getItem('userWasSignedIn');
  if (userWasSignedIn === 'false') {
    return;
  }

  yield put(authActions.refreshAuthPending(true));

  yield call(refreshTokenSaga);

  // Obtain the auth token from state (See select(selector, ...args) on https://redux-saga.js.org/docs/api/)
  const token = yield select(getAuthToken);

  // We only update the user's profile if we successfully obtained a fresh token
  // As token is only ever stored in memory, and user is signed out if the refresh
  // request fails (refreshTokenSaga called above), then this will only be set if the 
  // request succeeded.
  if (token) {

    // Usually, we would just dispatch the action, but as we want to wait for completion before moving, we call it directly passing
    // in the result of the action creator to the saga, instead. Also, this will automatically obtain a refresh token.
    yield call(getProfileSaga, userProfileActions.getProfile('restoreAuthAndProfile'));

    // Get the user's preferences from our server - we can safely just dispatch this. No need to wait for the result.
    yield put(settingsActions.getUserPrefs());
  }

  yield put(authActions.refreshAuthPending(false));
}

/**
 saga watcher 
  */
 export function* authSagas() {
  yield fork(restoreAuthAndProfile);

  yield takeLatest(actionTypes.REFRESH_TOKEN, refreshTokenSaga);
}