import { createSelector } from 'reselect';

import { getSettings } from 'selectors';
import { getHasProEntitlement } from 'reselectors/userProfile'

export const selectUserPreferences = createSelector(
  [getSettings, getHasProEntitlement],
  (settings, isPro) => {

    // These are the settings that reflect user settable preferences, as opposed to static or derived state
    // Clever-clogs code from https://medium.com/@captaindaylight/get-a-subset-of-an-object-9896148b9c72
    
    let prefs = (
      ({ lang, dateFormat, timeFormat, timeZoneFormat, useMetricUnits, bodyKeys, standardMapType, displayOptions, bodySize, showVisualSearch, sphereMaps, defaultQs, useNow }) => (
        { lang, dateFormat, timeFormat, timeZoneFormat, useMetricUnits, bodyKeys, standardMapType, displayOptions, bodySize, showVisualSearch, sphereMaps, defaultQs, useNow }
      ))(settings);

    // Add premium only preferences - excluded here primarily to reduce size of payload sent to/stored in backend 
    let premiumPrefs = {};
    if (isPro) {
      premiumPrefs = (
        ({ usePremiumMapProvider, premiumMapType, useMagneticNorth, elSvc, sphereTerrain, terrainSegments }) => (
          { usePremiumMapProvider, premiumMapType, useMagneticNorth, elSvc, sphereTerrain, terrainSegments }
        ))(settings);
      prefs = { ...prefs, ...premiumPrefs }
    }
    
    return prefs;
  }
)
