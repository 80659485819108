import log from 'loglevel';
// import logdna from '@logdna/browser';
import * as Sentry from '@sentry/react';

export const logCentral =  {
  
  setUser: (context) => {

    if (!context) {
      return;
    }

    Sentry.setUser(context);

    // Include only opaque user ID in LogDNA log context
    // logdna.addContext({
    //   userId: context.id
    // });
  },
  /**
   * Method to log an event to Google Analytics and other logging services
   * @param {Object} event event object with properties category, action, label
   * @param {Object} extras object with any additional properties - these are not sent to Google Analytics, but are sent to other logging services
   */
  event: (event, extras = {}, logLevel = 'info') => {
    
    // switch (logLevel) {
    //   case 'error':
    //     logdna.error(event, extras);
    //     break;
    //   case 'warn':
    //     logdna.warn(event, extras);
    //     break;
    //   case 'debug':
    //     logdna.debug(event, extras);
    //     break;
    //   case 'log':
    //     logdna.log(event, extras);
    //     break;
    //   case 'info':
    //   default:
    //     logdna.info(event, extras);
    //     break;
    // }
    // We don't send Events to Sentry - it's for errors/exceptions only
  },
  eventGA4: (name, parameters) => {

    if (window.gtag) {
      window.gtag("event", name, parameters);
    } else {
      log.debug('warning: gtag not defined');
    }
  },
  error: (msg = '', err, extras = {}) => {

    // log only the error message to the console
    log.error(err.message);

    // logdna.error(event, extras);

    // See https://github.com/getsentry/sentry-javascript/issues/1607#issuecomment-578762771
    Sentry.withScope(scope => {
      if (msg.length > 0) {
        Sentry.captureMessage(msg);
      }
      if (extras !== {}) {
        scope.setExtras(extras)
      };
      Sentry.captureException(err);
    }); 
  } 
}
