import Dexie from 'dexie';

// Create your instance
export const locationsDB = new Dexie("locations"); 

// Define locations schema
locationsDB.version(1).stores({
  saved: "&uuid, useruuid, title, requiresSync, favourite, folder"
});

locationsDB.version(2).stores({
  saved: "&uuid, useruuid, title, requiresSync, favourite, folder",
  deleted: "&uuid, useruuid, deletedAt"
});

