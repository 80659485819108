import { createReducer } from '@reduxjs/toolkit';

import { actionTypes } from '../actions/locationActions';
import log from 'loglevel';

const initialState = {
	dbIsOpen: undefined,
	synchPending: false,
	error: undefined,
	synchToken: undefined,
	serverTime: undefined,
	storedCount: undefined,
  loadPending: false,
  writePending: false,
  synchRequired: true,
	saved: [],
	selectedUUID: undefined,
	editingUUID: undefined,
	filter: {
		field: undefined,
		value: undefined,
		source: undefined,
		favourites: false
	}
};

/**
 * Note: this uses redux-toolkit createReducer so we can write mutating code for state
 * See https://redux-toolkit.js.org/api/createReducer
 */
export const locations = createReducer(initialState, {
	[actionTypes.SET_DB_OPEN]: (state, action) => {
		return { ...state, dbIsOpen: action.payload.dbIsOpen };
	},
	[actionTypes.RESET_LOCATIONS]: (state, action) => {
		return { ...initialState, dbIsOpen: state.dbIsOpen };
	},
	[actionTypes.SYNCH_LOCATIONS_PENDING]: (state, action) => {

    // Only update stored count if pending is being is not true and a new value is supplied:    
    let changes = {
      synchPending: action.payload.pending,
      storedCount: action.payload.pending === true ? state.storedCount : action.payload.storedCount || state.storedCount
    }

		return { ...state, ...changes };
	},
	[actionTypes.SET_LOCATIONS_ERROR]: (state, action) => {
		return { ...state, error: action.payload };
	},
	[actionTypes.SET_SYNCH_TOKEN]: (state, action) => {

    // We assume that if we dispatch a synch token to the store, then synchronization has succeeded and there are not pending changes, i.e. no synch required
    let changes = {
      synchToken: action.payload.synchToken, 
      serverTime: action.payload.serverTime,
      synchRequired: false
    }

		return { ...state, ...changes };
	},
	[actionTypes.LOAD_LOCATIONS_FROM_STORE]: (state, action) => {
		return { ...state, saved: action.payload };
	},
	[actionTypes.LOAD_LOCATIONS_PENDING]: (state, action) => {
		return { ...state, loadPending: action.payload.pending };
  },
  [actionTypes.WRITE_LOCATIONS_PENDING]: (state, action) => {
    return { ...state, writePending: action.payload.pending };
  },
	[actionTypes.SELECT_LOCATION]: (state, action) => {
		return { ...state, selectedUUID: action.payload.uuid };
	},
	[actionTypes.EDIT_LOCATION]: (state, action) => {
		return { ...state, editingUUID: action.payload.uuid };
	},
	[actionTypes.SET_SAVED_LOCATIONS_FILTER]: (state, action) => {
		return { ...state, filter: action.payload };
	},
	[actionTypes.PRE_DELETE_LOCATION]: (state, action) => {
		return {
			...state,
			deletionUUID: action.payload.uuid,
			resumeEditingIfDeletionCancelled: action.payload.resumeEditingIfDeletionCancelled
		};
	},
	[actionTypes.POST_DELETE_CLEANUP]: (state, action) => {
		return { ...state, deletionUUID: undefined, resumeEditingIfDeletionCancelled: undefined };
	},
	[actionTypes.MODIFY_LOCATION_STATE]: (state, action) => {
    
    // Do we have this item already?
    const uuid = action.payload.location.uuid;
    if (!uuid) {
      log.warn('MODIFY_LOCATION_STATE: no uuid provided');
      return state;
    }
  
    let locationItem;
    switch (action.payload.operation) {
			case 'add':
        locationItem = state.saved.find(location => location.uuid === uuid);
        if (!locationItem) {
          state.saved.push(action.payload.location);
        } else {
          log.error('MODIFY_LOCATION_STATE: cannot add existing location with uuid ' + uuid);
        }
				break;
			case 'update':
        locationItem = state.saved.find(location => location.uuid === uuid);
        if (!locationItem) {
          state.saved.push(action.payload.location);
        } else {
          const updatedSaved = state.saved.map(item => {
            if (item.uuid !== uuid) {
              return item // This isn't the location we care about - keep it as-is
            }

            // Otherwise, this is the one we want - return an updated value
            return {
              ...item,
              ...action.payload.location
            }
          });
          state.saved = updatedSaved;
        }
				break;
			case 'delete':
        // Note - we don't check it exists before attempting to remove it.
        state.saved = state.saved.filter(item => item.uuid !== uuid);
				break;
			default:
				break;
    }
    
    // Finally - mark synch as required
    state.synchRequired = true;
	}
});
