/*
 * action types
 */

export const actionTypes = {
  SET_TIME_FORMAT: 'SET_TIME_FORMAT',
  RESET_SETTINGS: 'RESET_SETTINGS',
  DERIVE_UPDATED_SETTINGS: 'DERIVE_UPDATED_SETTINGS',
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  PUT_USER_PREFS: 'PUT_USER_PREFS',
  GET_USER_PREFS: 'GET_USER_PREFS',
  GET_USER_PREFS_PENDING: 'GET_USER_PREFS_PENDING',
  TOGGLE_FULL_SCREEN_MODE: 'TOGGLE_FULL_SCREEN_MODE'
};

/*
 * action creators
 */

export const actions = {
  setTimeFormat: (format) => {
    return { type: actionTypes.SET_TIME_FORMAT, payload: { format: format } };
  },
  resetSettings: () => {
    return { type: actionTypes.RESET_SETTINGS };
  },
  deriveUpdatedSettings: (settings, noSave) => {
    return { type: actionTypes.DERIVE_UPDATED_SETTINGS, payload: { settings: settings, noSave: noSave } };
  },
  updateSettings: (settings) => {
    return { type: actionTypes.UPDATE_SETTINGS, payload: settings };
  },
  putUserPrefs: (prefs) => {
    return { type: actionTypes.PUT_USER_PREFS, payload: prefs };
  },
  getUserPrefs: () => {
    return { type: actionTypes.GET_USER_PREFS };
  },
  getUserPrefsPending: (pending) => {
    return { type: actionTypes.GET_USER_PREFS_PENDING, payload: { pending: pending }};
  },
  toggleFullScreenMode: (mode) => {
    return { type: actionTypes.TOGGLE_FULL_SCREEN_MODE, payload: { enabled: mode } };
  }
}