import { createSelector } from 'reselect';
import LatLonSpherical from "geodesy/latlon-spherical";
import LatLonEllipsoidal from "geodesy/latlon-ellipsoidal-vincenty";
import _ from 'lodash';
import log from 'loglevel';

import Ellipsoid from '@stephent/meeusjs/lib/vincenty/ellipsoid';
import GeodeticCalculator from '@stephent/meeusjs/lib/vincenty/geodeticCalculator';
import RiseTransitSetEvents from '@stephent/meeusjs/lib/risetransitsetevents';
import { toRadians } from '@stephent/meeusjs/lib/meeushelper';
import GlobalPosition from '@stephent/meeusjs/lib/vincenty/globalPosition';

import { getIsAdjustingTimeOfDay, getCoordinate, getCenter, getSpan, getHalfSpan, getElevation, getGeodetics, getObjectHeights, getSettings, getMapServiceNames, getServiceCredentials } from 'selectors';
import { getBodyPositions } from 'reselectors/';
import { getTimelineEvents } from 'reselectors/dateTime';
import { getHasProEntitlement } from 'reselectors/userProfile';

const mapEventKeys = [
  RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition,
  RiseTransitSetEvents.EVENT_KEYS.MESunPosition,
  RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition,
  RiseTransitSetEvents.EVENT_KEYS.SSRise,
  RiseTransitSetEvents.EVENT_KEYS.SSSet,
  RiseTransitSetEvents.EVENT_KEYS.MMRise,
  RiseTransitSetEvents.EVENT_KEYS.MMSet,
  RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentreRise,
  RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentreSet
];

const DEFAULT_AZIMUTH_LENGTH_METRES = 321000
const ANTI_KEY_EXTENSION = '_anti';
const SHADOW_KEY_EXTENSION = '_shadow';

const geodeticCalculator = new GeodeticCalculator(Ellipsoid.WGS84);

const getPolylineStyleAttributes = (key, apparentAltitude) => {

  switch (key) {
    case RiseTransitSetEvents.EVENT_KEYS.MESunPosition:
      if (apparentAltitude < -18) {
        return {
          opacity: 0.0,
          weight: 2.0
        }
      } else if (apparentAltitude < 0) {
        return {
          opacity: 0.3,
          weight: 2.0
        }
      } else {
        return {
          opacity: 0.8,
          weight: 5.0
        }
      }
    case RiseTransitSetEvents.EVENT_KEYS.MESunPosition + ANTI_KEY_EXTENSION:
      if (apparentAltitude < -18) {
        return {
          opacity: 0.0,
          weight: 1.0
        }
      } else if (apparentAltitude < 0) {
        return {
          opacity: 0.3,
          weight: 1.0
        }
      } else {
        return {
          opacity: 0.8,
          weight: 1.0
        }
      }
    case RiseTransitSetEvents.EVENT_KEYS.MESunPosition + SHADOW_KEY_EXTENSION:
      return {
        opacity: 0.8,
        weight: 6.0
      }
    case RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition:
    case RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition:
      if (apparentAltitude < 0) {
        return {
          opacity: 0.0,
          weight: 2.0
        }
      } else {
        return {
          opacity: 0.8,
          weight: 5.0
        }
      }
    case RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition + ANTI_KEY_EXTENSION:
      if (apparentAltitude < 0) {
        return {
          opacity: 0.0,
          weight: 1.0
        }
      } else {
        return {
          opacity: 0.8,
          weight: 1.0
        }
      }
    case RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition + SHADOW_KEY_EXTENSION:
      return {
        opacity: 0.8,
        weight: 6.0
      }
    case RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition + SHADOW_KEY_EXTENSION:
    case RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition + ANTI_KEY_EXTENSION:
      return {
        opacity: 0
      }
    default:
      return {
        opacity: 0.8,
        weight: 8.0
      };
  }
}

/**
 * Returns an object with northEast and southWest properties corresponding to bounds
 * described by redux map.center and map.span state. Returns undefined if no map.span available
 */
export const getBounds = createSelector(
  [getCenter, getSpan],
  (center, span) => {

    if (!center || !span) {
      return;
    }

    let ne = {
      lat: center.lat + span.lat / 2.0,
      lng: center.lng + span.lng / 2.0
    }

    let sw = {
      lat: center.lat - span.lat / 2.0,
      lng: center.lng - span.lng / 2.0
    }

    return {
      northEast: ne,
      southWest: sw
    }
  }
);

export const getServiceCredentialsMap = createSelector(
  [getServiceCredentials],
  (serviceCredentials) => {

    if (!serviceCredentials) {
      return new Map();
    }

    // We want a Map, so a little transformation required (see https://stackoverflow.com/a/38622270/220287)
    let result = new Map(serviceCredentials.map(svc => [svc.name, { token: svc.token, user: svc.user }]));
    return result;
  }
)

/**
 * Returns a memoized array of uniquely services required by the map
 */
export const getDistinctMapServiceNames = createSelector(
  [getMapServiceNames],
  (mapServiceNames) => {

    let serviceNames = Object.values(mapServiceNames);

    // https://stackoverflow.com/a/14438954/220287
    let distinctNames = [...new Set(serviceNames)];

    return distinctNames;
  }
)

/**
 * Returns a Map object with capability as key ('map', 'elevation', 'timezone'), and a credentials object
 * as value (e.g. { token: [TOKEN], user: [USERNAME] })
 */
export const getCredentialsMap = createSelector(
  [getMapServiceNames, getServiceCredentialsMap],
  (serviceNames, credentialsMap) => {

    if (!serviceNames || !credentialsMap) {
      return new Map();
    }

    // We want to map capability to credentials, so a little transformation required (see https://stackoverflow.com/a/38622270/220287)
    let result = new Map(Object.entries(serviceNames).map(([key, value]) => [key, credentialsMap.get(value)]));

    return result;

  }
)

/**
 * Returns a memoized array of events (e.g. SSRise, MMSet etc.) for display on the map
 */
export const getMapTimelineEvents = createSelector(
  [getTimelineEvents],
  (timelineEvents) => {

    const mapEvents = timelineEvents.filter(event => mapEventKeys.includes(event.key));

    return mapEvents;
  }
)

/**
 * Returns a memoized array of objects containing required information to construct a polyline for display
 * on a map. The object structure is generic so this reselector can be used on more than one type of map,
 * with appropriate adaptation in the display Component itself.
 */
export const getEventPolylines = createSelector(
  [getCoordinate, getMapTimelineEvents, getSettings],
  (coordinate, events, settings) => {

    if (isNaN(coordinate.lat) || isNaN(coordinate.lng)) {
      log.error('Invalid coordinates - cannot calculate event polylines');
      return [];
    }

    const startPoint = new LatLonEllipsoidal(coordinate.lat, coordinate.lng);

    var results = events.map(ev => {

      if (!ev.azimuth) {
        log.error('Cannot calculate event polyline, azimuth not defined:', ev);
        return null;
      }

      // let endPoint = startPoint.rhumbDestinationPoint(DEFAULT_AZIMUTH_LENGTH_METRES, ev.azimuth);
      let endPoint = startPoint.destinationPoint(DEFAULT_AZIMUTH_LENGTH_METRES, ev.azimuth);

      // Make the line a "no-op" if there's no valid end point available
      if (isNaN(endPoint.lat) || isNaN(endPoint.lng)) {
        endPoint = {
          lat: startPoint.lat,
          lng: startPoint.lng
        }
      }

      var polyData = {
        key: ev.key,
        color: settings.eventKeyColors[ev.key],
        points: [
          [startPoint.lat, startPoint.lng],
          [endPoint.lat, endPoint.lng]
        ],
        event: ev
      };

      const styleAttr = getPolylineStyleAttributes(ev.key, ev.apparentAltitude);
      const finalData = Object.assign(polyData, styleAttr);

      return finalData;
    }).filter((item) => item); // filtered for any null results
    
    return results;
  }
)

export const getShadowCircleRadius = createSelector(
  [getIsAdjustingTimeOfDay, getCenter, getHalfSpan],
  (isAdjustingTimeOfDay, center, halfSpan) => {

    if (!center || !halfSpan || !isAdjustingTimeOfDay) {
      return 0;
    }

    var endPoint;
    if (halfSpan.lat < halfSpan.lng) {
      endPoint = new LatLonSpherical(center.lat + halfSpan.lat, center.lng);
    } else {
      endPoint = new LatLonSpherical(center.lat, center.lng + halfSpan.lng);
    }

    const mapCenter = new LatLonSpherical(center.lat, center.lng);
    const radius = mapCenter.rhumbDistanceTo(endPoint) * 0.98; // reduce to create some margin from circle to map edges

    return radius;
  }
)

/**
 * Returns a memoized array of objects containing required information to construct a polyline for display
 * on a map. The object structure is generic so this reselector can be used on more than one type of map,
 * with appropriate adaptation in the display Component itself.
 */
export const getBodyPositionPolylines = createSelector(
  [getCoordinate, getBodyPositions, getSettings, getIsAdjustingTimeOfDay, getShadowCircleRadius],
  (coordinate, positions, settings, isAdjustingTimeOfDay, shadowCircleRadius) => {

    if (isNaN(coordinate.lat) || isNaN(coordinate.lng)) {
      log.error('Invalid coordinates - cannot calculate body position polylines');
      return [];
    }

    const startPoint = new LatLonEllipsoidal(coordinate.lat, coordinate.lng);

    const positionResults = positions.map(ev => {

      if (!ev.azimuth) {
        log.error('Cannot calculate body polyline, azimuth not defined:', ev);
        return null;
      }

      let endPoint = startPoint.destinationPoint(DEFAULT_AZIMUTH_LENGTH_METRES, ev.azimuth);

      // Make the line a "no-op" if there's no valid end point available
      if (isNaN(endPoint.lat) || isNaN(endPoint.lng)) {
        endPoint = {
          lat: startPoint.lat,
          lng: startPoint.lng
        }
      }

      const polyData = {
        key: ev.key,
        color: settings.eventKeyColors[ev.key],
        points: [
          [startPoint.lat, startPoint.lng],
          [endPoint.lat, endPoint.lng]
        ],
        event: ev
      };

      const styleAttr = getPolylineStyleAttributes(ev.key, ev.apparentAltitude);
      const finalData = { ...polyData, ...styleAttr };

      return finalData;
    });

    const antiResults = positions.map(ev => {

      if (!ev.azimuth) {
        log.error('Cannot calculate body "anti" polyline, azimuth not defined:', ev);
        return null;
      }

      let endPoint = startPoint.destinationPoint(DEFAULT_AZIMUTH_LENGTH_METRES, ev.azimuth + 180.0);

      // Make the line a "no-op" if there's no valid end point available
      if (isNaN(endPoint.lat) || isNaN(endPoint.lng)) {
        endPoint = {
          lat: startPoint.lat,
          lng: startPoint.lng
        }
      }

      const polyData = {
        key: ev.key + ANTI_KEY_EXTENSION,
        color: settings.eventKeyColors[ev.key],
        points: [
          [startPoint.lat, startPoint.lng],
          [endPoint.lat, endPoint.lng]
        ],
        event: ev
      };

      var finalData;
      if (isAdjustingTimeOfDay) {
        const styleAttr = getPolylineStyleAttributes(polyData.key, ev.apparentAltitude);
        finalData = { ...polyData, ...styleAttr };
      } else {
        finalData = { ...polyData, opacity: 0 };
      }

      return finalData;
    });

    const minAltitude = 0.104719755; // 6.0 degrees converted to radians - golden hour altitude
    const impliedObjectHeight = shadowCircleRadius * Math.tan(minAltitude);
    var impliedShadowLength;
    var usableShadowLength;

    const shadowResults = positions.map(ev => {

      var endPoint = startPoint; // assume no shadow

      if (ev.apparentAltitude > 0 && shadowCircleRadius > 0) {
        // Calculate shadow length in metres:
        impliedShadowLength = impliedObjectHeight / Math.tan(toRadians(ev.apparentAltitude));

        // Constrain the shadow line to within the circle
        usableShadowLength = Math.min(DEFAULT_AZIMUTH_LENGTH_METRES, impliedShadowLength); // 200 mile max shadow length - same as azimuth lines

        if (!ev.azimuth) {
          log.error('Cannot calculate shadow polyline, azimuth not defined:', ev);
          return null;
        } 

        if (!impliedShadowLength || impliedShadowLength < 0.25) { // arbitrary small value
          log.error('Cannot calculate shadow polyline, invalid implied shadow length:', impliedShadowLength, ev);
          return null;
        }

        // Calculate end point
        endPoint = startPoint.destinationPoint(usableShadowLength, ev.azimuth + 180.0);

        // Make the line a "no-op" if there's no valid end point available
        if (isNaN(endPoint.lat) || isNaN(endPoint.lng)) {
          endPoint = {
            lat: startPoint.lat,
            lng: startPoint.lng
          }
        }
      }

      const key = ev.key + SHADOW_KEY_EXTENSION;
      const polyData = {
        key: key,
        color: settings.eventKeyColors[key],
        points: [
          [startPoint.lat, startPoint.lng],
          [endPoint.lat, endPoint.lng]
        ],
        event: ev
      };

      var finalData;
      if (isAdjustingTimeOfDay) {
        const styleAttr = getPolylineStyleAttributes(key, ev.apparentAltitude);
        finalData = { ...polyData, ...styleAttr };
      } else {
        finalData = { ...polyData, opacity: 0 };
      }

      return finalData;
    })

    // Assemble results and filter out null or undefined values:
    const results = [...positionResults, ...antiResults, ...shadowResults].filter((item) => item);

    return results;
  }
)

export const getAllPolylines = createSelector(
  [getEventPolylines, getBodyPositionPolylines],
  (eventPolylines, positionPolylines) => {

    return eventPolylines.concat(positionPolylines);
  }
)

export const getShadowCircleOptions = createSelector(
  [getShadowCircleRadius, getBodyPositions, getIsAdjustingTimeOfDay, getSettings],
  (radius, bodyPositions, isAdjustingTimeOfDay, settings) => {

    var circleOptions = {};
      
    if (isAdjustingTimeOfDay) {

      circleOptions = {
        radius: radius,
        stroke: true,
        weight: 1,
        color: '#000000',
        opacity: 0.8,
        fill: false,
        fillColor: 'transparent',
        fillOpacity: 0.0,
        visible: true
      }

      const sunPosition = bodyPositions.find(ev => ev.key === RiseTransitSetEvents.EVENT_KEYS.MESunPosition);
      if (!sunPosition) {
        return circleOptions;
      }

      const isGoldenHour = (0.0 < sunPosition.apparentAltitude && sunPosition.apparentAltitude < 6.0);

      if (isGoldenHour) {
        const color = settings.eventKeyColors[sunPosition.key];
        circleOptions = {
          ...circleOptions,
          weight: 3,
          color: color,
          fill: true,
          fillColor: color,
          fillOpacity: 0.1
        }
      }
    } else {
      circleOptions = {
        visible: false
      };
    }

    return circleOptions;
  }

)

export const getGeodeticMeasurement = createSelector(
  [getCoordinate, getElevation, getGeodetics, getObjectHeights, getSettings],
  (coordinate, elevation, geodetics, objectHeights, settings) => {

    if (!geodetics.enabled || !geodetics.coordinate) {
      return;
    }

    if (coordinate.lat === geodetics.coordinate.lat && coordinate.lng === geodetics.coordinate.lng) {
      log.debug('Primary and secondary coordinates are identical: not calculating geodetics');
      return;
    }

    // let startPoint = new LatLonEllipsoidal_Vincenty(coordinate.lat, coordinate.lng, elevation.verified ? elevation.value : undefined);
    // let endPoint = new LatLonEllipsoidal_Vincenty(geodetics.coordinate.lat, geodetics.coordinate.lng, geodetics.elevation.verified ? geodetics.elevation.value : undefined);
    // let greatCircle = startPoint.inverse(endPoint);

    let ground = {
      elevation: {
        start: 0,
        end: 0,
        delta: 0
      }
    }

    let startEl = 0 + (objectHeights?.primary || 0);
    if (elevation && elevation.value) {
      startEl += elevation.value;
      ground.elevation.start = elevation.value;
    }

    let endEl = 0 + (objectHeights?.secondary || 0);
    if (geodetics.elevation && geodetics.elevation.value) {
      endEl += geodetics.elevation.value;
      ground.elevation.end = geodetics.elevation.value;
    }

    ground.elevation.delta = ground.elevation.end - ground.elevation.start;

    // We need to calculate the ground level great circle
    let groundStartPoint = new GlobalPosition(coordinate, ground.elevation.start);
    let groundEndPoint = new GlobalPosition(geodetics.coordinate, ground.elevation.end);
    
    ground.greatCircle = geodeticCalculator.calculateGeodeticMeasurement(groundStartPoint, groundEndPoint).toObject();

    let startPoint = new GlobalPosition(coordinate, startEl);
    let endPoint = new GlobalPosition(geodetics.coordinate, endEl);

    const greatCircle = geodeticCalculator.calculateGeodeticMeasurement(startPoint, endPoint);

    const result = greatCircle.toObject();

    // Can we trust the results?
    result.verified = elevation && elevation.verified === true && geodetics && geodetics.elevation && geodetics.elevation.verified === true;
    result.consistent = (elevation?.source === geodetics?.elevation?.source);
    if (!_.isNil(settings.elSvc)) {
      result.consistent = result.consistent && (elevation?.source === settings.elSvc);
    }

    const startLatLon = new LatLonSpherical(coordinate.lat, coordinate.lng)
    const endLatLon = new LatLonSpherical(geodetics.coordinate.lat, geodetics.coordinate.lng)

    result.rhumbBearing = startLatLon.rhumbBearingTo(endLatLon)
    result.rhumbDistanceTo = startLatLon.rhumbDistanceTo(endLatLon)

    // Calculate correction for curvature
    const earthRadiusMetres = Ellipsoid.WGS84.semiMajorAxis;
    const theta = greatCircle.pointToPointDistance / (2 * earthRadiusMetres);
    const D = 2 * earthRadiusMetres * Math.sin(theta);
    const alpha = Math.asin(D / (2 * earthRadiusMetres));
    const C = Math.pow(D, 2) / (2 * earthRadiusMetres * Math.cos(2 * alpha));
    const r = C * 0.14; // standard correction for refraction
    const verticalError = C - r;

    result.curvature = C; // in metres
    result.refraction = r; // in metres
    result.verticalError = verticalError; // in metres

    result.ground = ground;

    return result
  }
)

export const getApparentHeightSize = createSelector(
  [getGeodeticMeasurement, getBodyPositions],
  (geodeticMeasurement, bodyPositions) => {

    if (!geodeticMeasurement || !geodeticMeasurement.ground || !geodeticMeasurement.ground.greatCircle) {
      return [];
    }

    const greatCircle = geodeticMeasurement.ground.greatCircle;

    const apparentHeightSizes = bodyPositions.map((body) => {
      
      // Calculate apparent height above secondary pin
      const result = {
        key: body.key
      }

      if (greatCircle.pointToPointDistance /* && geodeticMeasurement.verticalError*/) {
        if (body.semidiameterInDegrees) {
          // Calculate apparent size at distance of secondary pin, if semidiameter defined
          result.apparentSize = 2 * Math.tan(toRadians(body.semidiameterInDegrees)) * greatCircle.pointToPointDistance;
        }
        // No need to include verticalError, per Jeff Conrad. See https://github.com/crookneck/tpe-web-app/issues/56
        result.apparentHeight = Math.tan(toRadians(body.apparentAltitude - greatCircle.apparentAltitude)) * greatCircle.pointToPointDistance /*+ greatCircle.verticalError */
      }

      return result;
    })


    return apparentHeightSizes;
  }
)

/**
 * The services available depend on the input latitude:
 * SRTM3 or STRM1: between 60N and 56S
 * AsterGDEM: between 82N and 65S
 * GTOPO30: all latitudes
 */
export const getAvailableElevationSvcs = createSelector(
  [getCoordinate, getHasProEntitlement, getSettings],
  (coordinate, isPro, settings) => {

    const latitude = coordinate.lat;
    let svcs = [];

    if (latitude > 82 || latitude < -65) {
      svcs = ['gtopo30'];
    } else if (latitude > 60 || latitude < -56) {
      svcs = ['astergdem', 'gtopo30'];
    } else {
      svcs = ['srtm3', 'srtm1', 'astergdem', 'gtopo30'];
    }

    // We can only display Google Elevation in conjunction with a Google Map, per license terms:
    if (isPro && settings.usePremiumMapProvider === true) {
      svcs = [...svcs, 'google'];
    }

    return svcs;
  }
)