import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
// import log from 'loglevel';

import { useProfitwell } from 'hooks/profitwell';
import { actions } from 'actions/userProfileActions';
import { getUserProfile } from 'selectors';
import { CircleWarningIcon } from '../icons/AppIcons';

import PremiumBadge from '../premiumBadge/PremiumBadge';
// import SkyfireBadge from '../skyfire/SkyfireBadge';

export default function SignOut() {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const profile = useSelector(getUserProfile);

  useProfitwell();

  const doSignOut = () => {
    dispatch(actions.signOut(true));
  }

  const displayedEmail = profile.email || '?'
  const displayedName = profile.firstname ? [profile.firstname, profile.lastname].join(' ') : '?'

  return (
    <>
      <div>
        <Trans i18nKey="account.signedInAs" user={displayedName}>
          <Link to="/account" title={displayedEmail}>{{ user: displayedName }}</Link>
        </Trans>
      </div>
      {profile.emailUndeliverable === true &&
        <Link to="/account"><CircleWarningIcon className="icon warning" /></Link>
      }
      <PremiumBadge />
      {/* <SkyfireBadge /> */}
      <button className="link small" onClick={doSignOut}>
        {t('account.signOut')}
      </button>
    </>
  )
}
