import React from 'react';
import { HistoryRouter as Router } from "redux-first-history/rr6";
import { Provider } from 'react-redux';
import log from 'loglevel';
// 
import { Helmet } from 'react-helmet-async';
import { enableMapSet } from "immer"
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { Integrations } from "@sentry/tracing";
import Modal from 'react-modal';

import { useThreeCompatibility } from 'hooks/three-compatibility';

// Styling
// import './styles/normalize.css';
import './styles/skeleton.css';
import './styles/skeleton_enhancements.css';
import './styles/App.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './styles/react-confirm-alert_custom.css';
import './styles/react-modal-custom.css';
import 'react-toastify/dist/ReactToastify.css';
import './styles/react-toastify-custom.css';
import './styles/list-items.css';
import './styles/iubenda-custom.css';
import './styles/events.css';
import './styles/timeline.css';

// Components
import Header from './components/header/Header';
import Container from './Container';
import Footer from './components/footer/Footer';
import ZendeskWebWidget from './components/zendesk/ZendeskWebWidget';

// Redux Store
import { store, history } from './store';

// Initialize Sentry logging
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_NAME + '@' + process.env.REACT_APP_VERSION,
  environment: process.env.NODE_ENV,
  ignoreErrors: [
    'Request failed with status code 401',
    'SecurityError: The operation is insecure.',
    'TypeError: can\'t redefine non-configurable property "userAgent"',
    'STREETVIEW_GET_PANORAMA: ZERO_RESULTS',
    'ChunkLoadError',
    'Network Error'
  ],
  integrations: [new Integrations.BrowserTracing()]
});

// https://immerjs.github.io/immer/docs/installation
enableMapSet();

// Listen for relevant location changes
// history.listen((location, action) => {

// });

// Set up logging
if (process.env.REACT_APP_LOG_LEVEL) {
  log.setLevel(process.env.REACT_APP_LOG_LEVEL);
} else {
  log.setDefaultLevel('info'); // safe default
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement(document.getElementById('root'));

export default function App() {

  useThreeCompatibility();

  const { t } = useTranslation();

  // The <Suspense> element included below is required by i18n to allow translated string resources to be loaded
  // before attempting to render the UI.

  return (
    <Provider store={store}>
      <Helmet>
        <title>{t('app.pageTitle')}</title>
        <meta name="description" content="The Photographer's Ephemeris (TPE) helps you plan outdoor photography shoots in natural light, particularly landscape and urban scenes. It’s a map-centric sun and moon calculator: see how the light will fall on the land, day or night, for any location on earth." />

        <meta property="og:title" content="Photo Ephemeris Web" />
        <meta property="og:site_name" content="The Photographer's Ephemeris - Web App" />
        <meta property="og:url" content="https://app.photoephemeris.com" />
        <meta property="og:description" content="The Photographer's Ephemeris (TPE) helps you plan outdoor photography shoots in natural light, particularly landscape and urban scenes. It’s a map-centric sun and moon calculator: see how the light will fall on the land, day or night, for any location on earth." />
        <meta property="og:type" content="website" />
        <meta property="article:publisher" content="//www.facebook.com/photoephemeris" />
        <meta property="og:image" content="https://app.photoephemeris.com/images/fb-og-image.jpg" />
        <meta property="og:image:width" content="1068" />
        <meta property="og:image:height" content="561" />
        <meta property="fb:app_id" content="1444089095854237" />

        <meta id="apple-itunes-app" name="apple-itunes-app" content="app-id=366195670, affiliate-data=at=10layT" />
      </Helmet>
      <Router history={history}>

        <>
          {/* <CookieConsentManager /> */}
          <Header />
          <Container />
          <Footer />
          <ZendeskWebWidget />
        </>

      </Router>
    </Provider>
  )
}


