import React from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import log from 'loglevel';

// Selectors
import { getSettings } from './selectors';
import { getIsSignedIn, getHasVisitedPreviously } from './reselectors/userProfile';

// Component
import PageErrorBoundary from './components/errorBoundary/PageErrorBoundary';

// Pages - using lazy loading for code splitting
const Ephemeris = React.lazy(() => import('./components/pages/ephemeris/Ephemeris'));
const ArmillarySphere = React.lazy(() => import('./components/pages/armillarySphere/ArmillarySphere'));
// const About = React.lazy(() => import('./components/pages/about/About'));
// const Search = React.lazy(() => import('./components/pages/search/Search'));
const Locations = React.lazy(() => import('./components/pages/locations/Locations'));
const Settings = React.lazy(() => import('./components/pages/settings/Settings'));
const Account = React.lazy(() => import('./components/pages/account/Account'));
const SignUp = React.lazy(() => import('./components/pages/signup/SignUp'));
const Join = React.lazy(() => import('./components/pages/signup/Join'));
const SignIn = React.lazy(() => import('./components/pages/signin/SignIn'));
const Subscription = React.lazy(() => import('./components/pages/subscribe/paddle/Subscription'));
const RequestPasswordReset = React.lazy(() => import('./components/pages/requestpasswordreset/RequestPasswordReset'));
const PasswordReset = React.lazy(() => import('./components/pages/passwordreset/PasswordReset'));

const noRedirectPaths = ['/passwordreset', 'signup'];

export default function Container() {

  const { fullScreenEnabled } = useSelector(getSettings);
  const isSignedIn = useSelector(getIsSignedIn);
  const previousVisitor = useSelector(getHasVisitedPreviously);
  const { pathname, search } = useLocation();

  // TODO: Should only display full screen on map page, but simply doing this isn't enough, it seems - need to somehow trigger a full re-render to get header 
  // and footer displaying again properly.
  let containerClass = 'container max-w-screen-xl px-2';
  if (fullScreenEnabled /*&& pathname === '/'*/) {
    containerClass += ' full-screen';
  }

  if (pathname === '/join') {
    containerClass += ' full-bleed';
  }

  const defaultPath = () => {

    if (isSignedIn === true) {
      return (<Ephemeris />)
    } 
    
    if (previousVisitor !== true && isSignedIn !== true && !noRedirectPaths.includes(pathname)) {
      log.debug('Redirecting to /join, pathname === ' + pathname);
      return (<Navigate replace to="/join" />)
    }
    
    log.debug('Redirecting to /signin, pathname === ' + pathname);
    return (<Navigate replace state={{ referrer: pathname }} to={{
      pathname: "/signin",
      search: search
    }} />)
  }

  return (
    /* For tabIndex="-1" - see https://stackoverflow.com/questions/42764494/blur-event-relatedtarget-returns-null#comment115031516_42764495 */
    <div id="container" className={`${containerClass} focus:outline-none`} tabIndex="-1" >
      <PageErrorBoundary> {/* Error boundary to guard against lazy loaded imports failing */}
        <Routes>
          <Route path="/account" element={<Account />}/>
          <Route path="/signin" element={<SignIn />}/>
          <Route path="/signup" element={<SignUp />}/>
          <Route path="/join" element={<Join />}/>
          <Route path="/requestpasswordreset" element={<RequestPasswordReset />}/>
          <Route path="/passwordreset" element={<PasswordReset />}/>
          <Route path="/locations" element={<Locations />} />
          <Route path="/settings" element={<Settings />} />
          {/* <Route path="/about" element={About} /> */}
          <Route path="/subscribe" element={<Subscription />} />
          <Route path="/sphere" element={<ArmillarySphere />}/>
          <Route path="/" element={defaultPath()} />
        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover />
      </PageErrorBoundary>
    </div>
  )
}


