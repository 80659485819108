import PELatLng from './PELatLng';

export default class PELatLngBounds {
  
  /**
   * Constructs a PELatLngBounds
   * @param {PELatLng} center latitude/longitude of center point of bounds
   * @param {PELatLng} span latitude and longitude span of bounds
   */
  constructor (center, span) {
    this.center = center;
    this.span = span;
    
    // this.northEast = new PELatLng(
    //   center.lat + span.lat/2.0,
    //   center.lng + span.lng/2.0
    // );

    // this.southWest = new PELatLng(
    //   center.lat - span.lat/2.0,
    //   center.lng - span.lng/2.0
    // );
  }

  get northEast() {
    return new PELatLng(
      this.center.lat + this.span.lat/2.0,
      this.center.lng + this.span.lng/2.0
    );
  }

  get southWest() {
    return new PELatLng(
      this.center.lat - this.span.lat/2.0,
      this.center.lng - this.span.lng/2.0
    );
  }

  /**
   * Constructs and returns a new PELatLngBounds from the given corner points
   * @param {PELatLng} northEast northeast corner of desired bounds
   * @param {PELatLng} southWest southwest corner of desired bounds
   * @param {Number} minimumSpan optional minimum value for the span of the resulting bounds
   * @return {PELatLngBounds} Bounds covering the area described by the corners
   */
  static fromCorners(northEast, southWest, minimumSpan = 0.1) {
  
    const Δlat = Math.abs(northEast.lat - southWest.lat);
    const Δlng = Math.abs(northEast.lng - southWest.lng);

    const center = new PELatLng(
      southWest.lat + Δlat/2.0,
      southWest.lng + Δlng/2.0
    );
    
    const minSpan = Math.max(0.0, parseFloat(minimumSpan));
    const span = new PELatLng(
      Math.max(minSpan, Δlat),
      Math.max(minSpan, Δlng)
    );

    return new PELatLngBounds(center, span);
  }
  
  /**
   * Function to return a lat/lng bounds object
   * @param {Array} boundingBox Array of four floats representing S, N, W, E (lat, lat, lng, lng)
   * @param {Number} minimumSpan optional minimum value for the span of the resulting bounds, defaults to 0.1 degrees
   * @return {PELatLngBounds} Bounds derived from array values
   */
  static fromBoundingBoxArray(boundingBox, minimumSpan = 0.025) {

    if (!boundingBox || !Array.isArray(boundingBox) || boundingBox.length !== 4) {
      return;
    }
  
    let ne = new PELatLng( boundingBox[1], boundingBox[3] );
    let sw = new PELatLng( boundingBox[0], boundingBox[2] );

    return PELatLngBounds.fromCorners(ne, sw, minimumSpan);
  }

  /**
   * Expand the instance to include the given array of points
   * @param {[PELatLng]} latLngs an array of PELatLng instances 
   * @return {PELatLngBounds} returns the instance (this)
   */
  expandToInclude(latLngs) {

    const acc = {
      minLat: this.center.lat - this.span.lat/2.0,
      maxLat: this.center.lat + this.span.lat/2.0,
      minLng: this.center.lng - this.span.lng/2.0,
      maxLng: this.center.lng + this.span.lng/2.0
    }
    latLngs.reduce((acc, cur) => {

      acc.minLat = Math.min(acc.minLat, cur.lat);
      acc.maxLat = Math.max(acc.maxLat, cur.lat);
      acc.minLng = Math.min(acc.minLng, cur.lng);
      acc.maxLng = Math.max(acc.maxLng, cur.lng);

      return acc;
      
    }, acc);

    const updatedBounds = PELatLngBounds.fromCorners(new PELatLng(acc.maxLat, acc.maxLng), new PELatLng(acc.minLat, acc.minLng ), 0.001);

    this.center = updatedBounds.center;
    this.span = updatedBounds.span;

    return this;
  }

  /**
   * Expand (or contract) the span by multiplying by the given numerical factor
   * @param {number} factor 
   * @return {PELatLngBounds} returns the instance (this)
   */
  expandByFactor(factor) {

    this.span.lat *= factor;
    this.span.lng *= factor;

    return this;
  }
}