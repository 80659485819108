import React from 'react';
import * as Sentry from '@sentry/react';

function StillHavingTrouble({ eventId }) {
  return (
    <div className="pb-2 flex items-baseline gap-4">
      <div className="flex-1"></div>
      <div className="text-regent-gray-500">Still having trouble?</div>
      <button className='button button-info' onClick={() => Sentry.showReportDialog({ eventId: eventId })}>
        Send feedback
      </button>
    </div>
  )
}

export default StillHavingTrouble