import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import log from 'loglevel';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { logCentral } from './services/log-central';
import 'numeral/locales'; // Imports all available numeral locales

import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// See https://github.com/i18next/i18next-xhr-backend#backend-options for details
const backendOptions = {
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  loadPath: '/locales/{{lng}}/{{ns}}.json',
};

// See https://github.com/i18next/i18next-browser-languageDetector
const detectorOptions = {
  // order and from where user language should be detected
  order: ['querystring', 'cookie', 'localStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // only detect languages that are in the whitelist
  checkWhitelist: true,

  // fallback to a similar whitelist language
  // Example 1: Browser language is 'es'
  // if 'es' is not found in whitelist, first fallback to any whitelist language that starts with 'es-', then fallback to fallbackLng ('es' -> 'es-*' -> fallbackLng)
  // Example 2: Browser language is 'es-MX'
  // if 'es-MX' is not found in whitelist, first fallback to 'es', then fallback to 'es-*', then fallback to fallbackLng ('es-MX' -> 'es' -> 'es-*' -> fallbackLng)
  checkForSimilarInWhitelist: true,

}

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(HttpApi)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    whitelist: ['en', 'en-US', 'de', 'es-ES', 'fr', 'it', 'ko', 'pl'],
    detection: detectorOptions,
    // nonExplicitWhitelist: true, // if true will pass eg. en-US if finding en in whitelist
    load: 'currentOnly',
    fallbackLng: 'en',
    backend: backendOptions,
    debug: process.env.REACT_APP_I18N_DEBUG === true,
    // have a common namespace used around the full app
    ns: ['translation'],
    defaultNS: 'translation',

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
      format: function(value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        // if(value instanceof Date) return moment(value).format(format);
        return value;
    }
    }
  });

// On language changed
var lastLng;
i18n.on('languageChanged', function(lng) {
  
  // Prevent duplicate logged events
  if (lastLng !== lng) {

    logCentral.event({
      category: 'i18n',
      action: 'language_changed',
      label: lng
    });
    // log.info('** Language was changed to ' + lng + ' **');

    lastLng = lng;
  }

  // See public/scripts/iubenda-consent.js
  if (window?._ccc) {
    window._ccc.lang = lng;
  }
  
  if (moment.locale() !== lng) {
    // See https://momentjs.com/docs/#/use-it/browserify/ - not sure entirely why this is required here, 
    // but it seems to fix https://github.com/crookneck/tpe-web-app/issues/152
    require("moment/min/locales.js");
    moment.locale(lng);
  }

  changeNumeralLocale(lng);

});

i18n.on('failedLoading', function(lng, ns, msg) {
  
  logCentral.event({
    category: 'i18n',
    action: 'language_failed_to_load',
    label: lng
  }, {}, 'warn')

  // log.warn('Could not load language ' + lng + '!');
});

function changeNumeralLocale(lng) {

  // See https://github.com/adamwdraper/Numeral-js/issues/415 for background on loading locales
  if (numeral.locale() === lng) {
    return;
  }

  // We can obtain a list of registered locales like this: 
  // (not documented, I think, but apparent from source code at https://github.com/adamwdraper/Numeral-js/blob/master/src/numeral.js
  // see the source of the register() function)
  const availableLocales = Object.keys(numeral.locales);
  log.debug(availableLocales);

  // 'en-US' is not a supported locale for numeral.js, but will be very common, so, let's handle that case explicitly
  let candidate = lng.toLowerCase();

  let isAvailable = availableLocales.find(locale => locale === candidate);

  if (!isAvailable) {
    candidate = candidate.substring(0,2)
    // Try again with just initial two letter locale code:
    isAvailable = availableLocales.find(locale => locale === candidate);
  }

  if (!isAvailable) {
    candidate = 'en'; // default fallback
  }

  try {
    numeral.locale(candidate);
    if (!numeral.localeData()) {
      throw new Error('Numeral: locale data is undefined, ' + candidate);
    }
  } catch (e) {
    logCentral.error('numeral.js: unsupported locale', e, { lang: lng });
    log.error('numeral.js: unsupported locale, falling back to en: ' + lng);
    numeral.locale('en');
  }

  // If we wanted to load the locales dynamically - e.g. if we had our own additional locale files, we could do it as shown below.
  // However, no point in doing this for the bundled locales, as we can import them statically (see import 'numeral/locales' above)
  // Keeping this for future reference:
  /*
  if (lng === 'en') {
    // Just set it - no need to load. 'en' is the library default and there is no module to import
    numeral.locale(lng);
  } else {
    import('numeral/locales/' + lng)
      .then((locale) => {
        if (!locale) {
          throw new Error('Numeral: locale not found for ' + lng);
        }

        numeral.locale(lng);
        if (!numeral.localeData()) {
          throw new Error('Numeral: locale data undefined after import, ' + lng);
        }
      })
      .catch(e => {
        logCentral.error('numeral.js: unsupported locale', e, { lang: lng });
        log.error('numeral.js: unsupported locale, falling back to en: ' + lng);
        numeral.locale('en');
      });
  }*/
}

i18n.acceptLanguageHeaderValue = () => {

  let acceptLanguageValue;
  
  if (i18n.language) {
    acceptLanguageValue = i18n.language;
  }

  if (i18n?.language?.length > 2) {
    acceptLanguageValue = `${i18n.language},${i18n.language.substring(0,2)}`
  }
  
  if (!acceptLanguageValue) {
    acceptLanguageValue = 'en';
  }

  return acceptLanguageValue;

}

export default i18n;
