import React, { useMemo } from 'react'
import log from 'loglevel'
import { WEBGL } from 'lib/3d/webgl'
import ResizeObserver from 'resize-observer-polyfill';

/**
 * Finding the global variable.
 * See https://github.com/JRJurman/rollup-plugin-polyfill/issues/7#issuecomment-637109188
 */
// const myGlobal = (function () {
  // if (typeof global !== 'undefined' && global.Math === Math) {
  //     return global;
  // }

  // if (typeof self !== 'undefined' && self.Math === Math) {
  //     return self;
  // }

//   if (typeof window !== 'undefined' && window.Math === Math) {
//       return window;
//   }
// })();

// If ResizeObserver doesn't exist, set the Polyfill.
var wasPolyfilled = false;
if (!window.ResizeObserver) {
  log.warn('ResizeObserver not defined: using polyfill');
  window.ResizeObserver = ResizeObserver;
  wasPolyfilled = true;
}

export function useThreeCompatibility() {

  const compatibilityInfo = useMemo(() => {

    const webGLIsAvailable = WEBGL.isWebGLAvailable();
    var errorMessage = (<>Your system appears to be compatible with the required features (<a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" target="_blank" rel="noreferrer">WebGL</a> and <a href={'https://caniuse.com/?search=resizeobserver'} target='_blank' rel='noreferrer'>ResizeObserver</a>)</>)
    var resizeObserverIsAvailable;
    if (!webGLIsAvailable) {
      errorMessage = WEBGL.getWebGLErrorMessage();
    } else {
      const ResizeObserver = /*polyfill || (typeof window === 'undefined' ? class ResizeObserver {} :*/ window.ResizeObserver/*)*/;
      if (!ResizeObserver || wasPolyfilled === true) {
        resizeObserverIsAvailable = false;
        errorMessage = (
          <>Your browser does not appear to support <a href={'https://caniuse.com/?search=resizeobserver'} target='_blank' rel='noreferrer'>ResizeObserver</a></>
        )
      } else {
        resizeObserverIsAvailable = true;
      }
    }

    if (!webGLIsAvailable) {
      log.warn('WebGL is unavailable on this system');
    } else if (!resizeObserverIsAvailable) {
      log.warn('Browser does not have native support for ResizeObserver');
    }

    return {
      webGLIsAvailable: webGLIsAvailable,
      resizeObserverIsAvailable: resizeObserverIsAvailable,
      resizeObserverWasPolyfilled: wasPolyfilled,
      isCompatible: webGLIsAvailable && resizeObserverIsAvailable,
      errorMessage: errorMessage
    }
  }, []);

  return compatibilityInfo;
}