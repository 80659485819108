export const userServices = {
  protocol: process.env.REACT_APP_CPA_PROTOCOL,
  host: process.env.REACT_APP_CPA_HOST,
  port: process.env.REACT_APP_CPA_PORT
}

export const baseURL = process.env.REACT_APP_CPA_PROTOCOL + '://' + process.env.REACT_APP_CPA_HOST + ':' + process.env.REACT_APP_CPA_PORT;

export const makeURL = (pathAndQuery) => {

  let sUrl = baseURL + pathAndQuery;
  return sUrl;
};