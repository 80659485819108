import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { actions } from 'actions/subscribeActions';
import { getHasProEntitlement } from 'reselectors/userProfile';

import './premiumBadge.css';

export const BadgeDisplayMode = Object.freeze({
  premiumRequired: 0,
  premiumStatus: 1
});

export default function PremiumBadge({ displayMode, inverse, title, source, clickHandler }) {
  
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isPro = useSelector(getHasProEntitlement);

  var className = 'premiumBadge inline-block rounded text-xs mx-1 px-1 pt-0.5 pb-1 font-black uppercase tracking-wide border-none';
  var badgeText = t('subscriptions.premiumBadgeText');
  var titleText = '';
	switch (displayMode) {
		case BadgeDisplayMode.premiumRequired:
      if (isPro) {
        className += ' hidden';
      } else {
        className += ` ${inverse ? 'bg-white text-regent-gray-500 hover:bg-orange-600' : 'bg-regent-gray-500 text-white hover:bg-orange-600 hover:text-white'}`;
        titleText = t('subscriptions.upgradeForAccess');
      }
			break;
		case BadgeDisplayMode.premiumStatus:
		default:
      if (!isPro) {
        className += ' bg-orange-600 text-white no-underline focus:text-white focus:bg-orange-600 hover:text-white hover:bg-orange-500 text-md';
        badgeText = t('subscriptions.upgrade');
        titleText = t('subscriptions.upgradeForAccess');
      } else {
        className += ' bg-orange-600 text-white hover:fill-regent-gray-900';
        titleText = t('subscriptions.thanksForBeingPremium');
      }
			break;
  }
  
  if (title) {
    titleText = title;
  }

  let handleClick = () => {
    dispatch(actions.showSubscriptionPage(source || 'premium-badge'));
  }

  if (clickHandler) {
    handleClick = clickHandler;
  }

	return <button className={className} title={titleText} onClick={handleClick} >{badgeText}</button>;
}
