
/** 
 * Search action types
 */
export const actionTypes = {
  RECEIVED_SERVICE_CREDENTIALS: 'RECEIVED_SERVICE_CREDENTIALS',
  SEARCH_INPUT_CHANGED: 'HANDLE_SEARCH_INPUT',
  UPDATE_SEARCH_TERM: 'UPDATE_SEARCH_TERM',
  SUBMIT_SEARCH_RESULTS: 'SUBMIT_SEARCH_RESULTS',
  CLEAR_SEARCH_RESULTS: 'CLEAR_SEARCH_RESULTS',
  SELECT_SEARCH_RESULT: 'SELECT_SEARCH_RESULT',
  MOVE_TO_SEARCH_RESULT: 'MOVE_TO_SEARCH_RESULT',
  SEARCH_RESULTS_PENDING: 'SEARCH_RESULTS_PENDING',
  SAVE_SEARCH_RESULT: 'SAVE_SEARCH_RESULT' 
}

/** 
 * Search action creators
 */
export const actions = {
  receivedServiceCredentials: (credentials) => {
    return { type: actionTypes.RECEIVED_SERVICE_CREDENTIALS, payload: { credentials: credentials } };
  },
  /**
   * @param {String} searchTerm the input to the search
   * @param {Array} intents array including one or more of 'suggest', 'forward', 'reverse', 'parse'
   */
  searchInputChanged: (searchTerm, intents = ['parse', 'suggest']) => {
    return { type: actionTypes.SEARCH_INPUT_CHANGED, payload: { searchTerm: searchTerm, intents: intents } };
  },
  updateSearchTerm: (searchTerm) => {
    return { type: actionTypes.UPDATE_SEARCH_TERM, payload: { searchTerm: searchTerm } };
  },
  submitSearchResults: (results) => {
    return { type: actionTypes.SUBMIT_SEARCH_RESULTS, payload: { results: results } };
  },
  clearSearchResults: () => {
    return { type: actionTypes.CLEAR_SEARCH_RESULTS};
  },
  selectSearchResult: (placeId) => {
    return { type: actionTypes.SELECT_SEARCH_RESULT, payload: { placeId: placeId} };
  },
  moveToSearchResult: (placeId) => {
    return { type: actionTypes.MOVE_TO_SEARCH_RESULT, payload: { placeId: placeId} };
  },
  setSearchResultsPending: (pending) => {
    return { type: actionTypes.SEARCH_RESULTS_PENDING, payload: { pending: pending }};
  },
  saveSearchResult: (placeId) => {
    return { type: actionTypes.SAVE_SEARCH_RESULT, payload: { placeId: placeId } };
  }
}