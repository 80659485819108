import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import log from 'loglevel';

import { getSettings } from '../../selectors';

import { FacebookIcon, TwitterIcon, InstagramIcon, VimeoIcon } from '../icons/AppIcons';

import './footer.css';

export default function Footer() {

  const { t } = useTranslation();

  const settings = useSelector(getSettings);
	const isFullScreen = settings.fullScreenEnabled;

  const usingPaddleSandbox = useMemo(() => {
    log.debug("Using Paddle Sandbox:", process.env.REACT_APP_PADDLE_USE_SANDBOX)
    return process.env.REACT_APP_PADDLE_USE_SANDBOX === 'true'
  }, [])

  if (isFullScreen) {
		return (<></>)
	}

  return (
    <footer tabIndex="-1">
      <div>
        <ul>
          { process.env.REACT_APP_CPA_HOST !== 'api.photoephemeris.com' &&
            <li className="text-white font-bold">
              <span className="bg-red-800 text-xs inline-block px-2 mr-2 rounded-lg cursor-pointer" title="Using non-production backend">NON-PROD</span>
            </li>
          }
          { usingPaddleSandbox === true &&
            <li className="text-white font-bold">
              <span className="bg-red-800 text-xs inline-block px-2 rounded-lg cursor-pointer" title="Using Paddle sandbox environment">PADDLE SANDBOX</span>
            </li>
          }
          <li>
            <a rel="noopener noreferrer" href="https://twitter.com/photoephemeris" target="_blank">
                <TwitterIcon className="icon"/>
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href="https://www.facebook.com/photoEphemeris/" target="_blank">
                <FacebookIcon className="icon"/>
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href="https://instagram.com/photoephemeris" target="_blank">
              <InstagramIcon className="icon"/>
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href="https://vimeo.com/showcase/tpe-web" target="_blank">
              <VimeoIcon className="icon"/>
            </a>
          </li>
        </ul>
      </div>
      <div>
        <ul className='blurb'>
          <li>{process.env.REACT_APP_VERSION}</li>
          {/* <li>{t("footer.madeIn", { place: "Boulder, Colorado" })}</li> */}
          <li>&copy; 2010 Crookneck Consulting LLC. {t("footer.allRightsReserved")}.</li>
          <li>
            <a rel="noopener noreferrer" href="https://photoephemeris.com/terms-of-use" target="_blank">
              {t("footer.termsOfUse")}
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href={process.env.REACT_APP_PRIVACY_POLICY_URL} target="_blank">
              {t("footer.privacy")}
            </a>
          </li>
          <li>
            <a rel="noopener noreferrer" href={process.env.REACT_APP_COOKIE_POLICY_URL} target="_blank">{t("footer.cookies")}</a>
          </li>
          <li>
            <a rel="noopener noreferrer" href={process.env.REACT_APP_STATUS_PAGE_URL} target="_blank">Status</a>
          </li>
        </ul>
      </div>
		</footer>
  )
}
