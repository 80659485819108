import { createSelector } from 'reselect';
import moment from 'moment-timezone';
import { isNumber } from 'lodash';
import log from 'loglevel';

import RiseTransitSetEvents from '@stephent/meeusjs/lib/risetransitsetevents';
import LunarCoordinates from '@stephent/meeusjs/lib/lunarcoordinates';
import CrescentMoonVisibility from '@stephent/meeusjs/lib/crescentmoonvisibility';
import { MoonPhase } from '@stephent/meeusjs/lib/moonphase';
import { Eclipse } from '@stephent/meeusjs/lib/eclipse';
import { ApogeePerigee } from '@stephent/meeusjs/lib/apogeeperigee';
import { solsticeEquinoxEventsForYear } from '@stephent/meeusjs/lib/solsticeequinox';
import SolarCoordinates from '@stephent/meeusjs/lib/solarcoordinates';

import { WMMFactory } from '../wmm/wmm';

import { MeeusEvents, decorateLunarEvent } from 'lib/meeusevents';

import { getDateTime, getIsAdjustingTimeOfDay, getCoordinate, getTimeZone, getElevation, getSettings } from 'selectors';
import { getElevationAboveHorizon } from 'reselectors';
import { getHasProEntitlement } from 'reselectors/userProfile';

const EVENT_KEYS = RiseTransitSetEvents.EVENT_KEYS;

const DATE_KEY_FORMAT = "YYYYMMDD";

/**
 * A memoized selector that returns local midday on the day corresponding to the selected date time and time zone
 * Midday only changes when the selected date time and/or time zone changes. Use this selector to trigger recalculation
 * of derived data that depends only the selected day, rather then specific date/time, e.g. timeline events.
 */
export const getMiddayDateTime = createSelector(
  [getDateTime, getTimeZone],
  (dateTime, timeZoneObj) => {

    // Middle of the day - result should only change when the local day changes
    const result = moment(dateTime).tz(timeZoneObj.timeZoneId).startOf('day').hour(12).valueOf();
    return result;
  }
)

export const getDateTimeAsMomentWithTZOffset = createSelector(
  [getDateTime, getTimeZone],
  (dateTime, timeZoneObj) => {
    
    const tzId = timeZoneObj.timeZoneId || 'Etc/GMT';
    
    const mo1 = moment(dateTime); // in default time zone
    const mo2 = moment(dateTime).tz(tzId); // in current state time zone

    const modifiedDate = moment(dateTime);
    
    modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');

    return modifiedDate.valueOf();
  }
)

export const getDateTimeAsMoment = createSelector(
  [getDateTime, getTimeZone],
  (dateTime, timeZoneObj) => {
    
    const tzId = timeZoneObj.timeZoneId || 'Etc/GMT';

    const result = moment(dateTime).tz(tzId);
    return result;
  }
)

export const getStartOfDay = createSelector(
  [getDateTime, getTimeZone],
  (dateTime, timeZoneObj) => {
    
    const result = moment(dateTime).tz(timeZoneObj.timeZoneId).startOf('day');
    return result.valueOf();
  }
)

export const getEndOfDay = createSelector(
  [getDateTime, getTimeZone],
  (dateTime, timeZoneObj) => {
    
    const result = moment(dateTime).tz(timeZoneObj.timeZoneId).endOf('day').subtract(1, 'second');
    return result.valueOf();
  }
)

/**
 * A memoized selector that returns local midday on the day corresponding to the selected date time and time zone
 * Midday only changes when the selected date time and/or time zone changes. Use this selector to trigger recalculation
 * of derived data that depends only the selected day, rather then specific date/time, e.g. timeline events.
 */
export const getInitialTimeOfDay = createSelector(
  [getDateTimeAsMoment],
  (moment) => {

    // Middle of the day - result should only change when the local day changes
    const result = moment.startOf('day').hour(12);
    return result.valueOf();
  }
)

export const getDateKey = createSelector(
  [getDateTimeAsMoment],
  (moment) => {
    const key = moment.format(DATE_KEY_FORMAT);
    return key;
  }
)

export const getSelectedYear = createSelector(
  [getDateTimeAsMoment],
  (moment) => {

    return moment.year();
  }
)

export const getMoonPhaseEventsForYear = createSelector(
  [getSelectedYear, getTimeZone, getSettings],
  (year, timeZoneObj, settings) => {

    const moonKey = settings.bodyKeys.find(key => key === EVENT_KEYS.MEMoonPosition);
    if (!moonKey) {
      return [];
    }

    const tzid = timeZoneObj.timeZoneId;
    if (typeof tzid !== 'string') {
      log.error('getMoonPhaseEventsForYear: timeZoneId is not a string', tzid, typeof(timeZoneId));
      return [];
    }

    // Sorted - we need them in sequence for the Crescent Moon event determination
    const events = MoonPhase.allOccurrencesInYear(year, true, true);

    events.forEach(event => {
      // Mutate the moment to set the required time zone, https://momentjs.com/timezone/docs/#/using-timezones/converting-to-zone/
      event.moment.tz(tzid);
    })

    return events;
  }
)

export const getLunarEclipseEventsForYear = createSelector(
  [getSelectedYear, getTimeZone, getSettings, getMoonPhaseEventsForYear],
  (year, timeZoneObj, settings, moonPhaseEvents) => {

    const moonKey = settings.bodyKeys.find(key => key === EVENT_KEYS.MEMoonPosition);
    if (!moonKey) {
      return [];
    }

    const tzid = timeZoneObj.timeZoneId;
    if (typeof tzid !== 'string') {
      log.error('getLunarEclipseEventsForYear: timeZoneId is not a string', tzid, typeof(timeZoneId));
      return [];
    }

    // Only Lunar Eclipses (near full moon)
    const eclipsePossibleEvents = moonPhaseEvents.filter(event =>  event.eclipsePossible === true && event.key === EVENT_KEYS.MMFull);

    // Unsorted, flat array of events
    if (Array.prototype.flat) {
      const eclipseEvents = eclipsePossibleEvents.map(event => {
        const eclipse = Eclipse.calculate(event);
        if (eclipse && eclipse.events) {
          return eclipse.events;
        }
        return null;
      }).flat().filter(event => event !== null);

      eclipseEvents.forEach(event => {
        event.moment = moment(event.timestamp).tz(tzid);
      })

      return eclipseEvents;
    } else {
      log.warn('Cannot return eclipse events: browser does not support Array.prototype.flat');
    }

    return [];
  }
)

export const getLunarApogeePerigeeEventsForYear = createSelector(
  [getSelectedYear, getTimeZone, getSettings],
  (year, timeZoneObj, settings) => {

    const moonKey = settings.bodyKeys.find(key => key === EVENT_KEYS.MEMoonPosition);
    if (!moonKey) {
      return [];
    }

    const tzid = timeZoneObj.timeZoneId;
    if (typeof tzid !== 'string') {
      log.error('getLunarApogeePerigeeEventsForYear: timeZoneId is not a string', tzid, typeof(timeZoneId));
      return [];
    }

    // All events, not sorted (they'll get sorted later on)
    const events = ApogeePerigee.apogeePerigeeForYear(year, false);

    events.forEach(event => {
      // Mutate the moment to set the required time zone, https://momentjs.com/timezone/docs/#/using-timezones/converting-to-zone/
      event.moment.tz(tzid);
    })

    return events;
  }
)

export const getSolsticeEquinoxesForYear = createSelector(
  [getSelectedYear, getTimeZone],
  (year, timeZoneObj) => {

    const tzid = timeZoneObj.timeZoneId;
    if (typeof tzid !== 'string') {
      log.error('getSolsticeEquinoxesForYear: timeZoneId is not a string', tzid, typeof(timeZoneId));
      return [];
    }

    // Method returns a single object by default - we want an array
    const events = Object.values(solsticeEquinoxEventsForYear(year));

    events.forEach(event => {
      // Mutate the moment to set the required time zone, https://momentjs.com/timezone/docs/#/using-timezones/converting-to-zone/
      event.moment.tz(tzid);
    })

    return events;
  }
)

export const getLunarEventsForYear = createSelector(
  [getMoonPhaseEventsForYear, getLunarEclipseEventsForYear, getLunarApogeePerigeeEventsForYear, getSolsticeEquinoxesForYear, getCoordinate, getElevation],
  (moonPhaseEvents, lunarEclipseEvents, apogeePerigeeEvents, solEqEvents, coordinate, elevationObj) => {

    // Combine all the relevant events:
    const lunarEvents = [ ...moonPhaseEvents, ...lunarEclipseEvents, ...apogeePerigeeEvents].filter(event => event.moment);

    var elevation = 0; // default to MSL
    if (isNumber(elevationObj.value)) {
      elevation = elevationObj.value;
    }

    const lc = new LunarCoordinates();
    lunarEvents.forEach( moonEvent => {
      decorateLunarEvent(moonEvent, coordinate, elevation, lc);
    });

    lunarEvents.sort((a, b) => a.moment.diff(b.moment));

    return lunarEvents;
  }
)

export const getAllEventsForYear = createSelector(
  [getLunarEventsForYear, getSolsticeEquinoxesForYear],
  (lunarEvents, solEqEvents) => {

    var events = [ ...lunarEvents, ...solEqEvents ].filter(event => event.moment);

    events.sort((a, b) => a.moment.diff(b.moment));

    return events;
  }
)

export const getEntitledEventsForYear = createSelector(
  [getAllEventsForYear, getHasProEntitlement],
  (eventsForYear, isPro) => {

    if (!eventsForYear) {
      return [];
    }

    const events = eventsForYear.filter(event => (event.moment && (isPro || !MeeusEvents.PREMIUM_KEYS.includes(event.key))));

    return events;
  }
)

export const getPreviousEvent = createSelector(
  [getDateTimeAsMoment, getEntitledEventsForYear],
  (mo, events) => {

    let previousEvent;
    for (var i=events.length - 1; i>-1; i--) {
      const event = events[i];
      if (event.moment.isBefore(mo)) {
        previousEvent = event;
        break;
      }
    }

    return previousEvent;
  }
)

export const getNextEvent = createSelector(
  [getDateTimeAsMoment, getEntitledEventsForYear],
  (mo, events) => {

    let nextEvent;
    for (var i=0; i<events.length; i++) {
      var event = events[i];
      if (event.moment.isAfter(mo)) {
        nextEvent = event;
        break;
      }
    }

    return nextEvent;
  }
)

export const getLunarEclipseEventsForTimeline = createSelector(
  [getDateKey, getLunarEclipseEventsForYear],
  (dateKey, events) => {

    const eventsForDate = events.filter(event => event.moment.format(DATE_KEY_FORMAT) === dateKey);
    return eventsForDate;
  }
)

export const getLunarEventsForTimeline = createSelector(
  [getDateKey, getLunarEventsForYear],
  (dateKey, events) => {

    const eventsForDate = events.filter(event => event.moment.format(DATE_KEY_FORMAT) === dateKey);
    return eventsForDate;
  }
)

export const getSolsticeEquinoxEventsForTimeline = createSelector(
  [getDateKey, getSolsticeEquinoxesForYear],
  (dateKey, events) => {

    const eventsForDate = events.filter(event => event.moment.format(DATE_KEY_FORMAT) === dateKey);
    return eventsForDate;
  }
)

/**
 * A memoized selector for rise/transit/set timeline events (meeus output) that depends on getDateTime and getCoordinate
 */
export const getRTSTimelineEvents = createSelector(
  [getMiddayDateTime, getCoordinate, getTimeZone, getElevation, getElevationAboveHorizon, getSettings],
  (dateTime, coordinate, timeZoneObj, elevationObj, elevationAboveHorizon, settings) => {
    log.debug("getTimelineEvents called: ** calculating events ** ");

    const tzid = timeZoneObj.timeZoneId;
    if (typeof tzid !== 'string') {
      log.error('getTimelineEvents: timeZoneId is not a string', tzid, typeof(timeZoneId));
      return [];
    }

    var elevation = 0; // default to MSL
    if (isNumber(elevationObj.value)) {
      elevation = elevationObj.value;
    }

    const rtsEvents = new RiseTransitSetEvents(moment(dateTime).tz(tzid), coordinate.lat, -coordinate.lng, elevation, elevationAboveHorizon);
    // Only calculate the events we're interested in from Settings
    const events = rtsEvents.eventsForKeys(settings.eventKeys);
    //log.debug(events);

    return events;
  }
)

/**
 * Memoized selector for crescent moon visibility events relevant to the current timeline sunset and moonset events.
 * @returns {Array} an empty Array or an Array containing a single event corresponding to the time of best visibility of the crescent moon
 */
export const getCrescentMoonVisibilityEvents = createSelector(
  [getRTSTimelineEvents, getMoonPhaseEventsForYear, getCoordinate, getElevation],
  (rtsEvents, moonPhaseEvents, coordinate, elevationObj) => {

    const sunset = rtsEvents.find(event =>  event.key === EVENT_KEYS.SSSet);
    if (!sunset) {
      return [];
    }

    const moonset = rtsEvents.find(event =>  event.key === EVENT_KEYS.MMSet);
    if (!moonset) {
      return [];
    }

    // Find the next moon phase event after sunset
    const nextMoonphase = moonPhaseEvents.find(event => event.moment.isAfter(sunset.moment));
    
    // We only calculate crescent moon visibility between new moon and first quarter
    if (!nextMoonphase || nextMoonphase.key !== EVENT_KEYS.MMFirstQuarter) {
      return [];
    }

    var elevation = 0; // default to MSL
    if (isNumber(elevationObj.value)) {
      elevation = elevationObj.value;
    }
    
    const cmv = new CrescentMoonVisibility(sunset.moment, moonset.moment, coordinate.lat, -coordinate.lng, elevation).calculate();

    // It seems the CMV algorithm can calculate a best time which is after moonset - that doesn't make any sense at all, so discard in that case.
    if (cmv.bestMoment.isAfter(moonset.moment)) {
      return [];
    }
    
    const event = {
      key: EVENT_KEYS.MMCrescentMoonBestVisibility,
      moment: cmv.bestMoment,
      description: cmv.classification
    }

    return [event];
  }
)

/**
 * A memoized selector for timeline events (meeus output) that depends on getDateTime and getCoordinate
 */
export const getTimelineEvents = createSelector(
  [getRTSTimelineEvents, getLunarEventsForTimeline, getCrescentMoonVisibilityEvents, getSolsticeEquinoxEventsForTimeline, getCoordinate, getElevation],
  (rtsEvents, lunarEvents, cmvEvents, solEqEvents, coordinate, elevationObj) => {
    log.debug("getTimelineEvents called: ** calculating events ** ");

    var elevation = 0; // default to MSL
    if (isNumber(elevationObj.value)) {
      elevation = elevationObj.value;
    }

    // Decorate solstice equinox events with altitude/azimuth
    let sc = new SolarCoordinates();
    solEqEvents.forEach( solEqEvent => {
      // give it a clone so we don't mutate the original event time
      const mo = moment(solEqEvent.moment);
      sc.calculate(mo).calculateLocalHorizontal(coordinate.lat, -coordinate.lng, elevation);
      solEqEvent.azimuth = sc.azimuth
      solEqEvent.altitude = sc.altitude
      solEqEvent.apparentAltitude = sc.apparentAltitude
    });

    // Decorate moon RTS and CMX events
    const moonRTSEvents = rtsEvents.filter(event => RiseTransitSetEvents.MOON_RELATED_KEYS.includes(event.key));
    const moonRTSCMVEvents = [...moonRTSEvents, ...cmvEvents];
    let lc = new LunarCoordinates()
    moonRTSCMVEvents.forEach(event => {
      decorateLunarEvent(event, coordinate, elevation, lc);
    })

    // Combine all the relevant events:
    var events = [ ...rtsEvents, ...lunarEvents, ...cmvEvents, ...solEqEvents ].filter(event => event.moment);
    events.sort((a, b) => a.moment.diff(b.moment));

    return events;
  }
)

export const getTotalLunarEclipseTimespanForDay = createSelector(
  [getLunarEclipseEventsForTimeline],
  (lunarEclipseEvents) => {

    const eventsArray = lunarEclipseEvents.filter(event => MeeusEvents.TOTAL_LUNAR_ECLIPSE_SPAN_KEYS.includes(event.key));
    
    if (eventsArray.length > 0) {
      const eventsObj = eventsArray.reduce((object, item) => {
        return {
          ...object,
          [item.key]: item
        }
      }, {});
      return eventsObj;
    }

    return null;
  }
)

export const getCurrentEvent = createSelector(
  [getDateTime, getIsAdjustingTimeOfDay, getTimelineEvents],
  ( dateTime, isAdjusting, events) => {

    // Don't compute this while user is adjusting time of day - too expensive
    // if (isAdjusting) {
    //   return;
    // }

    const mo = moment(dateTime);

    const currentEvent = events.find(event => {
     
      const deltaMS = Math.abs(event.moment.diff(mo));
      if (deltaMS < 15000) { // within 30 seconds
        return true;
      }

      return false;
    });

    return currentEvent;
  }
)

export const getWMM = createSelector(
  [getSettings, getSelectedYear],
  (settings, year) => {

    if (settings.useMagneticNorth !== true) {
      return null;
    }

    // Choose date in middle of selected year - that's good enough
    const model = WMMFactory(moment(year + '-07-02T00:00:00Z'));

    return model;

  }
)

export const getMagneticDeclination = createSelector(
  [getWMM, getMiddayDateTime, getCoordinate, getElevation],
  (magneticModel, dateTime, coordinate, elevationObj) => {
    log.debug("getMagneticDeclination called");

    if (!magneticModel) {
      return null;
    }

    var elevation = 0; // default to MSL
    if (isNumber(elevationObj.value)) {
      elevation = elevationObj.value;
    }

    const mo = moment(dateTime);
    const approxDecimalYear = mo.year() + mo.dayOfYear()/366;

    const decl = magneticModel.declination(elevation/1000, coordinate.lat, coordinate.lng, approxDecimalYear);

    return decl;
  }

)