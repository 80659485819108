import { takeEvery, takeLatest, put, select, call } from "redux-saga/effects";
import { intersection } from 'lodash';
import i18n from 'i18next';
import log from 'loglevel';
import RiseTransitSetEvents, { relatedEventsForBodies } from '@stephent/meeusjs/lib/risetransitsetevents';

import { MeeusEvents } from 'lib/meeusevents';
import { logCentral } from 'services/log-central';
import { actionTypes, actions } from 'actions/settingsActions';
import { getHasProEntitlement } from 'reselectors/userProfile';
import { selectUserPreferences } from 'reselectors/settings';
import { getDate } from 'selectors';
import { makeURL } from '../config';
import { authorizedRequest, AuthorizedRequestError } from './authSaga';
import { actions as mapActions } from 'actions/mapActions'
import { actions as dateActions } from 'actions/dateActions'
import { QS_SOURCES } from 'actions/rootActions'

const PREFERENCES_KEY = 'TPEW';

/** Put preferences function that returns an axios options object */
function putPreferencesApiOptions(prefs) {
  
  return {
    method: 'put',
    url: makeURL('/user/preferences/' + PREFERENCES_KEY),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    },
    data: prefs
  };
}

/** Get preferences function that returns an axios options object */
function getPreferencesApiOptions(prefs) {
  
  return {
    method: 'get',
    url: makeURL('/user/preferences/' + PREFERENCES_KEY),
    headers: {
      'Accept': 'application/json',
    },
    validateStatus: function (status) {
      return (status >= 200 && status < 300) || status === 404; // don't error on 404s
    },
  };
}

/**
 * Saga to derive additional settings state from the action payload. This includes logic to 
 * check entitlements for privileged settings. This prevents a bad actor simply enabling a control such as a switch
 * to enable a setting or feature that should require an entitlement. We double check the entitlement here before
 * allowing such features to be enabled.
 * @param {Object} action redux action with a settings object as the payload property value
 */
function* deriveSettings(action) {

  const isPro = yield select(getHasProEntitlement);

  let derivedSettings = action.payload.settings;
  
  if (!isPro) {
    // Settings that require a premium entitlement should be coerced into non-premium state:
    const premiumBodies = [RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition, RiseTransitSetEvents.EVENT_KEYS.MEMeteors];
    if (Array.isArray(derivedSettings.bodyKeys)) {
      derivedSettings.bodyKeys = derivedSettings.bodyKeys.filter( ( key ) => !premiumBodies.includes(key) );
    }
    derivedSettings = { ...derivedSettings, usePremiumMapProvider: false, useMagneticNorth: false, elSvc: undefined, sphereTerrain: false };
  }

  // .eventKeys is dependent on .bodyKeys: e.g. if bodyKeys does not include Moon, then event keys should
  // not include any moon-related events etc. We handle deriving that data here as an input to the redux store
  // We derive this AFTER the (!isPro) check so that we've already excluded any disallowed body keys
  if (Array.isArray(derivedSettings.bodyKeys)) {
    const relatedEventKeys = relatedEventsForBodies(derivedSettings.bodyKeys);
    const includedEvents = intersection(MeeusEvents.ALLOWED_TIMELINE_KEYS, relatedEventKeys);

    derivedSettings.eventKeys = includedEvents;
  } 

  if (derivedSettings.lang) {
    try {
      // Call a promise returning function directly https://stackoverflow.com/a/59144265/220287
      yield i18n.changeLanguage(derivedSettings.lang);
    } catch (error) {
      log.error('Error setting language: ' + error.message);
      logCentral.error('Error setting language', error);
      // Invalid language
      delete derivedSettings.lang;
    }
  }

  yield put(actions.updateSettings(derivedSettings));

  // Now save the user's preferences to the server
  if (!action.payload.noSave) {
    const prefs = yield select(selectUserPreferences);
    yield put(actions.putUserPrefs(prefs));
  }

  // Finally, make sure we have the right elevation data
  yield put(mapActions.verifyElevations());
}

/**
 * Saga to save the user's preferences to the server. No further actions are dispatched if the call fails
 */
function* putUserPreferences() {

  try {

    let prefs = yield select(selectUserPreferences);

    // write to back end, as localstorage can be wiped
    let options = putPreferencesApiOptions(prefs);
    let request = yield call(authorizedRequest, options);
    yield call(request);
  
  } catch (e) {
    if ((e instanceof AuthorizedRequestError) === false) {
      log.error(e, e.response || '');
      logCentral.error('Save preferences failed', e);
    }    
  }
}

/**
 * Saga to get the user's preferences from the server. Once received, we dispatch an action to update local settings state with
 * the results.
 */
function* getUserPreferences() {

  try {

    yield put(actions.getUserPrefsPending(true));

    let options = getPreferencesApiOptions();
    let request = yield call(authorizedRequest, options);
    let { data } = yield call(request);
    
    if (data) {
      // Re-inject the user preferences into our state
      yield put(actions.deriveUpdatedSettings(data, true));
    }

    if (data?.defaultQs) {
      yield put(mapActions.synchMapToQueryString(data.defaultQs, QS_SOURCES.userPreferences));
    }

    if (data?.useNow === true) {
      const { source } = yield select(getDate);
      // Never override a date derived from the URL
      if (source !== QS_SOURCES.windowLocation) {
        yield put(dateActions.setDateTime(new Date().valueOf(), QS_SOURCES.userPreferences));
      } else {
        log.info('Not setting to current date/time from user prefs: a date/time was included in the URL')
      }
    }

  } catch (e) {
    let shouldLog = true;
    if ((e instanceof AuthorizedRequestError) === false) {
      shouldLog = false;
    } else  if (e.response && e.response.status && e.response.status === 401) {
      shouldLog = false;
    } else if (e.message === 'Request failed with status code 401') {
      shouldLog = false;
    }

    if (shouldLog) {
      logCentral.error('Get preferences failed', e);
    }
  } finally {
    yield put(actions.getUserPrefsPending(false));
  }
}


/**
 * Settings related saga watcher
 */
export function* settingsSagas() {
  yield takeEvery(actionTypes.DERIVE_UPDATED_SETTINGS, deriveSettings);
  yield takeLatest(actionTypes.PUT_USER_PREFS, putUserPreferences);
  yield takeLatest(actionTypes.GET_USER_PREFS, getUserPreferences);
}
