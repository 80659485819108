export const QS_SOURCES = {
  localStorage: 'localstorage',
  windowLocation: 'window.location',
  userPreferences: 'user.preferences',
  userInteraction: 'user.interaction'
}

/*
 * action types
 */

export const actionTypes = {
  DERIVE_UPDATED_QUERY_STRING: 'DERIVE_UPDATED_QUERY_STRING'
};

/*
* action creators
*/

export const actions = {
  deriveUpdatedQueryString: () => {
    return { type: actionTypes.DERIVE_UPDATED_QUERY_STRING }
  }
}
