import { QS_SOURCES } from 'actions/rootActions'

/*
 * action types
 */

export const actionTypes = {
    SYNCH_DATE_TO_QUERY_STRING: 'SYNCH_DATE_TO_QUERY_STRING',
    NEXT_DAY: 'NEXT_DAY',
    PREVIOUS_DAY: 'PREVIOUS_DAY',
    NEXT_EVENT: 'NEXT_EVENT',
    PREVIOUS_EVENT: 'PREVIOUS_EVENT',
    SET_DATE: 'SET_DATE',
    SET_TIME_OF_DAY: 'SET_TIME_OF_DAY',
    SET_DATETIME: 'SET_DATETIME',
    UPDATE_IS_ADJUSTING_TIME_OF_DAY: 'UPDATE_IS_ADJUSTING_TIME_OF_DAY'
}

/*
 * action creators
 */

export const actions = {
    synchDateToQueryString: (search, source) => {
        return { type: actionTypes.SYNCH_DATE_TO_QUERY_STRING, payload: { search: search, source: source } }
    },
    nextDay: () => {
        return { type: actionTypes.NEXT_DAY }
    },
    previousDay: () => {
        return { type: actionTypes.PREVIOUS_DAY }
    },
    nextEvent: () => {
        return { type: actionTypes.NEXT_EVENT }
    },
    previousEvent: () => {
        return { type: actionTypes.PREVIOUS_EVENT }
    },
    setDate: (dateTime) => {
        return { type: actionTypes.SET_DATE, payload: {dateTime: dateTime} }
    },
    setTimeOfDay: (dateTime, isDone) => {
        return { type: actionTypes.SET_TIME_OF_DAY, payload: { dateTime: dateTime, isDone: isDone }};
    },
    setDateTime: (dateTime, source = QS_SOURCES.userInteraction) => {
        return { type: actionTypes.SET_DATETIME, payload: { dateTime: dateTime, source: source } }
    },
    updateIsAdjustingTimeOfDay: (isAdjusting, refresh = false ) => {
        return { type: actionTypes.UPDATE_IS_ADJUSTING_TIME_OF_DAY, payload: { isAdjusting: isAdjusting, refresh: refresh }};
    }
}