import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Zendesk, { ZendeskAPI } from "react-zendesk";
import { confirmAlert } from 'react-confirm-alert';
import log from 'loglevel';
import { useTranslation } from 'react-i18next';

import { getUserProfile } from 'selectors';
import { actions } from 'actions/userProfileActions';

import { useIubendaIsLoaded, useIubendaConsent, purposes } from 'hooks/iubenda-consent';

function ZendeskPlaceholder() {

  const { t } = useTranslation();

  const cookieSolutionIsLoaded = useIubendaIsLoaded();

  const onClick = () => {

    let buttons = [
      {
        label: t('general.close'),
        className: 'cancel',
        onClick: () => { }
      }
    ];

    if (cookieSolutionIsLoaded) {
      buttons.push({
        label: t('cookies.cookiePreferences'),
        onClick: () => {
          window._iub?.cs.api.openPreferences();
        }
      })
    }

    confirmAlert({
      title: t('footer.cookies'),
      message: t("cookies.cookiePurposeRequiredNotice", { purpose: t('cookies.purpose.basic') }),
      buttons: buttons,
    });
  }

  return (
    <div className="w-28 h-12 absolute bottom-1.5 right-4 z-50 overflow-visible opacity-100">
      <div className="float-right">
        <button type="button" className="whitespace-nowrap font-bold h-auto py-1 px-6 text-lg text-white hover:text-white border-4 border-zendesk-gray hover:border-regent-gray-500 bg-zendesk-gray rounded-full normal-case tracking-[-0.005rem]" onClick={onClick}>
          {t('general.help')}
        </button>
      </div>
    </div>
  )
}

export default function ZendeskWebWidget() {

  const dispatch = useDispatch();
  const [isLoaded, setIsLoaded] = useState(false);

  const cookieSolutionIsLoaded = useIubendaIsLoaded();
  const cookieConsentOK = useIubendaConsent(purposes.basic);

  // Optimistic - if cookie solution not loaded, just show Zendesk anyway - user may need help!
  const canLoadZDWidget = cookieConsentOK || !cookieSolutionIsLoaded;

  if (!canLoadZDWidget) {
    log.warn(`Cannot load Zendesk widget (iub loaded:${cookieSolutionIsLoaded}, consent OK:${cookieConsentOK})`);
  }

  const [deferredLoadReady, setDeferredLoadReady] = useState(cookieConsentOK);

  // Rationale for deferred load: window?.google_tag_manager may be unavailable immediately on page load. If so, then
  // recheck for consent after a couple of seconds
  useEffect(() => {

    if (cookieConsentOK === true) {
      return
    }

    let timeout = setTimeout(() => {
      log.debug('Re-rendering to check cookie consent')
      setDeferredLoadReady(true)
    }, 10000);

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    }
  }, [cookieConsentOK, deferredLoadReady]);


  const { email, firstname, lastname } = useSelector(getUserProfile);

  // Zendesk widget presets
  const zendeskConfig = {
    color: {
      theme: "#4C5362" //"#282C34"
    },
    // launcher: {
    //   chatLabel: {
    //     "en-US": "Need Help"
    //   }
    // },
    contactForm: {
      attachments: true,
      // fields: [
      //   { id: "description", prefill: { "*": "My pre-filled description" } }
      // ]
    }
  };

  const onLoaded = () => {
    log.debug('Zendesk web widget is loaded');
    setIsLoaded(true);
    dispatch(actions.zendeskLoaded(true));
  }

  useEffect(() => {

    if (isLoaded) {
      let name = '';
      if (firstname && lastname) {
        name = firstname + ' ' + lastname;
      }

      if (name !== '' && email) {
        ZendeskAPI('webWidget', 'identify', {
          name: name,
          email: email || '',
        });
      }

      ZendeskAPI('webWidget', 'prefill', {
        name: {
          value: name,
          readOnly: false // optional
        },
        email: {
          value: email || '',
          readOnly: email ? true : false // optional
        }
      });

      ZendeskAPI('webWidget', 'updateSettings', {
        webWidget: {
          contactForm: {
            suppress: false
          },
          helpCenter: {
            suppress: false,
            filter: {
              category: '201674863-General,200169713-TPE-Desktop-Web-App'
            }
          },
          offset: {
            horizontal: '-4px',
            vertical: '-6px'
          }
        }
      });

      ZendeskAPI('webWidget:on', 'close', () => {
        
        // Re-enable the help center in the Widget - see SubscriptionRecord. 
        // We disable it when we want to ensure the widget shows a contact form upon opening
        // See https://support.zendesk.com/hc/en-us/articles/4408834300314/comments/4408850234266
        ZendeskAPI('webWidget', 'updateSettings', {
          webWidget: {
            helpCenter: {
              suppress: false,
            }
          }
        });

      })
    }

  }, [isLoaded, email, firstname, lastname]);

  return (
    <>
      {canLoadZDWidget ?
        <Zendesk zendeskKey={process.env.REACT_APP_ZENDESK_KEY} {...zendeskConfig} onLoaded={onLoaded} /> :
        <>
          {deferredLoadReady &&
            <ZendeskPlaceholder />
          }
        </>
      }
    </>
  )
}
