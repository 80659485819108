import { combineReducers } from 'redux'

// Reducers
import { dateTime } from './dateTime'
import { map } from './map'
import { userProfile } from './userProfile'
import { search } from './search'
import { settings } from './settings'
import { subscriptions } from './subscriptions'
import { locations } from './locations'
import { visualSearch } from './visualSearch'

export const rootReducer = (routerReducer) => combineReducers({
  router: routerReducer,
  dateTime,
  map,
  userProfile,
  search,
  settings,
  subscriptions,
  locations,
  visualSearch
});

