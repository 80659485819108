import { all, fork, put, takeLatest, select } from 'redux-saga/effects'; // https://redux-saga.js.org/
import { replace } from 'redux-first-history';
import log from 'loglevel';

import { authSagas } from './authSaga'
import { userProfileSagas } from './userProfileSaga'
import { mapSagas } from './mapSaga'
import { dateSagas } from './dateSaga'
import { searchSagas } from './searchSaga'
import { settingsSagas } from './settingsSaga'
import { subscribeSagas } from './subscribeSaga'
import { locationSagas } from './locationsSaga'
import { visualSearchSagas } from './visualSearchSaga'

import { actionTypes, QS_SOURCES } from 'actions/rootActions';

import { actions as mapActions } from 'actions/mapActions';
import { actions as dateActions } from 'actions/dateActions';
import { actions as subscribeActions} from 'actions/subscribeActions';

import { getQueryString } from 'reselectors'

function* restoreLastState() {

  if (!window.location.search) {

    let savedQueryString;
    
    try {
      savedQueryString = localStorage.getItem('mostRecentQueryString');
    } catch (error) {
      log.error(`Could not get item from local storage: ${error.message}`);
    }

    if (savedQueryString) {
      log.debug('Restoring last known application state: ' + savedQueryString);
      // Remember to dispatch synch-to-browser-location actions, also:
      yield put(mapActions.synchMapToQueryString(savedQueryString, QS_SOURCES.localStorage));
      yield put(dateActions.synchDateToQueryString(savedQueryString, QS_SOURCES.localStorage));
    } else {
      log.debug('Not restoring state from local storage - no prior state was stored');
    }
  } else {
    log.debug('Not restoring state from local storage - query string was provided');
  }
}

function* synchToQueryString() {

  switch (window.location.pathname) {
    case '/':
      if (window.location.search) {
        log.debug('Synching to query string at load: ' + window.location.search)
        const decodedQS = decodeURIComponent(window.location.search)
        yield put(subscribeActions.synchToQueryString(decodedQS))
        yield put(mapActions.synchMapToQueryString(decodedQS, QS_SOURCES.windowLocation))
        yield put(dateActions.synchDateToQueryString(decodedQS, QS_SOURCES.windowLocation))
        yield put(replace(''))
      } else {
        log.debug('Not synching to query string at load - no query string provided')
      }
      break;
    default:
      // e.g. /passwordreset - don't mess with that!
      break;        
  }
}

function* deriveUpdatedQueryString() {

  const qs = yield select(getQueryString)

  try {
    localStorage.setItem('mostRecentQueryString', qs || '');  
  } catch (error) {
    log.warn('Could not save query string to local storage!');
  }

  // const qsIsValid = yield select(getQueryStringIsValid)
  // if (qsIsValid !== true) {
  //   yield put(push("?" + qs))
  // }
}

export function* rootSagas() {

  yield fork(restoreLastState);
  yield fork(synchToQueryString);
  yield takeLatest(actionTypes.DERIVE_UPDATED_QUERY_STRING, deriveUpdatedQueryString);
}

export default function* rootSaga() {
  yield all([
    rootSagas(),
    authSagas(),
    userProfileSagas(),
    mapSagas(),
    dateSagas(),
    searchSagas(),
    settingsSagas(),
    subscribeSagas(),
    locationSagas(),
    visualSearchSagas()
    // add other watchers to the array
  ]);
}