import { ReactComponent as DoubleArrowLeft } from './double-chevron-left.svg';
import { ReactComponent as DoubleArrowRight } from './double-chevron-right.svg';
import { ReactComponent as ArrowLeft } from './glyphicons-basic-223-chevron-left.svg';
import { ReactComponent as ArrowRight } from './glyphicons-basic-224-chevron-right.svg';
import { ReactComponent as Clock } from './glyphicons-basic-55-clock.svg';
import { ReactComponent as Edit } from './edit.svg'; //'./glyphicons-basic-31-pencil.svg';
import { ReactComponent as Expand } from './glyphicons-basic-796-set-down.svg';
import { ReactComponent as Map } from './map-control-icon.svg'; //'./glyphicons-basic-592-map.svg';
import { ReactComponent as Synch } from './glyphicons-basic-82-refresh.svg';
import { ReactComponent as Add } from './glyphicons-basic-371-plus.svg';
import { ReactComponent as Minus } from './glyphicons-basic-372-minus.svg';
import { ReactComponent as RedPin } from './location-to-red.svg';
import { ReactComponent as GreyPin } from './location-to-grey.svg';
import { ReactComponent as Notes } from './glyphicons-basic-40-notes.svg';
import { ReactComponent as Email} from './glyphicons-basic-130-envelope-star.svg';
import { ReactComponent as Download } from './save.svg'; //'./glyphicons-basic-546-cloud-download.svg';
import { ReactComponent as Export } from  './glyphicons-basic-546-cloud-download.svg';
import { ReactComponent as Upload } from  './glyphicons-basic-545-cloud-upload.svg';
import { ReactComponent as Search } from './search.svg'; 
import { ReactComponent as Check } from './glyphicons-basic-739-check.svg';
import { ReactComponent as CircleWarning } from './glyphicons-basic-197-circle-empty-alert.svg';
import { ReactComponent as TriangleAlert } from './glyphicons-basic-638-triangle-alert.svg';
import { ReactComponent as AppleShare } from './apple-share.svg' //'./glyphicons-basic-301-square-upload.svg'
import { ReactComponent as GoogleShare } from './glyphicons-basic-578-share.svg'
import { ReactComponent as Link } from './glyphicons-basic-351-link.svg'
import { ReactComponent as CogWheel } from './glyphicons-basic-137-cogwheel.svg'
import { ReactComponent as VisualSearch } from './visual-search-icon.svg'
import { ReactComponent as ThreeDTerrain } from './3d-terrain-icon.svg'

import { ReactComponent as Star } from './glyphicons-basic-49-star.svg'
import { ReactComponent as StarEmpty } from './glyphicons-basic-50-star-empty.svg'

import { ReactComponent as ArrowDown } from './glyphicons-basic-211-arrow-down.svg';
import { ReactComponent as ArrowUp } from './glyphicons-basic-212-arrow-up.svg';
import { ReactComponent as List } from './glyphicons-basic-115-list.svg';
import { ReactComponent as Starred } from './glyphicons-basic-610-star-of-life.svg';
import { ReactComponent as Binoculars } from './glyphicons-basic-26-binoculars.svg';
import { ReactComponent as EyeOff } from './glyphicons-basic-53-eye-off.svg';
import { ReactComponent as CrescentMoon } from './glyphicons-basic-231-moon.svg';
import { ReactComponent as Sun } from './glyphicons-basic-232-sun.svg';
import { ReactComponent as MilkyWay } from './milky-way.svg';
import { ReactComponent as RadiantPoint } from './meteor.svg';

import { ReactComponent as FullScreenOn } from './glyphicons-halflings-122-fullscreen.svg';
import { ReactComponent as FullScreenOff } from './glyphicons-halflings-121-fullscreen-off.svg';

import { ReactComponent as LocationOff } from './glyphicons-basic-262-direction-empty.svg';
import { ReactComponent as LocationOn } from './glyphicons-basic-263-direction-full.svg';

import { ReactComponent as Question }  from './glyphicons-basic-195-circle-empty-question.svg';
import { ReactComponent as QuestionFilled } from './glyphicons-basic-635-circle-question.svg';
import { ReactComponent as CopyDuplicate } from './glyphicons-basic-611-copy-duplicate.svg'

import { ReactComponent as Facebook } from './facebook.svg';
import { ReactComponent as Instagram } from './instagram.svg';
import { ReactComponent as Twitter } from './twitter.svg';
import { ReactComponent as Vimeo } from './vimeo.svg';
import { ReactComponent as YouTube } from './youtube.svg';
import { ReactComponent as Medium } from './medium.svg';

import { ReactComponent as BulbOn } from './lightbulb-on.svg';
import { ReactComponent as BulbOff } from './lightbulb-off.svg';

import { ReactComponent as Contrast } from './glyphicons-basic-92-contrast.svg';
import { ReactComponent as CircleSelected } from './glyphicons-basic-299-circle-selected.svg';

import { ReactComponent as Building } from './glyphicons-basic-90-building.svg';

export const DoubleArrowLeftIcon = DoubleArrowLeft;
export const DoubleArrowRightIcon = DoubleArrowRight;
export const ArrowLeftIcon = ArrowLeft;
export const ArrowRightIcon = ArrowRight;
export const ClockIcon = Clock;
export const EditIcon = Edit;
export const ExpandIcon = Expand;
export const MapIcon = Map;
export const SynchIcon = Synch;
export const AddIcon = Add;
export const MinusIcon = Minus
export const RedPinIcon = RedPin;
export const GreyPinIcon = GreyPin;
export const NotesIcon = Notes;
export const EmailIcon = Email;
export const DownloadIcon = Download;
export const UploadIcon = Upload;
export const ExportIcon = Export;
export const SearchIcon = Search;
export const CheckIcon = Check;
export const CircleWarningIcon = CircleWarning;
export const TriangleWarningIcon = TriangleAlert;

export const StarIcon = Star;
export const StarEmptyIcon = StarEmpty;

export const ArrowDownIcon = ArrowDown;
export const ArrowUpIcon = ArrowUp;
export const ListIcon = List;
export const StarredIcon = Starred;
export const BinocularsIcon = Binoculars;
export const EyeOffIcon = EyeOff;
export const CrescentMoonIcon = CrescentMoon;
export const SunIcon = Sun;
export const MilkyWayIcon = MilkyWay;
export const RadiantPointIcon = RadiantPoint;

export const FullScreenOnIcon = FullScreenOn;
export const FullScreenOffIcon = FullScreenOff;

export const LocationOffIcon = LocationOff;
export const LocationOnIcon = LocationOn;

export const QuestionIcon = Question;
export const QuestionFilledIcon = QuestionFilled;
export const CopyDuplicateIcon = CopyDuplicate

export const FacebookIcon = Facebook;
export const InstagramIcon = Instagram;
export const TwitterIcon = Twitter;
export const VimeoIcon = Vimeo;
export const YouTubeIcon = YouTube;
export const MediumIcon = Medium;

export const AppleShareIcon = AppleShare
export const GoogleShareIcon = GoogleShare
export const LinkIcon = Link

export const CogWheelIcon = CogWheel
export const VisualSearchIcon = VisualSearch
export const ThreeDTerrainIcon = ThreeDTerrain
export const BulbOnIcon = BulbOn
export const BulbOffIcon = BulbOff

export const ContrastIcon = Contrast;
export const CircleSelectedIcon = CircleSelected;

export const BuildingIcon = Building;