import { createReducer } from '@reduxjs/toolkit';
import _ from 'lodash';
import log from 'loglevel';

import { actionTypes } from '../actions/subscribeActions';

const initialState = {
	plans: [],
	selectedPlanId: undefined,
	leadSource: undefined,
	coupon: undefined,
	customer: undefined,
	order: undefined,
	requestCustomerPending: false,
	paymentMethodDetails: undefined,
	purchasedSubcription: undefined,
	requestPlansError: undefined, // error is set if app cannot retrieve product plans
	requestCustomerError: undefined,
	requestPaymentMethodDetailsError: undefined,
	paymentMethodError: undefined, // error is set if customer cannot be created/updated with default payment method
	paymentConfirmationError: undefined, // TODO: error is set if customer and subscription is created, but payment fails
	planChanges: {},
	entitlementFeatures: { // These correspond to i18next string resources - just join with '.' to obtain valid id, e.g. entitlementFeatures.free.streetMap
		'tpe-free': [
			'streetMap',
			'sunriseSunset',
			'moonriseMoonset',
			'sunMoonAzAlt',
			'moonPhase',
			'placenameSearch',
			/*'limitedAddressSearch',*/
			'decimalCoordinateEntry',
			'geodetics',
			'visualSearchSun'
		],
		'tpe-premium': [
			'googleMaps',
			'streetView',
			'premiumMaps',
			'celestialSphere',
			'3DTerrain',
			'searchAutocomplete',
			'unlimitedAddressSearch',
			'reverseGeocoding',
			'elevationDataServices',
			'magneticDeclination',
			'visualSearchSunMoonGC',
			'visualSearchBuildingHeight'
		]
	},
	entitlementLinks: {
		'streetMap' : 'https://support.crookneckapps.com/hc/en-us/articles/360045429712',
		'sunriseSunset' : 'https://support.crookneckapps.com/hc/en-us/articles/360000377246',
		'moonriseMoonset' : 'https://support.crookneckapps.com/hc/en-us/articles/360000377246',
		'sunMoonAzAlt' : 'https://support.crookneckapps.com/hc/en-us/articles/360000378923',
		'moonPhase' : 'https://support.crookneckapps.com/hc/en-us/articles/115004227163',
		'geodetics' : 'https://support.crookneckapps.com/hc/en-us/articles/360000377386',
		'googleMaps' : 'https://support.crookneckapps.com/hc/en-us/articles/360045429712',
		'premiumMaps' : 'https://support.crookneckapps.com/hc/en-us/articles/360045429712',
		'3DTerrain' : 'https://support.crookneckapps.com/hc/en-us/articles/360059826312',
		'visualSearchSun' : 'https://support.crookneckapps.com/hc/en-us/articles/360049932411',
		'visualSearchSunMoonGC' : 'https://support.crookneckapps.com/hc/en-us/articles/360049932411',
		'celestialSphere' : 'https://support.crookneckapps.com/hc/en-us/articles/360047112252',
		'reverseGeocoding' : 'https://support.crookneckapps.com/hc/en-us/articles/360000377406-Using-Photo-Ephemeris-Web-Part-5-Locations',
		'elevationDataServices' : 'https://support.crookneckapps.com/hc/en-us/articles/360045184651',
		'magneticDeclination' : 'https://support.crookneckapps.com/hc/en-us/articles/360048571791',
		'placenameSearch' : 'https://support.crookneckapps.com/hc/en-us/articles/360000377406',
		'searchAutocomplete' : 'https://support.crookneckapps.com/hc/en-us/articles/360000377406',
		'visualSearchBuildingHeight': 'https://support.crookneckapps.com/hc/en-us/articles/4415975098004'
	}
};

/**
 * Note: this uses redux-toolkit createReducer so we can write mutating code for state
 * See https://redux-toolkit.js.org/api/createReducer
 */
export const subscriptions = createReducer(initialState, {
	[actionTypes.REQUEST_PRODUCT_PLANS_SUCCESS]: (state, action) => {
		// Mutate state to update product plans
		state.plans = action.payload;
		state.requestPlansError = undefined;
	},
	[actionTypes.REQUEST_PRODUCT_PLANS_FAILURE]: (state, action) => {
		// Mutate state to update product plans
		state.plans = [];
		state.requestPlansError = action.payload;
	},
	[actionTypes.REQUEST_PAYMENT_PLATFORM_CUSTOMER_FAILED]: (state, action) => {
		// Mutate state to store error and clear any previous customer
		state.customer = undefined;
		state.requestCustomerError = action.payload;
		state.requestCustomerPending = false;
	},
	[actionTypes.SELECT_PRODUCT_PLAN]: (state, action) => {
		const planId = action.payload.planId;

		if (_.isNil(planId)) {
			log.error('Select product plan: planId missing!');
			return;
		}

		const matchingPlan = state.plans.find((plan) => plan.product_id === planId);
		if (!matchingPlan) {
			log.warn(`Selected product plan not found in current state: ${planId}`);
		}

		// mutate state to update the selected planId
		state.selectedPlanId = planId;
	},
	[actionTypes.PAYMENT_METHOD_FAILED]: (state, action) => {
		// mutate state to store the error
		state.paymentMethodError = action.payload;
	},
	[actionTypes.REQUEST_PAYMENT_PLATFORM_CUSTOMER]: (state, action) => {
		state.requestCustomerPending = true;
	},
	[actionTypes.PAYMENT_PLATFORM_CUSTOMER_RECEIVED]: (state, action) => {
		// mutate state to set the customer object
		// If the customer has changed (e.g. new customer, and/or new payment method), then any existing payment method details may be invalid
		state.customer = action.payload;
		state.requestCustomerError = undefined;
		state.requestCustomerPending = false;
		state.paymentMethodError = undefined;

		// If the customer has changed (e.g. new customer, and/or new payment method), then any existing payment
		// method details may be invalid, in which case they should be cleared.
		if (state.paymentMethodDetails && state.customer && state.customer.invoice_settings) {
			if (state.paymentMethodDetails.id !== state.customer.invoice_settings.default_payment_method) {
				state.paymentMethodDetails = undefined;
			}
		}
	},
	[actionTypes.SUBSCRIPTION_RECEIVED]: (state, action) => {
		// mutate state to set the new subscription
		state.purchasedSubcription = action.payload;
	},
	[actionTypes.PAYMENT_METHOD_DETAILS_RECEIVED]: (state, action) => {
		// mutate state to set the new subscription
		state.paymentMethodDetails = action.payload;
		state.requestPaymentMethodDetailsError = undefined;
	},
	[actionTypes.REQUEST_PAYMENT_METHOD_DETAILS_FAILED]: (state, action) => {
		// mutate state to set the new subscription
		state.paymentMethodDetails = undefined;
		state.requestPaymentMethodDetailsError = action.payload;
	},
	[actionTypes.UPDATE_WITH_ORDER_DETAILS]: (state, action) => {
		if (action.payload) {
			state.order = action.payload;
		}
	},
	[actionTypes.CLEAR_SUBSCRIPTION_DATA] : (state, action) => {

		return {...initialState, coupon: state.coupon };
	},
	[actionTypes.SET_COUPON] : (state, action) => {

		return { ...state, coupon: action.payload.coupon };
	},
	[actionTypes.SET_LEAD_SOURCE] : (state, action) => {

		return { ...state, leadSource: action.payload.source };
	},
	[actionTypes.SHOW_AVAILABLE_PLANS] : (state, action) => {

		return { ...state, showAvailablePlans: action.payload.enabled };
	},
	[actionTypes.HANDLE_PLAN_CHANGES] : (state, action) => {

		let updatedPlanChanges = { ...state.planChanges, ...action.payload };
    return { ...state, planChanges: updatedPlanChanges };
	}
});
