import { useMemo } from 'react';
import log from 'loglevel';

export const purposes = {
  necessary: '1',
  basic: '2',
  experience: '3',
  measurement: '4',
  targeting: '5'
}

export function useIubendaIsLoaded() {

  // Only need to check this once, so can memoize (FWIW)
  const isLoaded = useMemo(() => {

    let iubApi = window?._iub?.cs?.api;

    if (!iubApi) {
      log.warn("Iubenda cookie solution API not avaialble!");
      return false;
    }

    return true;
  }, []);

  return isLoaded;
}

export function useIubendaConsent(purpose) {

  const iub = window?._iub;

  const consent = useMemo(() => {

    const gdprApplies = iub?.cs?.api?.gdprApplies();

    // `gdprApplies` may be true, false, or undefined if iub script not loaded
    if (gdprApplies !== true) {
      log.debug('useIubendaConsent: gdpr does not apply');
      return true;
    }

    let localConsent = iub?.cs?.cookie?.loadConsentLocal();

    if (!localConsent || !localConsent.purposes) {
      log.warn('Iubenda local consent purposes unavailable');
      return null;
    }

    const consentForPurpose = localConsent.purposes[purpose];

    return consentForPurpose

  }, [iub, purpose])
  
  return consent;
}
