import { all, takeEvery, call, put, select, cancelled } from 'redux-saga/effects';
import axios from 'axios';
import { isNumber } from 'lodash';
import log from 'loglevel';
import { logCentral } from '../services/log-central';

import { getServiceCredentials, getMapState, getGeodetics, getSettings, getCoordinate } from 'selectors';
import { getDistinctMapServiceNames, getCredentialsMap, getAvailableElevationSvcs } from 'reselectors/map';
import { actionTypes as mapActionTypes, actions as mapActions } from 'actions/mapActions';
import { actionTypes as authActionTypes } from 'actions/authActions';
import { actions as rootActions, QS_SOURCES } from 'actions/rootActions';
import { makeURL } from '../config';
import { authorizedRequest, AuthorizedRequestError } from './authSaga';

import { deriveMapState } from 'lib/urls';

class GoogleMapsNotLoadedError extends Error {
  constructor(/*message, options*/) {
    // Needs to pass both `message` and `options` to install the "cause" property.
    super('Google Maps not loaded');
  }
}

/** Get service status function that returns an axios call */
function getServicesDetailsOptions(serviceNames) {

  let params = {
    'q': serviceNames.join(','),
    'projectID': process.env.REACT_APP_GEOCODING_PROJECT_ID // required in order to obtain a project key for crookneck-geo
  };

  return {
    method: 'get',
    url: makeURL('/services/details'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    params: params
  };
}

function getTimeZoneRequest(latLng, credentials) {

  return axios.request({
    method: 'get',
    url: 'https://secure.geonames.net/timezoneJSON?lat=' + latLng.lat.toFixed(4) + '&lng=' + latLng.lng.toFixed(4) + '&username=' + credentials.user + '&token=' + credentials.token,
    headers: {
      'Accept': 'application/json'
    }
  });
}

function getGoogleElevationPromise(latLng) {

  const p = new Promise((resolve, reject) => {

    if (!window.google) {
      return reject(new GoogleMapsNotLoadedError());
    }

    const elevator = new window.google.maps.ElevationService();
    elevator.getElevationForLocations({
      'locations': [latLng]
    }, function (results, status) {
      resolve({
        data: {
          results: results,
          status: status
        }
      });
    });
  });

  return p
}

/**
 * Returns an axios request to obtain elevation for the given latitude/longitude using the given DEM
 * 
 * @param {*} latLng object with .lat and .lng properties, representing the coordinate of interest
 * @param {*} credentials object with token and user properties, used to authenticate to Geonames
 * @param {string} credentials string with name of DEM to use. Must be one of srtm3, srtm1, astergdem, or gtopo30
 */
function getElevationPromise(latLng, credentials, dem = 'strm3') {

  if (dem === 'google') {
    // return axios.request({
    //   method: 'get',
    //   url: 'https://maps.googleapis.com/maps/api/elevation/json?key=' + credentials.token + '&locations=' + latLng.lat.toFixed(6) + ',' + latLng.lng.toFixed(6),
    //   headers: {
    //     'Accept': 'application/json'
    //   }
    // });  
    return getGoogleElevationPromise(latLng);
  }

  return axios.request({
    method: 'get',
    url: 'https://secure.geonames.net/' + dem + 'JSON?lat=' + latLng.lat.toFixed(4) + '&lng=' + latLng.lng.toFixed(4) + '&username=' + credentials.user + '&token=' + credentials.token,
    headers: {
      'Accept': 'application/json'
    }
  });
}

/**
 * Returns a time zone identifier determined from the geonames web service response body object
 * @param {Object} tzResponseData response.data object returned from getTimeZone
 * @returns {String} time zone identifier or null
 */
const getTimeZoneId = (tzResponseData) => {

  var tzId = null;
  if (tzResponseData && tzResponseData.timezoneId) {
    tzId = tzResponseData.timezoneId;
  } else {
    /*
      Response for oceanic areas does not include timezoneId, so we need to build our own, e.g.
      {
        dstOffset: 0
        gmtOffset: -3
        lat: 41.725
        lng: -49.95
        rawOffset: -3
      }
      */
    if (isNumber(tzResponseData.gmtOffset)) {
      var offsetInt = parseInt(tzResponseData.gmtOffset);
      if (0 === offsetInt) {
        tzId = 'Etc/UTC';
      } else if (0 > offsetInt) {
        tzId = 'Etc/GMT+' + Math.abs(tzResponseData.gmtOffset);
      } else {
        tzId = 'Etc/GMT-' + Math.abs(tzResponseData.gmtOffset);
      }
    }
  }

  return tzId;
}

/**
 * Returns an object based on the geonames web service response body object. The outbound call 
 * (see getElevation) may be to one of four different services (astergdem/strm3/srtm1/gtopo30/google). This function
 * normalizes the response into an object with source and value properties, or null if the elevation is unknown.
 * @param {Object} elResponseData response.data object returned from getElevation
 * @param {string} dem name of the dem used to request the response data
 * @returns {Object} an object with source, value and verified=true, if value known, otherwise { verified: false }
 */
function getElevationResult(elResponseData, dem) {

  var result = {
    verified: false
  };

  if (!elResponseData) {
    return result;
  }

  if (dem === 'google') {

    if (elResponseData.status === 'OK' && elResponseData.results[0]) {
      result = {
        source: dem,
        value: elResponseData.results[0].elevation,
        verified: true
      }
    } else {
      logCentral.error('Google elevation response error', new Error('Google elevation service status was ' + elResponseData.status));
    }

  } else {

    /*
    Sample response from Geonames:
    {
      "srtm3": 274,
      "lng": -3.0073,
      "lat": 16.7688
    }
    */

    if (isNumber(elResponseData.lat)) {
      const elevation = elResponseData[dem];
      var unknownElevation;
      switch (dem) {
        case 'gtopo30':
          unknownElevation = -9999;
          break;
        default:
          unknownElevation = -32768;
          break;
      }
      if (elevation && parseInt(elevation) !== unknownElevation) {
        result = {
          source: dem,
          value: parseInt(elevation),
          verified: true
        }
      }
    }
  }

  return result;
}

/////////////////////////////////
// Generators
/////////////////////////////////

function* getSvcCredentials() {

  try {

    log.debug('getSvcCredentials: called');

    const credentials = yield select(getServiceCredentials);
    if (credentials) {
      // Already got them...
      return;
    }

    const serviceNames = yield select(getDistinctMapServiceNames);

    // data is obtained after axios call is resolved
    let request = yield call(authorizedRequest, getServicesDetailsOptions(serviceNames));
    let { data } = yield call(request);

    // log.debug('getSvcCredentials:', data);

    // dispatch action to change redux state
    yield put(mapActions.requestSvcCredentialsSuccess(data));

    const { elevation, geodetics, timeZone, coordinate } = yield select(getMapState);

    if (!elevation.verified || !timeZone.verified) {
      log.debug('Time zone and/or elevation not verified: querying now');
      yield put(mapActions.queryTimeZoneElevation(coordinate));
    }

    if (geodetics && geodetics.coordinate && !geodetics?.elevation?.verified) {
      log.debug("Secondary elevation not verified, queriying now");
      yield put(mapActions.querySecondaryElevation());
    }

  } catch (e) {
    let shouldLog = true;
    if ((e instanceof AuthorizedRequestError) === true) {
      shouldLog = false;
    } else if (e.response && e.response.status && e.response.status === 401) {
      shouldLog = false;
    } else if (e.message === 'Request failed with status code 401') {
      shouldLog = false;
    }

    if (shouldLog === true) {
      logCentral.error('Get service credentials failed', e);
    } else {
      // Log only to console
      log.error(e);
    }

    yield put(mapActions.requestSvcCredentialsFailure());
  }
}

function* primaryCoordinateDidChange(action) {

  yield put(mapActions.updateMapState({ coordinate: action.payload }));

  // We need to query for new elevation and time zone here
  yield put(mapActions.queryTimeZoneElevation(action.payload));
  yield put(rootActions.deriveUpdatedQueryString());
}

/**
 * When the map zoom, center or span are changed, we update the router query string, and pass the changes 
 * through to the map state reducer. If a zoom level is included, it will override any previously set span
 * and the spn query parameter is removed from the query string.
 * @param {Object} action a redux action
 */
function* handleMapStateChange(action) {

  yield put(mapActions.updateMapState(action.payload));
  yield put(rootActions.deriveUpdatedQueryString());
}

function* elevationService() {

  const elevationSvcs = yield select(getAvailableElevationSvcs);
  const { elSvc } = yield select(getSettings);
  const elSource = elevationSvcs.includes(elSvc) ? elSvc : elevationSvcs[0];

  if (!elSource) {
    log.warn('Cannot query elevation: no source available');
    yield put(mapActions.queryTimeZoneElevationFailure());
  }

  return elSource;
}

function* elevationCredentials(svc) {

  // get credentials for the timezone service
  let credentialsMap = yield select(getCredentialsMap);
  var elCredentials;

  switch (svc) {
    case 'google':
      elCredentials = credentialsMap.get('premiumMap');
      break;
    default:
      elCredentials = credentialsMap.get('elevation');
      break;
  }

  if (!elCredentials) {
    log.debug('Cannot query elevation: credentials required');
    yield put(mapActions.queryTimeZoneElevationFailure());
    // don't throw an error here - just creates noise.
  }

  return elCredentials;
}

/**
 * Saga to check that the elevation data we currently have matches the elevation data we actually want
 * i.e. is verified from the currently selected service - if not, then dispatch actions to go get it afresh
 */
function* verifyElevations() {

  const elSource = yield elevationService();
  const { elevation, geodetics } = yield select(getMapState);

  if (elevation.verified !== true || elevation.source !== elSource) {
    log.debug('Elevation not verified or invalid source - requerying');
    yield put(mapActions.queryElevation());
  }

  if (geodetics.enabled === true) {
    if (!geodetics.elevation || geodetics.elevation.verified !== true || geodetics.elevation.source !== elSource) {
      log.debug('Secondary elevation not verified or invalid source - requerying');
      yield put(mapActions.querySecondaryElevation());
    }
  }

}

/**
 * Saga to requery the elevation at the primary coordinate
 */
function* queryElevationSaga() {

  try {

    log.debug('mapSaga.queryElevationSaga');

    const coordinate = yield select(getCoordinate);
    const elSource = yield elevationService();
    const elCredentials = yield elevationCredentials(elSource);

    // data is obtained after axios call is resolved
    if (elSource && elCredentials) {
      const elResult = yield call(getElevationPromise, coordinate, elCredentials, elSource);
      const elObj = getElevationResult(elResult.data, elSource);
      yield put(mapActions.updateElevation(elObj));
    }

  } catch (e) {

    if (e instanceof GoogleMapsNotLoadedError) {
      log.warn(e.message);
    } else {
      logCentral.error('Query elevation failed', e);
    }
    yield put(mapActions.queryTimeZoneElevationFailure());
  } finally {
    // because we're using takeLatest, if the primary map pin is moved again before this generator completes, then 
    // redux-saga will cancel any previous calls that are still running. Hence, we should not need to worry about handling 
    // out-dated responses
    if (yield cancelled()) {
      log.debug('mapSaga.queryElevationSaga: cancelled');
    }
  }
}

function* queryTimeZoneElevationSaga(action) {

  try {

    log.debug('mapSaga.queryTimeZoneElevation', action);

    const elSource = yield elevationService();
    const elCredentials = yield elevationCredentials(elSource);

    // get credentials for the timezone service
    let credentialsMap = yield select(getCredentialsMap);
    let tzCredentials = credentialsMap.get('timezone');

    if (!tzCredentials || !elCredentials) {
      log.debug('Cannot query time zone and elevation: credentials required');
      yield put(mapActions.queryTimeZoneElevationFailure());
      return; // don't throw an error here - just creates noise.
    }

    // Mark time zone as unverified
    yield put(mapActions.updateTimeZone({ verified: null }));
    yield put(mapActions.updateElevation({ verified: null }));

    // data is obtained after axios call is resolved
    const [tzResult, elResult] = yield all([
      call(getTimeZoneRequest, action.payload, tzCredentials),
      call(getElevationPromise, action.payload, elCredentials, elSource)
    ]);

    const tzId = getTimeZoneId(tzResult.data);
    if (tzId) {
      yield put(mapActions.updateTimeZone({ timeZoneId: tzId, verified: true }));
    } else {
      log.warn('mapSaga.queryTimeZoneElevation: could not get timeZoneId from response', tzResult.data);
    }

    const elObj = getElevationResult(elResult.data, elSource);
    yield put(mapActions.updateElevation(elObj));

  } catch (e) {
    if (e.message === 'Network Error') {
      log.error(e.message);
    } else {
      logCentral.error('Query time zone/elevation failed', e);
    }
    yield put(mapActions.queryTimeZoneElevationFailure());
  } finally {
    // because we're using takeLatest, if the primary map pin is moved again before this generator completes, then 
    // redux-saga will cancel any previous calls that are still running. Hence, we should not need to worry about handling 
    // out-dated responses
    if (yield cancelled()) {
      log.debug('mapSaga.queryTimeZoneElevation: cancelled');
    }
  }
}

function* enableGeodeticsSaga(action) {

  log.debug('mapSaga.enableGeodeticsSaga', action);

  let geodetics = yield select(getGeodetics);

  let { enabled } = action.payload;
  if (enabled === undefined) {
    enabled = (geodetics.enabled === false); // toggle state
  }

  const changes = {
    enabled: enabled,
    error: undefined // clear any error on change of enabled state
  }

  if (enabled === true) {
    const { center, halfSpan } = yield select(getMapState);
    if (!geodetics.coordinate) {
      // Calculate a suitable default position for the secondary pin: due east and within the map bounds
      changes.coordinate = { lat: center.lat, lng: center.lng + halfSpan.lng * 0.8 };
      yield put(mapActions.updateGeodeticsCoordinate(changes.coordinate));
    } else if (geodetics.coordinate.lat > center.lat + halfSpan.lat || geodetics.coordinate.lat < center.lat - halfSpan.lat
      || geodetics.coordinate.lng > center.lng + halfSpan.lng || geodetics.coordinate.lng < center.lng - halfSpan.lng) {
      // Check if existing coordinate is within the map bounds - if not, reset to default.
      changes.coordinate = { lat: center.lat, lng: center.lng + halfSpan.lng * 0.8 };
      yield put(mapActions.updateGeodeticsCoordinate(changes.coordinate));
    } else {
      yield put(mapActions.updateGeodeticsCoordinate(geodetics.coordinate));
    }
  } else {
    yield put(mapActions.updateGeodetics(changes));
    yield put(rootActions.deriveUpdatedQueryString());
  }
}

function* updateGeodeticsCoordinateSaga(action) {

  log.debug('mapSaga.updateGeodeticsCoordinateSaga', action);

  // Get the new coordinate
  let { coordinate } = action.payload;

  let geodetics = yield select(getGeodetics);

  const changes = {
    enabled: true, // set enabled to true if we're setting the coordinate
    coordinate: coordinate,
    elevation: { ...geodetics?.elevation, verified: null },
    error: undefined
  }

  yield put(mapActions.updateGeodetics(changes));
  yield put(rootActions.deriveUpdatedQueryString());

  // Get the secondary elevation
  yield put(mapActions.querySecondaryElevation());

}

function* querySecondaryElevationSaga() {

  log.debug('mapSaga.secondaryElevationSaga');

  // current state
  let geodetics = yield select(getGeodetics);

  const changes = {
    elevation: { ...geodetics?.elevation, verified: null },
    error: undefined
  }

  try {

    if (!geodetics.coordinate) {
      throw new Error('Get secondary elevation : coordinate missing');
    }

    yield put(mapActions.geodeticsElevationPending(true));

    const elSource = yield elevationService();
    const elCredentials = yield elevationCredentials(elSource);

    if (elSource && elCredentials) {
      const elResult = yield call(getElevationPromise, geodetics.coordinate, elCredentials, elSource);
      changes.elevation = getElevationResult(elResult.data, elSource);
    } else {
      log.warn('Cannot query elevation: credentials and source required');
    }

  } catch (e) {
    logCentral.error('Query secondary elevation failed', e);
    changes.error = { message: e.message, code: e.code }; // a plain object for redux state
  } finally {

    yield put(mapActions.geodeticsElevationPending(false));
    yield put(mapActions.updateGeodetics(changes));
  }
}

function* swapPinsSaga() {

  // current map state
  let currentState = yield select(getMapState);

  const primary = currentState.coordinate;
  const secondary = currentState.geodetics.coordinate;

  if (!secondary) {
    log.warn('Cannot swap pin positions: secondary coordinate not defined');
    return;
  }

  // Put primary coordinate to secondary position
  yield put(mapActions.handlePrimaryPinDidMove(secondary));
  // Put secondary coordinate to primary position
  yield put(mapActions.updateGeodeticsCoordinate(primary));

  // Swap the object heights
  const swapped = { 
    objectHeights: { 
      primary: currentState.objectHeights.secondary, 
      secondary: currentState.objectHeights.primary 
    },
    objectFootprint: { 
      primary: currentState.objectFootprint.secondary, 
      secondary: currentState.objectFootprint.primary 
    }
  }
  yield put(mapActions.updateMapState(swapped));
}

/**
 * A saga that is run at app start-up to read the map state from the browser location query parameters, if any, and to synchronize state
 */
function* synchMapToQueryString(action) {

  const { search, source } = action.payload;
  const { source: priorSource } = yield select(getMapState);

  log.debug(`Synching map to query string ${search} from ${source}`);

  if (source && priorSource) {
    if (source === QS_SOURCES.userPreferences && priorSource === QS_SOURCES.windowLocation) {
      // Never override the given URL with the user's default preferences
      log.info(`Discarding query string from ${source} - ${priorSource} takes precedence`);
      return;
    }
  }

  let mapOptions = deriveMapState(search, true);

  if (source) {
    mapOptions.source = source;
  }

  log.debug("Updating map state from query params:", mapOptions);
  yield put(mapActions.updateMapState(mapOptions));

  if (mapOptions.coordinate) {
    // We need to query for new elevation and time zone here
    yield put(mapActions.queryTimeZoneElevation(mapOptions.coordinate));
  }

  // Check if secondary pin position is included
  if (mapOptions.geodetics?.coordinate) {
    yield put(mapActions.updateGeodeticsCoordinate(mapOptions.geodetics.coordinate));
  } else {
    // ensure geodetics is off
    yield put(mapActions.enableGeodetics(false));
  }
}

/**
 * Saga/generator to obtain the time zone ID and elevation for the given lat/lng without any additional
 * actions dispatched to redux state. Intended for use as drop-in logic for other sagas.
 * @param {Object} latLng object with lat and lng parameters
 * @returns {Object} object with timeZoneId and elevation properties, undefined if no data available
 */
export function* requestTimeZoneElevation(latLng) {

  let result = {
    timeZoneId: undefined,
    elevation: undefined
  }

  try {

    log.debug('mapSaga.getTimeZoneElevation', latLng);

    const elSource = yield elevationService();
    const elCredentials = yield elevationCredentials(elSource);

    // get credentials for the timezone service
    let credentialsMap = yield select(getCredentialsMap);
    let tzCredentials = credentialsMap.get('timezone');

    if (!tzCredentials || !elCredentials || !elSource) {
      log.warn('Cannot query time zone and elevation: credentials and source required');
      return result;
    }

    // data is obtained after axios call is resolved
    const [tzResult, elResult] = yield all([
      call(getTimeZoneRequest, latLng, tzCredentials),
      call(getElevationPromise, latLng, elCredentials, elSource)
    ]);

    const tzId = getTimeZoneId(tzResult.data);
    if (!tzId) {
      log.warn('mapSaga.queryTimeZoneElevation: could not get timeZoneId from response', tzResult.data);
    } else {
      result.timeZoneId = tzId;
    }

    const elObj = getElevationResult(elResult.data, elSource);
    if (!elObj) {
      log.warn('mapSaga.queryTimeZoneElevation: could not get elevation from response', elResult.data);
    } else {
      result.elevation = elObj;
    }

  } catch (e) {
    logCentral.error('Request time zone/elevation failed', e);
  } finally {
    return result;
  }
}

function* centreMapOnPrimaryPinSaga() {

  // current map state
  let currentState = yield select(getMapState);

  const primary = currentState.coordinate;

  // Set map center equal to primary pin position
  yield put(mapActions.updateMapState({ center: primary }));

}

/**
 * Root saga for map related actions
 */
export function* mapSagas() {

  // Top level actions
  // yield takeEvery(mapActionTypes.RESTORE_LAST_STATE, restoreLastState);
  yield takeEvery(mapActionTypes.SYNCH_MAP_TO_QUERY_STRING, synchMapToQueryString);

  yield takeEvery(mapActionTypes.HANDLE_PRIMARY_PIN_DID_MOVE, primaryCoordinateDidChange);
  yield takeEvery(mapActionTypes.HANDLE_MAP_STATE_CHANGE, handleMapStateChange);
  yield takeEvery(mapActionTypes.QUERY_ELEVATION, queryElevationSaga);
  yield takeEvery(mapActionTypes.QUERY_TIME_ZONE_ELEVATION, queryTimeZoneElevationSaga);
  yield takeEvery(mapActionTypes.ENABLE_GEODETICS, enableGeodeticsSaga);
  yield takeEvery(mapActionTypes.QUERY_SECONDARY_ELEVATION, querySecondaryElevationSaga);
  yield takeEvery(mapActionTypes.UPDATE_GEODETICS_COORDINATE, updateGeodeticsCoordinateSaga);
  yield takeEvery(mapActionTypes.SWAP_PIN_POSITIONS, swapPinsSaga);
  yield takeEvery(mapActionTypes.CENTRE_MAP_ON_PRIMARY_PIN, centreMapOnPrimaryPinSaga);
  yield takeEvery(mapActionTypes.VERIFY_ELEVATIONS, verifyElevations);

  yield takeEvery(authActionTypes.UPDATE_AUTH_INFO, getSvcCredentials);

}