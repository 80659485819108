import { createReducer } from '@reduxjs/toolkit';
import RiseTransitSetEvents from '@stephent/meeusjs/lib/risetransitsetevents'

import { actionTypes } from 'actions/settingsActions';

import { MeeusEvents } from 'lib/meeusevents';

function defaultEventColors() {

  var colors = Object.assign({}, ...Object.values(RiseTransitSetEvents.EVENT_KEYS).map(v => (
    {[v]: 'transparent'}
  )));

  const SHADOW_KEY_EXTENSION = '_shadow';

  colors[RiseTransitSetEvents.EVENT_KEYS.SSRise] = '#E4BA2A';
  colors[RiseTransitSetEvents.EVENT_KEYS.SSSet] = '#F77807';
  colors[RiseTransitSetEvents.EVENT_KEYS.MMRise] = '#86A9B9';
  colors[RiseTransitSetEvents.EVENT_KEYS.MMSet] = '#233455';
  colors[RiseTransitSetEvents.EVENT_KEYS.MESunPosition] = '#EE9817';
  colors[RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition] = '#526B84';
  colors[RiseTransitSetEvents.EVENT_KEYS.MESunPosition + SHADOW_KEY_EXTENSION] = '#3F2B19';
  colors[RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition + SHADOW_KEY_EXTENSION] = '#363654';
  colors[RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentreRise] = '#999';
  colors[RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentreSet] = '#777';
  colors[RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition] = '#888';
  
//   $tpe-sun-fill: #F5C980;
// $tpe-sun: #EE9817;
// $tpe-moon-fill: #A6B1BD;
// $tpe-moon: #526B84;
// $tpe-moonshadow: #363654;

  return colors;
}

const highlightedEventKeys = [
  RiseTransitSetEvents.EVENT_KEYS.SSRise,
  RiseTransitSetEvents.EVENT_KEYS.SSSet,
  RiseTransitSetEvents.EVENT_KEYS.MMRise,
  RiseTransitSetEvents.EVENT_KEYS.MMSet,
  RiseTransitSetEvents.EVENT_KEYS.MMFull,
  RiseTransitSetEvents.EVENT_KEYS.MMNew,
  RiseTransitSetEvents.EVENT_KEYS.MMFirstQuarter,
  RiseTransitSetEvents.EVENT_KEYS.MMLastQuarter,
  RiseTransitSetEvents.EVENT_KEYS.MMCrescentMoonBestVisibility,
  RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentreSet,
  RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentreRise,
  RiseTransitSetEvents.EVENT_KEYS.SSSolstice,
  RiseTransitSetEvents.EVENT_KEYS.SSEquinox
];

var initialState = {
  lang: 'en',
  preferredLang: navigator.language,
  availableLanguages: ['en', 'en-US', 'de', 'es-ES', 'fr', 'it', 'ko', 'pl'],
  dateFormat: 'ddd LL',
  datePickerDateFormat: 'PPP', // see https://date-fns.org/v2.0.0-alpha.25/docs/format - did try 'eee PP' but it blows up with this: https://github.com/Hacker0x01/react-datepicker/issues/2195#issuecomment-699569415
  timeFormat: 'LT', // Localized Time format from https://momentjs.com/docs/#/displaying/
  timeZoneFormat: 'ZZ z',
  useMetricUnits: false,
  azimuthDecimalPlaces: 2,
  coordinateDecimalPlaces: 4,
  highPrecisionCoordinateDecimalPlaces: 6,
  bodyKeys: MeeusEvents.DEFAULT_BODY_KEYS,
  eventKeys: MeeusEvents.DEFAULT_TIMELINE_KEYS,
  eventKeyColors: defaultEventColors(),
  highlightedEventKeys: highlightedEventKeys,
  usePremiumMapProvider: false,
  standardMapType: 'map.types.openCycleMap',
  premiumMapType: 'hybrid', // google maps specific default
  fullScreenEnabled: false,
  userPreferencesPending: undefined,
  useMagneticNorth: false,
  elSvc: undefined,
  bodySize: 8,
  showVisualSearch: false,
  sphereMaps: true,
  sphereTerrain: false,
  defaultQs: null,
  useNow: false,
  worklight: false,
  terrainSegments: 256 // low (safe default for older computers or bad WebGL browsers such as Safari) - other possible values are 512 (medium), 1024 (high)
}

/**
 * Note: this uses redux-toolkit createReducer so we can write mutating code for state
 * See https://redux-toolkit.js.org/api/createReducer
 */
export const settings = createReducer(initialState, {
  [actionTypes.RESET_SETTINGS] : (state, action) => {
    return { ...initialState };
  },
  [actionTypes.SET_TIME_FORMAT] : (state, action) => {
    return { ...state, timeFormat: action.payload.format };
  },
  [actionTypes.UPDATE_SETTINGS] : (state, action) => {
    // Merge changes into settings state
    return { ...state, ...action.payload };
  },
  [actionTypes.TOGGLE_FULL_SCREEN_MODE] : (state, action) => {

    // use provided value, if any
    let toggledMode = action.payload.enabled;
    // if none, then toggle state
    if (toggledMode === undefined) {
      toggledMode = state.fullScreenEnabled === false ? true : false;
    }

    return { ...state, fullScreenEnabled: toggledMode };
  },
  [actionTypes.GET_USER_PREFS_PENDING] : (state, action) => {

    return { ...state, userPreferencesPending: action.payload.pending };
  }
});
