/*
 * action types
 */

export const actionTypes = {
    SIGN_IN: 'SIGN_IN',
    SIGN_IN_PENDING: 'SIGN_IN_PENDING',
    SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
    SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
    CLEAR_SIGN_IN_ERROR: 'CLEAR_SIGN_IN_ERROR',
    SIGN_OUT: 'SIGN_OUT',
    GET_PROFILE_PENDING: 'GET_PROFILE_PENDING',
    GET_PROFILE: 'GET_PROFILE',
    UPDATE_WITH_PROFILE: 'UPDATE_WITH_PROFILE',
    GET_PROFILE_FAILURE: 'GET_PROFILE_FAILURE',
    PUT_PROFILE: 'PUT_PROFILE',
    PUT_PROFILE_PENDING: 'PUT_PROFILE_PENDING',
    PUT_PROFILE_SUCCESS: 'PUT_PROFILE_SUCCESS',
    PUT_PROFILE_FAILURE: 'PUT_PROFILE_FAILURE',
    PUT_PROFILE_FINISHED: 'PUT_PROFILE_FINISHED',
    CREATE_ACCOUNT: "CREATE_ACCOUNT",
    CREATE_ACCOUNT_PENDING: "CREATE_ACCOUNT_PENDING",
    CREATE_ACCOUNT_SUCCESS: "CREATE_ACCOUNT_SUCCESS",
    CREATE_ACCOUNT_FAILURE: "CREATE_ACCOUNT_FAILURE",
    RESEND_VERIFICATION_EMAIL: "RESEND_VERIFICATION_EMAIL",
    REQUEST_PASSWORD_RESET: "REQUEST_PASSWORD_RESET",
    COMPLETE_PASSWORD_RESET: "COMPLETE_PASSWORD_RESET",
    DELETE_ACCOUNT: "DELETE_ACCOUNT",
    ZENDESK_LOADED: "ZENDESK_LOADED"
};

/*
 * action creators
 */

export const actions = {

    signIn: (userName, password, staySignedIn = false) => {
        return { type: actionTypes.SIGN_IN, payload: { userName: userName, password: password, staySignedIn: staySignedIn } };
    },
    signInPending: (pending) => {
        return { type: actionTypes.SIGN_IN_PENDING, payload: { pending: pending} };
    },
    signInSuccess: (useruuid) => {
        return { type: actionTypes.SIGN_IN_SUCCESS, payload: { useruuid: useruuid } };
    },
    signInFailure: (error) => {
        return { type: actionTypes.SIGN_IN_FAILURE, payload: error };
    },
    clearSignInError: () => {
        return { type: actionTypes.CLEAR_SIGN_IN_ERROR };
    },
    signOut: (userInitiated) => {
        return { type: actionTypes.SIGN_OUT, payload: { userInitiated: userInitiated } };
    },
    getProfilePending: (source) => {
        return { type: actionTypes.GET_PROFILE_PENDING, payload: { source: source } };
    },
    getProfile: (source) => {
        return { type: actionTypes.GET_PROFILE, payload: { source: source } };
    },
    updateWithProfile: (profileData) => {
        return { type: actionTypes.UPDATE_WITH_PROFILE, payload: profileData };
    },
    getProfileFailure: (error) => {
        return { type: actionTypes.GET_PROFILE_FAILURE, payload: error };
    },
    putProfile: (data) => {
        return { type: actionTypes.PUT_PROFILE, payload: data };
    },
    putProfilePending: (source) => {
        return { type: actionTypes.PUT_PROFILE_PENDING, payload: { source: source } };
    },
    putProfileSuccess: () => {
        return { type: actionTypes.PUT_PROFILE_SUCCESS };
    },
    putProfileFailure: (error) => {
        return { type: actionTypes.PUT_PROFILE_FAILURE, payload: error };
    },
    putProfileFinished: () => {
        return { type: actionTypes.PUT_PROFILE_FINISHED };
    },
    createAccount: (data) => {
        return { type: actionTypes.CREATE_ACCOUNT, payload: data };
    },
    createAccountPending: (data) => {
        return { type: actionTypes.CREATE_ACCOUNT_PENDING, payload: data };
    },
    createAccountSuccess: (details) => {
        return { type: actionTypes.CREATE_ACCOUNT_SUCCESS, payload: details };
    },
    createAccountFailure: (error) => {
        return { type: actionTypes.CREATE_ACCOUNT_FAILURE, payload: error };
    },
    resendVerificationEmail: (email) => {
        return { type: actionTypes.RESEND_VERIFICATION_EMAIL, payload: { email: email } };
    },
    requestPasswordReset: (email) => {
        return { type: actionTypes.REQUEST_PASSWORD_RESET, payload: { email: email } };
    },
    completePasswordReset: (data) => {
        return { type: actionTypes.COMPLETE_PASSWORD_RESET, payload: data };
    },
    deleteAccount: (data) => {
        return { type: actionTypes.DELETE_ACCOUNT, payload: data};
    },
    zendeskLoaded: (loaded) => {
        return { type: actionTypes.ZENDESK_LOADED, payload: { loaded: loaded }};
    }
}
