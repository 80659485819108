import { createStore, compose, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { createReduxHistoryContext } from 'redux-first-history';
import { composeWithDevToolsDevelopmentOnly } from '@redux-devtools/extension';

// Reducers
import { rootReducer } from './reducers';

// Sagas
import rootSaga from './sagas/rootSaga';

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ 
  history: createBrowserHistory(),
  //other options if needed - see https://github.com/salvoravida/redux-first-history#options
  savePreviousLocations: 5
});

/** Saga Middleware */
const sagaMiddleware = createSagaMiddleware();

/** Create middlewares for redux */
let middlewares = applyMiddleware(
  routerMiddleware /* for dispatching history actions */,
  sagaMiddleware
);

// This is an important read: https://medium.com/@zalmoxis/using-redux-devtools-in-production-4c5b56c5600f
const composeEnhancers = composeWithDevToolsDevelopmentOnly({
  // Specify name here, actionsBlacklist, actionsCreators and other options if needed
}) || compose;

/** Create redux store */
export const store = createStore(
  rootReducer(routerReducer),
  composeEnhancers(middlewares) // https://github.com/zalmoxisus/redux-devtools-extension
);

export const history = createReduxHistory(store);

/** run saga watchers */
sagaMiddleware.run(rootSaga);
