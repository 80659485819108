/*
 * action types
 */

export const actionTypes = {
  REFRESH_AUTH_PENDING: 'REFRESH_AUTH_PENDING', 
  UPDATE_AUTH_INFO: 'UPDATE_AUTH_INFO',
  CLEAR_AUTH_INFO: 'CLEAR_AUTH_INFO',
  REFRESH_TOKEN: 'REFRESH_TOKEN'
};

/*
* action creators
*/

export const actions = {
  refreshAuthPending: (pending) => {
    return { type: actionTypes.REFRESH_AUTH_PENDING, payload: { pending: pending } };
  },
  updateAuthInfo: (authInfo) => {
      return { type: actionTypes.UPDATE_AUTH_INFO, payload: authInfo };
  },
  clearAuthInfo: () => {
      return { type: actionTypes.CLEAR_AUTH_INFO };
  },
  refreshToken: () => {
      return { type: actionTypes.REFRESH_TOKEN };
  }
}
