
export const getDate = state => state.dateTime;
export const getDateTime = state => state.dateTime.selectedDateTime;
export const getIsAdjustingTimeOfDay = state => state.dateTime.isAdjustingTimeOfDay;

export const getRouter = state => state.router;

// User profile
export const getUserProfile = state => state.userProfile;
export const getRefreshAuthPending = state => state.userProfile.auth.pending;
export const getDecodedToken = state => state.userProfile.decodedToken;
export const getAuthToken = state => state.userProfile.token;
export const getSignIn = state => state.userProfile.signIn;
export const getAccountCreation = state => state.userProfile.accountCreation;
export const getProfileManagement = state => state.userProfile.profileManagement;

// Map
export const getMapState = state => state.map;
export const getGeodetics = state => state.map.geodetics;
export const getObjectHeights = state => state.map.objectHeights;
export const getObjectFootprint = state => state.map.objectFootprint;
export const getHorizon = state => state.map.horizon;
export const getCoordinate = state => state.map.coordinate;
export const getCenter = state => state.map.center;
export const getSpan = state => state.map.span;
export const getHalfSpan = state => state.map.halfSpan;
export const getZoom = state => state.map.zoom;
export const getTimeZone = state => state.map.timeZone;
export const getElevation = state => state.map.elevation;
export const getServiceCredentials = state => state.map.serviceCredentials;
export const getMapServiceNames = state => state.map.serviceNames;
export const getWillLoadPremiumMaps = state => state.map.willLoadPremiumMaps;
export const getDidLoadPremiumMaps = state => state.map.didLoadPremiumMaps;

// Settings
export const getSettings = state => state.settings;

// Search
export const getSearch = state => state.search;
export const getGeocodingApiKey = state => state.search?.serviceCredentials?.key;
export const getAutocompleteDebounce = state => {
  if (state.search.serviceCredentials) {
    return state.search.serviceCredentials.autocompleteDebounce || 1001;
  }
  return 1002;
};
export const getSearchResults = state => state.search.results;
export const getSearchResultsPending = state => state.search.resultsPending;

// Subscriptions
export const getSubscriptions = state => state.subscriptions;
export const getCustomer = state => state.subscriptions.customer;
export const getOrder = state => state.subscriptions.order;
export const getCustomerPending = state => state.subscriptions.requestCustomerPending;
export const getCustomerError = state => state.subscriptions.requestCustomerError;
export const getProductPlans = state => state.subscriptions.plans;
export const getProductPlansError = state => state.subscriptions.requestPlansError;
export const getSelectedProductPlanId = state => state.subscriptions.selectedPlanId;
export const getPaymentMethodError = state => state.subscriptions.paymentMethodError;
export const getPurchasedSubscription = state => state.subscriptions.purchasedSubcription;
export const getPaymentMethodDetails = state => state.subscriptions.paymentMethodDetails;
export const getRequestPaymentMethodDetailsError = state => state.subscriptions.requestPaymentMethodDetailsError;
export const getEntitlementFeatures = state => state.subscriptions.entitlementFeatures;
export const getEntitlementLinks = state => state.subscriptions.entitlementLinks;
export const getCoupon = state => state.subscriptions.coupon;
export const getLeadSource = state => state.subscriptions.leadSource;
export const getPlanChanges = state => state.subscriptions.planChanges;

// Locations
export const getLocations = state => state.locations
export const getLocationsError = state => state.locations.error;
export const getSynchToken = state => state.locations.synchToken;
export const getSynchPending = state => state.locations.synchPending;
export const getSynchRequired = state => state.locations.synchRequired;
export const getSavedLocations = state => state.locations.saved;
export const getStoredCount = state => state.locations.storedCount;
export const getLoadPending = state => state.locations.loadPending;
export const getWritePending = state => state.locations.writePending;
export const getSelectedLocationUUID = state => state.locations.selectedUUID;
export const getEditingLocationUUID = state => state.locations.editingUUID;
export const getFilter = state => state.locations.filter;
export const getDeletionLocationUUID = state => state.locations.deletionUUID;
export const getResumeEditingIfDeletionCancelled = state => state.locations.resumeEditingIfDeletionCancelled;

// Visual Search
export const getVisualSearch = state => state.visualSearch

// Sphere
export const getSphere = state => state.sphere