import { createReducer } from '@reduxjs/toolkit';

import { actionTypes } from '../actions/mapActions';

var initialState = {
  serviceCredentials: undefined,
  serviceNames: {
    map: 'thunderforest',
    elevation: 'geonames',
    timezone: 'geonames',
    geocoding: 'crookneck-geo',
    premiumMap: 'google-maps',
    mapbox: 'mapbox'
  },
  coordinate: {
    lat: 16.7688,
    lng: -3.0073
  },
  center: {
    lat: 16.7688,
    lng: -3.0073
  },
  span: undefined,
  zoom: 13,
  timeZone: {
    timeZoneId: 'Africa/Bamako',
    verified: true
  },
  elevation: {
    source: 'srtm3',
    value: 274,
    verified: true
  },
  objectHeights: {
    primary: 1.524, // 5 feet by default
    secondary: 0
  },
  objectFootprint: {
    primary: 100, // 100m by default
    secondary: 100
  },
  willLoadPremiumMaps: false,
  didLoadPremiumMaps: false,
  requestingCurrentLocation: false,
  currentLocationRequestComplete: undefined,
  geodetics: {
    enabled: false,
    coordinate: undefined,
    elevation: undefined,
    pending: false,
    error: undefined
  },
  horizon: {
    enabled: false,
    heightAboveHorizon: undefined,
    elevationAtHorizon: undefined,
    useGeodetics: false
  }
};

/**
 * Note: this uses redux-toolkit createReducer so we can write mutating code for state
 * See https://redux-toolkit.js.org/api/createReducer
 */
export const map = createReducer(initialState, {
  [actionTypes.REQUEST_SVC_CREDENTIALS_SUCCESS] : (state, action) => {
    
    // action.payload is an array of service credential objects, e.g.
    // [
    //   {
    //     "name": "thunderforest",
    //     "available": true,
    //     "token": "[TOKEN]"
    //   },
    //   {
    //     "name": "geonames",
    //     "available": true,
    //     "user": "[USERNAME]",
    //     "token": "[TOKEN]"
    //   }
    // ]
    
    // Mutate state to set the credentials
    state.serviceCredentials = action.payload;
  },
  [actionTypes.REQUEST_SVC_CREDENTIALS_FAILURE] : (state, action) => {
    // Mutate state to reset the credentials to undefined
    state.serviceCredentials = undefined;
  },
  [actionTypes.RESET_SVC_CREDENTIALS] : (state, action) => {
    // Mutate state to reset credentials to undefined - we generally want to do this when we need to force a refresh 
    // after obtaining new auth token, e.g. when a user has subscribed to premium service level and we need to refresh
    // the credentials to include new premium services (e.g. Google Maps)
    state.serviceCredentials = undefined;
  },
  [actionTypes.UPDATE_MAP_STATE] : (state, action) => {
    // Merge the payload into the state - careful, this is powerful

    let updatedState = { ...state, ...action.payload };
    if (updatedState.zoom) {
      updatedState.span = undefined;
    }

    return updatedState;
  },
  [actionTypes.QUERY_TIME_ZONE_ELEVATION_FAILURE] : (state, action) => {
    // Mutate state
    state.timeZone.verified = false;
    state.elevation.verified = false;
  },
  [actionTypes.UPDATE_ELEVATION] : (state, action) => {
    
    // Mutate state to merge in new elevation data
    // If action.payload is only { verified: false }, then we simply set verified to false, but keep existing values
    state.elevation = { ...state.elevation, ...action.payload };
  },
  [actionTypes.UPDATE_TIMEZONE] : (state, action) => {

    // Mutate state to merge in new time zone data
    // If action.payload is only { verified: false }, then we simply set verified to false, but keep existing values
    state.timeZone = { ...state.timeZone, ...action.payload };
  },
  [actionTypes.WILL_LOAD_PREMIUM_MAPS] : (state, action) => {

    state.willLoadPremiumMaps = true;
  },
  [actionTypes.DID_LOAD_PREMIUM_MAPS] : (state, action) => {

    state.didLoadPremiumMaps = true;
  },
  [actionTypes.REQUEST_CURRENT_LOCATION] : (state, action) => {

    const requesting = action.payload.requesting ? action.payload.requesting : (state.requestingCurrentLocation !== true);

    const changes = {
      requestingCurrentLocation: requesting,
      currentLocationRequestComplete: requesting === true ? false : undefined
    }

    return { ...state, ...changes };
  },
  [actionTypes.CURRENT_LOCATION_REQUEST_COMPLETE] : (state, action) => {

    const changes = {
      currentLocationRequestComplete: true
    }

    return { ...state, ...changes };
  },
  [actionTypes.UPDATE_GEODETICS] : (state, action) => {

    const geodetics = { ...state.geodetics, ...action.payload.geodetics };
    return { ...state, geodetics: geodetics };
  },
  [actionTypes.GEODETICS_ELEVATION_PENDING] : (state, action) => {

    const geodetics = { ...state.geodetics, pending: action.payload.pending };
    return { ...state, geodetics: geodetics };
  },
  [actionTypes.UPDATE_HORIZON] : (state, action) => {
    
    const horizon = { ...state.horizon, ...action.payload };
    return { ...state, horizon: horizon };
  }
});