
export default class PELatLng {

  constructor(lat, lng) {
    this.lat = lat;
    this.lng = lng;
  }

  toObject() {
    return {
      lat: this.lat,
      lng: this.lng
    }
  }
}