/*
 * action types
 */

export const actionTypes = {
  UPDATE_SEARCH_PARAMETERS: 'UPDATE_SEARCH_PARAMETERS',
  EXECUTE_SEARCH: 'EXECUTE_SEARCH',
  CLEAR_RESULTS: 'CLEAR_RESULTS',
  ADD_RESULTS: 'ADD_RESULTS',
  ADD_RANGE_RESULTS: 'ADD_RANGE_RESULTS',
  SET_FILTERS: 'SET_FILTERS'
}

/*
* action creators
*/

export const actions = {
  updateSearchParameters: (params) => {
    return { type: actionTypes.UPDATE_SEARCH_PARAMETERS, payload: params }
  },
  executeSearch: (durationMS, fromToday = false) => {
    return { type: actionTypes.EXECUTE_SEARCH, payload: { duration: durationMS, fromToday: fromToday } }
  },
  clearResults: () => {
    return { type: actionTypes.CLEAR_RESULTS }
  },
  addResults: (results) => {
    return { type: actionTypes.ADD_RESULTS, payload: results }
  },
  addRangeResults: (rangeResults) => {
    return { type: actionTypes.ADD_RANGE_RESULTS, payload: rangeResults }
  },
  setFilters: (filters) => {
    return { type: actionTypes.SET_FILTERS, payload: filters }
  }
}