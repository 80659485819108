import React from 'react';
import * as Sentry from '@sentry/react';

import ChunkLoadError from './ChunkLoadError';
import SecurityError from './SecurityError';
import DefaultError from './DefaultError';

function PageErrorBoundary({ children }) {

	return (
		<Sentry.ErrorBoundary fallback={PageErrorFallback}>
			{children}
		</Sentry.ErrorBoundary>
	);
}

export default PageErrorBoundary;

function PageErrorFallback({ error, /*componentStack, resetError,*/ eventId }) {

	if (error && error.name === 'ChunkLoadError') {
		return (
			<div className='max-w-3xl mx-auto'>
				<ChunkLoadError eventId={eventId} />
			</div>
		)
	}

	if (error && error.name === 'SecurityError' && error.message.includes('history.replaceState()')) {
		return (
			<div className='max-w-3xl mx-auto'>
				<SecurityError eventId={eventId} />
			</div>
		)
	}

	return (
		<div className='max-w-3xl mx-auto'>
			<DefaultError eventId={eventId} />
		</div>
	)
}
