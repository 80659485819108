import i18n from '../i18n';
import numeral from 'numeral';

export const decimalDegreesRegExes = {
  en: /^(([NnSs+-])?([0-8]?\d(\.\d*)?|90) *([°˚º^~*]*) *([NnSs+-])*)([,:;\s|/\\-]+)(([EeWw+-]*)([0]?\d?\d(\.\d*)?|1[0-7]\d(\.\d*)?|180) *([°˚º^~*]*) *([EeWw+-]*))$/,
  de: /^(([NnSs+-])?([0-8]?\d([,.]\d*)?|90) *([°˚º^~*]*) *([NnSs+-])*)([,:;\s|/\\-]+)(([OoWw+-]*)([0]?\d?\d([,.]\d*)?|1[0-7]\d([,.]\d*)?|180) *([°˚º^~*]*) *([OoWw+-]*))$/,
  fr: /^(([NnSs+-])?([0-8]?\d([,.]\d*)?|90) *([°˚º^~*]*) *([NnSs+-])*)([,:;\s|/\\-]+)(([EeOo+-]*)([0]?\d?\d([,.]\d*)?|1[0-7]\d([,.]\d*)?|180) *([°˚º^~*]*) *([EeOo+-]*))$/,
  "es-ES": /^(([NnSs+-])?([0-8]?\d([,.]\d*)?|90) *([°˚º^~*]*) *([NnSs+-])*)([,:;\s|/\\-]+)(([EeOo+-]*)([0]?\d?\d([,.]\d*)?|1[0-7]\d([,.]\d*)?|180) *([°˚º^~*]*) *([EeOo+-]*))$/,
  it: /^(([NnSs+-])?([0-8]?\d([,.]\d*)?|90) *([°˚º^~*]*) *([NnSs+-])*)([,:;\s|/\\-]+)(([EeOo+-]*)([0]?\d?\d([,.]\d*)?|1[0-7]\d([,.]\d*)?|180) *([°˚º^~*]*) *([EeOo+-]*))$/,
  ko: /^(([북Nn동Ss+-])?([0-8]?\d(\.\d*)?|90) *([°˚º^~*]*) *([북Nn동Ss+-])*)([,:;\s|/\\-]+)(([동Ee서Ww+-]*)([0]?\d?\d(\.\d*)?|1[0-7]\d(\.\d*)?|180) *([°˚º^~*]*) *([동Ee서Ww+-]*))$/,
}

export const hemispheres = {
  en: {
    n: "n",
    s: "s",
    e: "e",
    w: "w",
  },
  de: {
    n: "n",
    s: "s",
    e: "o",
    w: "w"
  },
  fr: {
    n: "n",
    s: "s",
    e: "e",
    w: "o"
  },
  "es-ES": {
    n: "n",
    s: "s",
    e: "e",
    w: "o"
  },
  it: {
    n: "n",
    s: "s",
    e: "e",
    w: "o"
  },
  ko: {
    n: "북",
    s: "남",
    e: "동",
    w: "서"
  }
}

export function decimalDegreesRegEx() {

  let regex = decimalDegreesRegExes[i18n.language] || decimalDegreesRegExes['en'];
  return regex;

}

/**
 * Javascript function to parse a coordinate string and return an object with .lat and .lng properties, or undefined
 * if coordinate string could not be parsed.
 * @param {String} coordString string containing latitude and longitude to be parsed
 */
export function parseCoordinate(coordString) {

  let regex = decimalDegreesRegExes[i18n.language] || decimalDegreesRegExes.en;

  let matches = regex.exec(coordString.trim());
  if (!matches || matches.length !== 15) {
    return;
  }

  let hemisphere = hemispheres[i18n.language] || hemispheres.en;

  // Rather than relying on numeral.js to parse the value, we replace any localised decimal delimiter with '.'
  // That way, we can accept either localised (e.g. '57,456') or default ('57.456')
  const decimalDelimiter = numeral.localeData().delimiters.decimal;

  const absoluteLatitude = parseFloat(('' + matches[3]).replace(decimalDelimiter, '.'));
  const absoluteLongitude = parseFloat(('' + matches[10]).replace(decimalDelimiter, '.'));

  if (absoluteLatitude === 0 && absoluteLongitude === 0) {
    return;
  }

  if (absoluteLatitude < 0 || absoluteLatitude > 90) {
    return;
  }

  if (absoluteLongitude < 0 || absoluteLongitude > 180) {
    return;
  }
  
  // Find north/south hemisphere, if any
  var latitudeMultiplier = 1
  if ([hemisphere.s, hemisphere.s.toUpperCase()].includes(matches[6]) || matches[2] === '-') {
    latitudeMultiplier = -1
  }

  // Find east/west hemisphere, if any
  var longitudeMultiplier = 1
  if ([hemisphere.w, hemisphere.w.toUpperCase()].includes(matches[14]) || matches[7].includes('-')) {
    longitudeMultiplier = -1
  }
  
  let result = {
    lat: absoluteLatitude * latitudeMultiplier,
    lng: absoluteLongitude * longitudeMultiplier
  }

  return result;
}

/**
 * Hook to parse a coordinate string and return an object with .lat and .lng properties, or undefined
 * if coordinate string could not be parsed.
 * @param {String} coordString string containing latitude and longitude to be parsed
 */
export function useCoordinateParser(coordString) {

  return parseCoordinate(coordString);
}