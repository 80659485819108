import axios from 'axios';
import { call, put, takeLatest, delay, select } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import { osName } from 'react-device-detect'; // https://github.com/duskload/react-device-detect#readme
import { ZendeskAPI } from "react-zendesk";
import log from 'loglevel';
import { logCentral } from '../services/log-central';
import queryString from 'query-string';

import i18n from 'i18n'

import { getRouter, getUserProfile } from '../selectors';

// Inspired by https://auth0.com/blog/beyond-create-react-app-react-router-redux-saga-and-more/
import { makeURL } from 'config';
import { getHasVisitedPreviously } from 'reselectors/userProfile';
import { actions as userProfileActions, actionTypes } from 'actions/userProfileActions';
import { actions as settingsActions } from 'actions/settingsActions';
import { actions as authActions } from 'actions/authActions';
import { actions as subscribeActions } from 'actions/subscribeActions';
import { actions as mapActions } from 'actions/mapActions';
import { actions as searchActions } from 'actions/searchActions';
import { actions as locationActions } from 'actions/locationActions';
import { authorizedRequest, AuthorizedRequestError } from './authSaga';

/** 
 * Sign in function that returns an axios call to /user/login. We set the refresh=1 query parameter
 * to have the server set an httponly secure third party refresh token cookie in its response. This cookie
 * is passed back to the server in requestFreshJWT(), below, when a new bearer JWT is needed.
 * See https://blog.hasura.io/best-practices-of-using-jwt-with-graphql/#refresh_token_persistance
 * 
 * @param {Object} authParams object with userName and password properties to submit to server
 */
function signInApi(authParams) {

  // DON"T LOG THIS, REALLY... log.debug('signInApi:', authParams);

  const acceptLanguageValue = i18n.acceptLanguageHeaderValue();

  return axios.request({
    method: 'post',
    url: makeURL('/user/login?refresh=1'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': acceptLanguageValue
    },
    withCredentials: true,
    data: {
      email: authParams.userName,
      password: authParams.password,
      staySignedIn: authParams.staySignedIn
    }
  });
}

function signUpApi(data) {

  // DON"T LOG THIS, REALLY... log.debug('signInApi:', authParams);

  const acceptLanguageValue = i18n.acceptLanguageHeaderValue();

  return axios.request({
    method: 'post',
    url: makeURL('/user/register'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': acceptLanguageValue
    },
    data: data
  });
}

function putProfileApiOptions(data) {

  // DON"T LOG THIS, REALLY... log.debug('putProfileApi:', data);

  let sendCookies = false;
  if (data.email) {
    // include cookies so we can invalidate the refresh token when account is signed out
    sendCookies = true;
  }

  return {
    method: 'put',
    url: makeURL('/user/profile'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: data,
    withCredentials: sendCookies
  };
}

function deleteAccountApiOptions(password) {

  // DON"T LOG THIS, REALLY... log.debug('putProfileApi:', data);

  return {
    method: 'delete',
    url: makeURL('/user/account'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'X-LSS-Password': password
    },
    withCredentials: true // include cookies so we can invalidate the refresh token when account is deleted
  };
}

function requestVerificationEmailApi(data) {

  const acceptLanguageValue = i18n.acceptLanguageHeaderValue();

  return axios.request({
    method: 'post',
    url: makeURL('/user/verificationemailrequest'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': acceptLanguageValue
    },
    data: data
  });
}

function requestPasswordResetApi(data) {

  const acceptLanguageValue = i18n.acceptLanguageHeaderValue();

  return axios.request({
    method: 'post',
    url: makeURL('/user/resetrequest'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Accept-Language': acceptLanguageValue
    },
    data: data
  });
}

function completePasswordResetApi(data, token) {

  return axios.request({
    method: 'post',
    url: makeURL('/user/passwordreset/' + token),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: data
  });
}


/** Sign out function that returns an axios call. This calls the /user/logout end point.
 * Important: this call also passes cookies so that the server can revoke both the bearer token
 * AND the refresh token set in the cookie.
 */
function signOutApiOptions() {

  return {
    method: 'get',
    url: makeURL('/user/logout/'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    withCredentials: true
  };
}

/** Get profile function that returns an axios call */
function getProfileApiOptions() {

  return {
    method: 'get',
    url: makeURL('/user/profile/'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
    }
  };
}

/** Handles user sign in, and subsequent actions */
function* signInSaga(action) {

  try {

    yield put(userProfileActions.signInPending(true));

    // data is obtained after axios call is resolved
    let { data } = yield call(signInApi, action.payload);

    // Update the profile with the user's unique ID - that's proof we signed in successfully
    yield put(userProfileActions.signInSuccess(data.useruuid));

    logCentral.setUser({
      id: data.useruuid,
      email: action.payload.userName
    });

    // Get the user's preferences from our server - this will automatically obtain a refresh token
    yield put(settingsActions.getUserPrefs());

    // Small delay to reduce chance of duplicate refresh token requests caused by asking for user prefs and user profile before the
    // first refresh token is received.
    yield delay(500);

    // dispatch action to update the user profile info (separate API call) - we don't render the map until the profile is received
    yield put(userProfileActions.getProfile('signIn'));

    // Record that user was signed in - see comments on 'userWasSignedIn' below
    localStorage.setItem('userWasSignedIn', 'true');

    logCentral.event({
      category: "Account",
      action: "user_signed_in"
    }, {
      uuid: data.useruuid
    });

    logCentral.eventGA4('login', { method: 'web_app' });

    // Set a flag that "this specific user has previously signed in on this browser" (use opaque uuid)
    localStorage.setItem(data.useruuid + '-hasPreviouslySignedIn', true);

    // Set a generic "a user has previously signed in on this browser"
    localStorage.setItem('userHasPreviouslySignedIn', 'true');

    // redirect to home route after successful login
    //   browserHistory.push('/home');
  } catch (e) {
    // log.error(e.message, e.response || ''); <- could contain user password, so don't log it
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Sign in failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "user_sign_in_failed",
      label: errObj.message
    });

    yield put(userProfileActions.signInFailure(errObj));
  } finally {
    yield put(userProfileActions.signInPending(false));
  }
}

function* signOutSaga(action) {

  try {

    let options = signOutApiOptions();
    // don't try to refresh the token for this call - if refresh fails, an sign out action
    // is dispatched, which would put us into an endless loop
    let shouldRefreshToken = false;
    let request = yield call(authorizedRequest, options, shouldRefreshToken);
    yield call(request);

    const userInitiated = action.payload && action.payload.userInitiated;
    if (userInitiated === true) {
      logCentral.event({
        category: "Account",
        action: "user_signed_out"
      });

      logCentral.eventGA4('logout', { method: 'web_app' });
    } else {
      logCentral.event({
        category: "Account",
        action: "user_was_signed_out"
      });
    }

  } catch (e) {
    if ((e instanceof AuthorizedRequestError) === false) {
      log.error(e, e.response || '');
    }

    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';

    } else if (e.message !== 'Network Error') {
      logCentral.error('Sign out failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "sign_out_failed",
      label: errObj.message
    });
  } finally {
    // Set a flag in browser local storage: we use this on restoring auth and profile state
    // at app startup. If, for some reason, the last call to signOut failed (e.g. no network), then
    // the user's refresh cookie may well still be set locally. If that is the case, without this
    // flag, we would otherwise try to refresh tokens at startup, succeed and the user would be 
    // 'magically' signed back in, even though they signed out.
    localStorage.setItem('userWasSignedIn', 'false');

    // Clear our local auth info irrespective of whether API call succeeds or fails
    yield put(authActions.clearAuthInfo());

    // Clear the subscription information - we don't want the next user seeing the previous user's plans
    yield put(subscribeActions.clearSubscriptionData());

    // Clear any service credentials - we need the next user to fetch them afresh using their own entitlements
    yield put(mapActions.resetSvcCredentials());

    // Reset settings to defaults - next user will get defaults or their own stored preferences
    yield put(settingsActions.resetSettings());

    // Clear any existing search results
    yield put(searchActions.clearSearchResults());

    // Clear the user's stored locations and any applied filters - don't want the next user to sign in to see any of that
    yield put(locationActions.resetLocations());

    // Clear any zendesk web widget data
    const { help } = yield select(getUserProfile);
    if (help.zendeskLoaded === true) {
      ZendeskAPI('webWidget', 'logout');
    }

    // Finally, navigate to the sign in page, clearing any query string
    const userHasSignedInBefore = yield select(getHasVisitedPreviously);
    if (userHasSignedInBefore) {
      yield put(push("/signin"));
    } /* else {
      yield put(push("/join"));
    } */
  }

}

/**
 * Saga to refresh the user's profile over the network. Calls GET /user/profile and updates
 * state with the result, if any. In case of error, dispatches GET_PROFILE_FAILURE action
 * This saga is exported so that we can call it directly - see authSaga.js. We want to be able to wait
 * for it to complete, rather than just fire-and-forget dispatch
 */
export function* getProfileSaga(action) {

  try {

    const { source } = action.payload;
    yield put(userProfileActions.getProfilePending(source));

    let options = getProfileApiOptions();
    let request = yield call(authorizedRequest, options);
    let { data } = yield call(request);

    // log.debug('getProfileSaga:', data);

    // dispatch action to change redux state
    yield put(userProfileActions.updateWithProfile(data));

    // Update the user id here also - with refresh tokens, users don't always sign in
    logCentral.setUser({
      id: data.uuid,
      email: data.email
    });

    // Set user profile info on the data layer for use by GTM
    try {
      // We want these data layer variable values to persist between events, so we don't set them as event parameters:
      window?.dataLayer.push({
        "event": "user_profile_refreshed",
        user_id: data.uuid,
        email: data.email,
        first_name: data.firstname,
        last_name: data.lastname
      });
    } catch (error) {
      logCentral.error('GTM user_profile_refreshed', error);
    }
    
    if (source === 'restoreAuthAndProfile') {
      logCentral.event({
        category: "Account",
        action: "user_session_resumed"
      }, {
        uuid: data.uuid
      });
    }

    const { help } = yield select(getUserProfile);
    if (help.zendeskLoaded === true) {
      ZendeskAPI('webWidget', 'identify', {
        name: [data.firstname, data.lastname].join(' '),
        email: data.email,
      });
    }

  } catch (e) {
    if ((e instanceof AuthorizedRequestError) === false) {
      log.error(e, e.response || '');
    }

    // log.error(e.message, e.response || ''); <- Don't log this - likely contains the user's password
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Get profile failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "user_get_profile_failed",
      label: errObj.message
    });

    // TODO: don't inject UI strings into state here - use error codes instead so we can localize properly
    yield put(userProfileActions.getProfileFailure(errObj));
  }
}

function* putProfileSaga(action) {

  try {

    let { source } = action.payload;

    let changes;
    switch (source) {
      case "profile":
        changes = {
          firstname: action.payload.firstName,
          lastname: action.payload.lastName
        }
        break;
      case "email":
        changes = {
          email: action.payload.email,
          currentPassword: action.payload.currentPassword
        }
        break;
      case "password":
        changes = {
          currentPassword: action.payload.currentPassword,
          password: action.payload.newPassword
        }
        break;
      default:
        throw new Error("Payload must include valid source");
    }

    yield put(userProfileActions.putProfilePending(source));

    // putProfileApiOptions
    // data is obtained after axios call is resolved
    let options = putProfileApiOptions(changes);
    let request = yield call(authorizedRequest, options);
    let { data } = yield call(request);

    logCentral.event({
      category: "Account",
      action: "user_profile_updated",
      label: source
    });

    // dispatch action to change redux state
    yield put(userProfileActions.updateWithProfile(data));

    // Success
    yield put(userProfileActions.putProfileSuccess());

  } catch (e) {
    // log.error(e.message, e.response || ''); <- Don't log this - likely contains the user's password
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Update profile failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "user_update_profile_failed",
      label: errObj.message
    });

    yield put(userProfileActions.putProfileFailure(errObj));
  }
}

function* createAccount(action) {

  try {

    /*
      {
        "email": "stephen.trainor@gmail.com",
        "password": "password",
        "firstname": "Stephen",
        "lastname": "Trainor",
        "sourceAppName": "Paw",
        "osName": "macOS",
        "signup": "true"
      }
    */

    const accountDetails = {
      email: action.payload.email,
      password: action.payload.password,
      firstname: action.payload.firstName,
      lastname: action.payload.lastName,
      signup: action.payload.mailingListConsent,
      agreeTermsOfUse: action.payload.agreeTermsOfUse,
      mailingListConsent: action.payload.mailingListConsent,
      osName: osName,
      sourceAppName: process.env.REACT_APP_NAME + '-' + process.env.REACT_APP_VERSION
    }

    const newAccountDetails = { ...action.payload };
    delete newAccountDetails.password;

    yield put(userProfileActions.createAccountPending(newAccountDetails));

    // data is obtained after axios call is resolved
    yield call(signUpApi, accountDetails);

    logCentral.event({
      category: "Account",
      action: "user_signed_up"
    });

    logCentral.eventGA4('sign_up', { method: 'cpa' });

    if (accountDetails.mailingListConsent === true) {
      logCentral.event({
        category: "Account",
        action: "user_consented_to_marketing_emails"
      });

      logCentral.eventGA4('sign_up', { method: 'marketing_list' });
    }

    yield put(userProfileActions.createAccountSuccess(newAccountDetails));

  } catch (e) {
    // log.error(e.message, e.response || ''); <- Don't log this - likely contains the user's password
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Sign up failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "user_sign_up_failed",
      label: errObj.message
    });

    yield put(userProfileActions.createAccountFailure(errObj));
  }
}

function* requestVerificationEmail(action) {

  try {
    // data is obtained after axios call is resolved
    yield call(requestVerificationEmailApi, action.payload);

    logCentral.event({
      category: "Account",
      action: "user_requested_verification_email"
    });

  } catch (e) {
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Request verification email failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "verification_email_request_failed",
      label: errObj.message
    });

    // We'll hijack the sign in failure action to expose any error encountered here
    yield put(userProfileActions.signInFailure(errObj));
  }
}

function* requestPasswordReset(action) {

  try {
    yield call(requestPasswordResetApi, action.payload);

    logCentral.event({
      category: "Account",
      action: "user_requested_password_reset"
    });

  } catch (e) {
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Request password reset email failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "password_reset_request_failed",
      label: errObj.message
    });

    // We'll hijack the sign in failure action to expose any error encountered here
    yield put(userProfileActions.signInFailure(errObj));
  }
}

function* completePasswordReset(action) {

  try {

    let { email, newPassword, source } = action.payload;

    const router = yield select(getRouter);
    const parsed = queryString.parse(router.location.search);

    var changes;
    switch (source) {
      case "password":
        changes = {
          email: email,
          password: newPassword,
        }
        break;
      default:
        throw new Error("Payload must include valid source");
    }

    yield put(userProfileActions.putProfilePending(source));

    yield call(completePasswordResetApi, changes, parsed.token);

    logCentral.event({
      category: "Account",
      action: "user_password_was_reset"
    });

    // Success
    yield put(userProfileActions.putProfileSuccess());

  } catch (e) {
    // log.error(e.message, e.response || ''); <- Don't log this - likely contains the user's password
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Complete password reset failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "user_password_reset_failed",
      label: errObj.message
    });

    yield put(userProfileActions.putProfileFailure(errObj));
  }
}

function* deleteAccount(action) {

  try {

    let { password, source } = action.payload;

    if (!password) {
      throw new Error('Must include password with this request');
    }

    yield put(userProfileActions.putProfilePending(source));

    let options = deleteAccountApiOptions(password);
    let request = yield call(authorizedRequest, options);
    yield call(request);

    logCentral.event({
      category: "Account",
      action: "user_account_deleted",
      label: source
    });

    yield put(userProfileActions.signOut());

    yield put(userProfileActions.putProfileFinished());

  } catch (e) {
    // log.error(e.message, e.response || ''); <- Don't log this - likely contains the user's password
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof (e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Delete account failed', e);
    }

    logCentral.event({
      category: "Account",
      action: "user_delete_account_failed",
      label: errObj.message
    });

    yield put(userProfileActions.putProfileFailure(errObj));
  }
}

/**
 saga watcher that is triggered when dispatching action of type 'SIGN_IN'
  */
export function* userProfileSagas() {

  yield takeLatest(actionTypes.SIGN_IN, signInSaga);
  yield takeLatest(actionTypes.SIGN_OUT, signOutSaga);
  yield takeLatest(actionTypes.GET_PROFILE, getProfileSaga);
  yield takeLatest(actionTypes.PUT_PROFILE, putProfileSaga);
  yield takeLatest(actionTypes.CREATE_ACCOUNT, createAccount);
  yield takeLatest(actionTypes.RESEND_VERIFICATION_EMAIL, requestVerificationEmail);
  yield takeLatest(actionTypes.REQUEST_PASSWORD_RESET, requestPasswordReset);
  yield takeLatest(actionTypes.COMPLETE_PASSWORD_RESET, completePasswordReset);
  yield takeLatest(actionTypes.DELETE_ACCOUNT, deleteAccount);
}

