/**
 * Basic flow for a new customer would be:
 * 1) Does customer have payment platform Id? Yes: update with new payment method No: create customer and set default payment method
 * 2) Is customer already subscribed to a plan? Yes: disallow add, allow update, cancel; No: allow add, disallow update, cancel
 * 3) 
 */


/** 
 * Search action types
 */
export const actionTypes = {
  SHOW_SUBSCRIPTION_PAGE: 'SHOW_SUBSCRIPTION_PAGE',
  REQUEST_PRODUCT_PLANS: 'REQUEST_PRODUCT_PLANS',
  REQUEST_PRODUCT_PLANS_SUCCESS: 'REQUEST_PRODUCT_PLANS_SUCCESS', 
  REQUEST_PRODUCT_PLANS_FAILURE: 'REQUEST_PRODUCT_PLANS_FAILURE',
  REQUEST_PAYMENT_PLATFORM_CUSTOMER: 'REQUEST_PAYMENT_PLATFORM_CUSTOMER',
  REQUEST_PAYMENT_PLATFORM_CUSTOMER_FAILED: 'REQUEST_PAYMENT_PLATFORM_CUSTOMER_FAILED',
  PAYMENT_PLATFORM_CUSTOMER_RECEIVED: 'PAYMENT_PLATFORM_CUSTOMER_RECEIVED',
  SELECT_PRODUCT_PLAN: 'SELECT_PRODUCT_PLAN',
  SUBMIT_CUSTOMER_PAYMENT_METHOD: 'SUBMIT_CUSTOMER_PAYMENT_METHOD',
  PAYMENT_METHOD_FAILED: 'PAYMENT_METHOD_FAILED',
  SUBSCRIBE_TO_PLAN: 'SUBSCRIBE_TO_PLAN',
  SUBSCRIPTION_RECEIVED: 'SUBSCRIPTION_RECEIVED',
  SUBSCRIBE_CUSTOMER_TO_STRIPE_PLAN: 'SUBSCRIBE_CUSTOMER_TO_STRIPE_PLAN',
  REQUEST_PAYMENT_METHOD_DETAILS: 'REQUEST_PAYMENT_METHOD_DETAILS',
  PAYMENT_METHOD_DETAILS_RECEIVED: 'PAYMENT_METHOD_DETAILS_RECEIVED',
  REQUEST_PAYMENT_METHOD_DETAILS_FAILED: 'REQUEST_PAYMENT_METHOD_DETAILS_FAILED',
  UPDATE_WITH_ORDER_DETAILS: 'UPDATE_WITH_ORDER_DETAILS',
  CLEAR_SUBSCRIPTION_DATA: 'CLEAR_SUBSCRIPTION_DATA',
  SYNCH_TO_QUERY_STRING: 'SYNCH_TO_QUERY_STRING',
  SET_COUPON: 'SET_COUPON',
  SET_LEAD_SOURCE: 'SET_LEAD_SOURCE',
  PAUSE_SUBSCRIPTION: 'PAUSE_SUBSCRIPTION',
  RESUME_SUBSCRIPTION: 'RESUME_SUBSCRIPTION',
  SHOW_AVAILABLE_PLANS: 'SHOW_AVAILABLE_PLANS',
  CHANGE_PLAN: 'CHANGE_PLAN',
  HANDLE_PLAN_CHANGES: 'HANDLE_PLAN_CHANGES'
}

/** 
 * Search action creators
 */
export const actions = {
  showSubscriptionPage: (source) => {
    return { type: actionTypes.SHOW_SUBSCRIPTION_PAGE, payload: { source: source } };
  },
  requestProductPlans: (entitlement, locale) => {
    return { type: actionTypes.REQUEST_PRODUCT_PLANS, payload: { entitlement: entitlement, locale: locale } };
  },
  requestProductPlansSuccess: (plans) => {
    return { type: actionTypes.REQUEST_PRODUCT_PLANS_SUCCESS, payload: plans };
  },
  requestProductPlansFailure: (error) => {
    return { type: actionTypes.REQUEST_PRODUCT_PLANS_FAILURE, payload: error };
  },
  requestPaymentPlatformCustomer: (refresh = false) => {
    return { type: actionTypes.REQUEST_PAYMENT_PLATFORM_CUSTOMER, payload: { refresh: refresh } };
  },
  requestPaymentPlatformCustomerFailed: (error) => {
    return { type: actionTypes.REQUEST_PAYMENT_PLATFORM_CUSTOMER_FAILED, payload: error };
  },
  paymentPlatformCustomerReceived: (customer) => {
    return { type: actionTypes.PAYMENT_PLATFORM_CUSTOMER_RECEIVED, payload: customer };
  },
  selectProductPlan: (planId) => {
    return { type: actionTypes.SELECT_PRODUCT_PLAN, payload: { planId: planId }};
  },
  submitCustomerPaymentMethod: (paymentMethod) => {
    return { type: actionTypes.SUBMIT_CUSTOMER_PAYMENT_METHOD, payload: { paymentMethod: paymentMethod }};
  },
  paymentMethodFailed: (error) => {
    return { type: actionTypes.PAYMENT_METHOD_FAILED, payload: error };
  },
  subscribeToPlan: () => {
    return { type: actionTypes.SUBSCRIBE_TO_PLAN };
  },
  subscriptionReceived: (subscription) => {
    return { type: actionTypes.SUBSCRIPTION_RECEIVED, payload: subscription};
  },
  subscribeCustomerToStripePlan: (paymentMethod) => {
    return { type: actionTypes.SUBSCRIBE_CUSTOMER_TO_STRIPE_PLAN, payload: { paymentMethod: paymentMethod }}; 
  },
  requestPaymentMethodDetails: (paymentMethodId) => {
    return { type: actionTypes.REQUEST_PAYMENT_METHOD_DETAILS, payload: { paymentMethodId: paymentMethodId }};
  },
  paymentMethodDetailsReceived: (paymentMethodDetails) => {
    return { type: actionTypes.PAYMENT_METHOD_DETAILS_RECEIVED, payload: paymentMethodDetails };
  },
  requestPaymentMethodDetailsFailed: (error) => {
    return { type: actionTypes.REQUEST_PAYMENT_METHOD_DETAILS_FAILED, payload: error };
  },
  updateWithOrderDetails: (details) => {
    return { type: actionTypes.UPDATE_WITH_ORDER_DETAILS, payload: details };
  },
  clearSubscriptionData: () => {
    return { type: actionTypes.CLEAR_SUBSCRIPTION_DATA };
  },
  synchToQueryString: (queryString) => {
    return { type: actionTypes.SYNCH_TO_QUERY_STRING, payload: { search: queryString } };
  },
  setCoupon: (coupon) => {
    return { type: actionTypes.SET_COUPON, payload: { coupon: coupon }};
  },
  setLeadSource: (source) => {
    return { type: actionTypes.SET_LEAD_SOURCE, payload: { source: source }};
  },
  pauseSubscription: (subscription) => {
    return { type: actionTypes.PAUSE_SUBSCRIPTION, payload: subscription };
  },
  resumeSubscription: (subscription) => {
    return { type: actionTypes.RESUME_SUBSCRIPTION, payload: subscription };
  },
  showAvailablePlans: (enabled) => {
    return { type: actionTypes.SHOW_AVAILABLE_PLANS, payload: { enabled: enabled }};
  },
  changePlan: (data) => {
    return { type: actionTypes.CHANGE_PLAN, payload: data };
  },
  handlePlanChanges: (changes) => {
    return { type: actionTypes.HANDLE_PLAN_CHANGES, payload: changes };
  }
}