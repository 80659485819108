import moment from 'moment'

import RiseTransitSetEvents from '@stephent/meeusjs/lib/risetransitsetevents'
import { ApogeePerigee } from '@stephent/meeusjs/lib/apogeeperigee';
import { toDegrees as rad2deg } from '@stephent/meeusjs/lib/meeushelper';

const EVENT_KEYS = RiseTransitSetEvents.EVENT_KEYS;

/**
 * Array of all allowed keys to display on the timeline - NOTE: this DOES include the Galactic Centre
 */
const ALLOWED_TIMELINE_KEYS = [
  EVENT_KEYS.SSRise, EVENT_KEYS.SSTransit, EVENT_KEYS.SSSet, EVENT_KEYS.SSCivilRise, EVENT_KEYS.SSCivilSet,
  EVENT_KEYS.SSNauticalRise, EVENT_KEYS.SSNauticalSet, EVENT_KEYS.SSAstroRise, EVENT_KEYS.SSAstroSet,
  EVENT_KEYS.SSGoldenEnd, EVENT_KEYS.SSGoldenStart, EVENT_KEYS.SSEquinox, EVENT_KEYS.SSSolstice,
  EVENT_KEYS.MMRise, EVENT_KEYS.MMTransit, EVENT_KEYS.MMSet,
  EVENT_KEYS.MMFull, EVENT_KEYS.MMNew, EVENT_KEYS.MMLastQuarter, EVENT_KEYS.MMFirstQuarter, EVENT_KEYS.MMCrescentMoonBestVisibility,
  EVENT_KEYS.MMApogee, EVENT_KEYS.MMPerigee,
  EVENT_KEYS.MEGalacticCentreRise, EVENT_KEYS.MEGalacticCentreTransit, EVENT_KEYS.MEGalacticCentreSet,
  EVENT_KEYS.MEMeteors,
  EVENT_KEYS.MMPenumbralFirstContact, EVENT_KEYS.MMPenumbralLastContact, EVENT_KEYS.MMUmbralFirstContact, EVENT_KEYS.MMUmbralLastContact, 
  EVENT_KEYS.MMTotalEclipseStart, EVENT_KEYS.MMTotalEclipseEnd, EVENT_KEYS.MMTimeOfMaximumEclipse
];

/**
 * Default event keys to include on the timeline - NOTE: this DOES NOT include the Galactic Centre
 */
const DEFAULT_TIMELINE_KEYS = [
  EVENT_KEYS.SSRise, EVENT_KEYS.SSTransit, EVENT_KEYS.SSSet, EVENT_KEYS.SSCivilRise, EVENT_KEYS.SSCivilSet,
  EVENT_KEYS.SSNauticalRise, EVENT_KEYS.SSNauticalSet, EVENT_KEYS.SSAstroRise, EVENT_KEYS.SSAstroSet,
  EVENT_KEYS.SSGoldenEnd, EVENT_KEYS.SSGoldenStart, EVENT_KEYS.SSEquinox, EVENT_KEYS.SSSolstice,
  EVENT_KEYS.MMRise, EVENT_KEYS.MMTransit, EVENT_KEYS.MMSet,
  EVENT_KEYS.MMFull, EVENT_KEYS.MMNew, EVENT_KEYS.MMLastQuarter, EVENT_KEYS.MMFirstQuarter, EVENT_KEYS.MMCrescentMoonBestVisibility,
  EVENT_KEYS.MMApogee, EVENT_KEYS.MMPerigee,
  EVENT_KEYS.MMPenumbralFirstContact, EVENT_KEYS.MMPenumbralLastContact, EVENT_KEYS.MMUmbralFirstContact, EVENT_KEYS.MMUmbralLastContact, 
  EVENT_KEYS.MMTotalEclipseStart, EVENT_KEYS.MMTotalEclipseEnd, EVENT_KEYS.MMTimeOfMaximumEclipse
];

/**
 * Keys representing the bodies we wish to display. DO NOT include keys for data derived from the body position (e.g. anti-position or shadows).
 * Search code base for usage of this const to see why.
 */
const DEFAULT_BODY_KEYS = [EVENT_KEYS.MESunPosition, EVENT_KEYS.MEMoonPosition];

const PENUMBRAL_LUNAR_ECLIPSE_KEYS = [EVENT_KEYS.MMPenumbralFirstContact, EVENT_KEYS.MMPenumbralLastContact];
const PARTIAL_LUNAR_ECLIPSE_KEYS = [EVENT_KEYS.MMUmbralFirstContact, EVENT_KEYS.MMUmbralLastContact];
const TOTAL_LUNAR_ECLIPSE_KEYS = [EVENT_KEYS.MMTotalEclipseStart, EVENT_KEYS.MMTotalEclipseEnd, EVENT_KEYS.MMTimeOfMaximumEclipse];
const TOTAL_LUNAR_ECLIPSE_SPAN_KEYS = [EVENT_KEYS.MMTotalEclipseStart, EVENT_KEYS.MMTotalEclipseEnd];

const PREMIUM_KEYS = [ ...PENUMBRAL_LUNAR_ECLIPSE_KEYS, ...PARTIAL_LUNAR_ECLIPSE_KEYS, ...TOTAL_LUNAR_ECLIPSE_KEYS];

const RELATIVE_SIZE_KEYS = [EVENT_KEYS.MMNew, EVENT_KEYS.MMFirstQuarter, EVENT_KEYS.MMFull, EVENT_KEYS.MMLastQuarter, EVENT_KEYS.MMApogee, EVENT_KEYS.MMPerigee];

const SOLSTICE_EQUINOX_KEYS = [EVENT_KEYS.SSEquinox, EVENT_KEYS.SSSolstice];

export const MeeusEvents = {

  ALLOWED_TIMELINE_KEYS: Object.freeze(ALLOWED_TIMELINE_KEYS),
  DEFAULT_TIMELINE_KEYS: Object.freeze(DEFAULT_TIMELINE_KEYS),
  DEFAULT_BODY_KEYS: Object.freeze(DEFAULT_BODY_KEYS),

  PENUMBRAL_LUNAR_ECLIPSE_KEYS: Object.freeze(PENUMBRAL_LUNAR_ECLIPSE_KEYS),
  PARTIAL_LUNAR_ECLIPSE_KEYS: Object.freeze(PARTIAL_LUNAR_ECLIPSE_KEYS),
  TOTAL_LUNAR_ECLIPSE_KEYS: Object.freeze(TOTAL_LUNAR_ECLIPSE_KEYS),
  TOTAL_LUNAR_ECLIPSE_SPAN_KEYS: Object.freeze(TOTAL_LUNAR_ECLIPSE_SPAN_KEYS),

  RELATIVE_SIZE_KEYS: Object.freeze(RELATIVE_SIZE_KEYS),

  SOLSTICE_EQUINOX_KEYS: Object.freeze(SOLSTICE_EQUINOX_KEYS),

  PREMIUM_KEYS: Object.freeze(PREMIUM_KEYS)

}

export const decorateLunarEvent = function(event, coordinate, elevation, lunarCoordinates) {

  const lc  = lunarCoordinates;

  // give it a clone so we don't mutate the original event time
  const mo = moment(event.moment);
  lc.calculate(mo).calculateLocalHorizontal(coordinate.lat, -coordinate.lng, elevation);
  event.azimuth = lc.azimuth
  event.apparentAltitude = lc.apparentAltitude
  event.illuminatedFraction = lc.illuminatedFraction;
  
  if (RELATIVE_SIZE_KEYS.includes(event.key)) {  
    // Add relative size
    // theta = atan(r/d), where r = moon radius, d = earth-moon distance
    // 2.theta is the arc subtended by the moon's disc
    const sizeOfMoon = rad2deg(2 * Math.atan(ApogeePerigee.constants.meanRadiusOfMoonKM/lc.distanceToEarth));
    event.relativeSizeOfMoon = sizeOfMoon / ApogeePerigee.constants.extremeApogeeSizeOfMoonDegrees;
  }

  // Is it waxing or waning?
  mo.add(1, 'minute');
  if (lc.calculate(mo).illuminatedFraction > event.illuminatedFraction) {
    event.waxing = true;
  } else {
    event.waxing = false;
  }

}