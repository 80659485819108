import { fork, all, call, put, takeLatest, takeEvery, select } from 'redux-saga/effects';
import moment from 'moment-timezone';
import log from 'loglevel';
import { push } from 'redux-first-history';
import { v4 as uuidv4 } from 'uuid';

import i18n from 'i18n';
import { logCentral } from 'services/log-central';

import { within360 } from '@stephent/meeusjs/lib/meeushelper'

import PELatLngBounds from 'classes/PELatLngBounds';

import { locationsDB } from 'storage/locations';

import { makeURL } from 'config';
import { getUserProfile, getMapState, getSynchToken, getSavedLocations, getGeodetics/*, getFilter*/ } from 'selectors';
import { actions as locationActions, actionTypes } from 'actions/locationActions';
import { actions as mapActions } from 'actions/mapActions';
import { actions as rootActions } from 'actions/rootActions';
import { authorizedRequest } from './authSaga';

const MOMENT_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

function postLocationsApiOptions(synchToken, locations) {

  let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  if (synchToken) {
    headers['X-Lss-Synch-Token'] = synchToken;
  }

  return {
    method: 'post',
    url: makeURL('/locations'),
    headers: headers,
    data: locations
  };
}

function postDeletedLocationsApiOptions(synchToken, deletedLocations) {

  let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  if (synchToken) {
    headers['X-Lss-Synch-Token'] = synchToken;
  }

  return {
    method: 'post',
    url: makeURL('/locations/deleted'),
    headers: headers,
    data: deletedLocations
  };
}

function getLocationsApiOptions(synchToken) {
  
  let headers = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  };

  if (synchToken) {
    headers['X-Lss-Synch-Token'] = synchToken;
  }

  return {
    method: 'get',
    url: makeURL('/locations'),
    headers: headers
  };
}

function* initDB() {

  if (!locationsDB.isOpen()) {
    
    try {
      // Open the database
      yield locationsDB.open();
      yield put(locationActions.setDatabaseOpen(true));
    } catch (e) {
      // console.log(e)
      log.error(`Dexie open failed: ${e.name} - ${e.message}`)
      // This check below ensures we don't bother logging the standard error that is thrown when trying to IndexedDB in a private Firefox window
      // No point in logging it, as it's super noisy and there's nothing much we can do about it.
      if (e.name !== 'InvalidStateError' || e.message.includes('A mutation operation was attempted on a database that did not allow mutations.') === false) {
        logCentral.error("Dexie open locations DB failed", e) 
      }
      yield put(locationActions.setDatabaseOpen(false));
    }
  }
}

function* restoreSynchToken() {

  const token = localStorage.getItem('LSSSynchToken');
  yield put(locationActions.setSynchToken(token));
}

/**
 * Delete all records from the database - caller is responsible for ensuring no data loss when this action is dispatched
 */
function* resetLocationsSaga() {

  try {

    if (!locationsDB.isOpen()) {
      logCentral.event({
        category: "Locations",
        action: "locations_database_not_open",
        label: "Reset locations"
      });
      return;
    }

    // Clear all records
    yield locationsDB.saved.clear();

    // Clear the locally stored synch token
    localStorage.removeItem('LSSSynchToken');

    // No need to reload into memory, as the reducer also handles this action and resets its own state

  } catch (e) {
    log.error(e);
    let errObj = {
      code: e.code,
      message: e.message
    }

    logCentral.error('Reset locations failed', e);

    yield put(locationActions.setLocationsError(errObj));  
  } finally {

  }
}

function* synchLocationsSaga(action) {

  if (!locationsDB.isOpen()) {
    logCentral.event({
      category: "Locations",
      action: "locations_database_not_open",
      label: "Synch locations"
    });
    return;
  }

  let storedCount = yield locationsDB.saved.count();

  try {

    yield put(locationActions.synchLocationsPending(true));

    const synchToken = yield select(getSynchToken);

    // 1) POST /locations/deleted    
    const deletedLocationsCollection = yield locationsDB.deleted.orderBy('uuid');
    const deletedLocations = yield deletedLocationsCollection.toArray();

    const postDeletedOptions = postDeletedLocationsApiOptions(synchToken, deletedLocations);
    const postDeletedRequest = yield call(authorizedRequest, postDeletedOptions);
    const postDeletedResponse = yield call(postDeletedRequest);

    if (Array.isArray(postDeletedResponse.data) && postDeletedResponse.data.length > 0) {
      const keysToDelete = postDeletedResponse.data.map( record => record.uuid );
      yield locationsDB.saved.bulkDelete(keysToDelete);
    }

    // We've posted our local deleted locations successfully (no error thrown), so now clear the local deleted table:
    yield locationsDB.deleted.clear();

    // 2) POST /locations
    const postLocationsCollection = yield locationsDB.saved.where('requiresSync').equals(1);
    const postLocations = yield postLocationsCollection.toArray();

    // Clean-up the location span
    const sanitizedPostLocations = postLocations.map((location) => {
      
      if (location.span && location.span.longitude >= 360) {
        location.span.longitude = within360(location.span.longitude)
      }
      
      return location;
    })

    if (sanitizedPostLocations.length) {
      const postLocationsOptions = postLocationsApiOptions(synchToken, sanitizedPostLocations);
      const postLocationsRequest = yield call(authorizedRequest, postLocationsOptions);
      yield call(postLocationsRequest);

      // We've posted our changes - we can now update them to mark them as no longer requiring synch
      yield postLocationsCollection.modify(location => {
        location.requiresSync = 0;
      });
    }

    // 3) GET /locations
    const getOptions = getLocationsApiOptions(synchToken);
    const getRequest = yield call(authorizedRequest, getOptions);
    const getResponse = yield call(getRequest);
    
    const updatedSynchToken = getResponse.headers['x-lss-synch-token'];
    const serverTime = getResponse.headers['x-lss-server-time'];

    // save the synch token
    if (updatedSynchToken) {
      localStorage.setItem('LSSSynchToken', updatedSynchToken);
    } else {
      log.warn('Synch token missing from GET /locations response');
    }
    yield put(locationActions.setSynchToken(updatedSynchToken, serverTime));
    
    // Process the received data
    const { data } = getResponse;
    // log.debug('synchLocationsSaga:', data);

    if (data.length > 0) {

      if (storedCount === 0) {
        // DB is empty - we can bulk add
        const result = yield locationsDB.saved.bulkAdd(data);
        log.debug('Dexie bulkAdd: ', result);
      } else {
        // We need to "upsert": find, if found update, if not, add
        // This is a good primer on the perils of misused promises: https://pouchdb.com/2015/05/18/we-have-a-problem-with-promises.html
        const promises = data.map( loc => {
  
          return locationsDB.saved.get(loc.uuid)
            .then(function (record) {
              if (record) {
                return locationsDB.saved.update(loc.uuid, loc);
              } else {
                return locationsDB.saved.add(loc);
              }
            });
        });
        yield all(promises);
      }  
    }
    
  } catch (e) {
    log.error(e.message, e.response || '');
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof(e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else if (e.message !== 'Request failed with status code 401') {
      logCentral.error('Get locations failed', e);
    }

    yield put(locationActions.setLocationsError(errObj));  
  } finally {
    // Update the stored count:
    storedCount = yield locationsDB.saved.count();
    yield put(locationActions.synchLocationsPending(false, storedCount));

    const userProfile = yield select(getUserProfile);
    if (userProfile.uuid) {
      // Load whatever we have stored into state:
      yield put(locationActions.requestLocationsInRange());
    } else {
      log.warn('Not requesting locations: no longer signed in');
    }
    
  }
};

function* requestLocationsSaga(action) {

  if (!locationsDB.isOpen()) {
    logCentral.event({
      category: "Locations",
      action: "locations_database_not_open",
      label: "Request locations"
    });
    return;
  }

  try {

    const userProfile = yield select(getUserProfile);

    if (!userProfile.uuid) {
      throw new Error('Cannot request locations: user uuid is missing!');
    }

    // Yield Dexie methods one-by-one so we can catch any errors individually:
    // We can't use both orderBy and filter (see https://github.com/dfahlander/Dexie.js/issues/297), 
    // so to ensure we only the user's own locations (see https://crookneck.atlassian.net/browse/TPEW-570), 
    // we filter here, then sort below:
    let collection = yield locationsDB.saved.where('useruuid').equals(userProfile.uuid)

    // let filter = yield select(getFilter);
    // if (filter && filter.field && filter.value) {
    //   collection = yield locationsDB.saved.where(filter.field).startsWith(filter.value);
    // } else {
    //   collection = yield locationsDB.saved.orderBy('title');
    // }

    let { startIndex, endIndex } = action.payload;

    // If action contains a range of records to load, then limit the collection to that window:
    if (startIndex && endIndex && endIndex > startIndex) {
      collection = collection.offset(startIndex).limit(endIndex - startIndex);
    }

    const storedLocations = yield collection.sortBy('title');

    // Sanitized data - must include a non-empty notes field (required for fuse.js index - it blows up otherwise.
    // See reselectors/locations.js
    const locationData = storedLocations.map(loc => { 
      if (!loc.notes || loc.notes.length === 0) {
        return { ...loc, indexableNotes: '!'};
      } 
      
      return { ...loc, indexableNotes: loc.notes};
    });

    yield put(locationActions.loadLocationsFromStore(locationData));
    
  } catch (e) {
    log.error(e.message, e.response || '');
    let errObj = {
      code: e.code,
      message: e.message
    }

    logCentral.error('Request locations failed', e);
    
    yield put(locationActions.setLocationsError(errObj));  
  }

}

function* moveToLocationSaga(action) {

  try {
 
    const { uuid, useSecondary, sourcePath } = action.payload;
    if (!uuid) {
      throw new Error('Cannot move to location: uuid missing');
    }

    const savedLocations = yield select(getSavedLocations);
    if (!savedLocations) {
      throw new Error('Cannot move to location: no saved locations!');
    }

    const loc = savedLocations.find(loc => loc.uuid === uuid);

    if (!loc) {
      throw new Error('Cannot move to location: uuid not found ' + uuid);
    }

    if (!loc.coordinate || !loc.coordinate.latitude || !loc.coordinate.longitude) {
      throw new Error('Cannot move to location: coordinate or coordinate property missing', loc);
    }

    const mapState = {
      zoom: undefined
    };

    const currentMapState = yield select(getMapState);
    const geodetics = yield select(getGeodetics);
    // Use the existing, if any
    let primaryCoordinate = currentMapState.coordinate;
    let secondaryCoordinate = geodetics.coordinate;

    if (useSecondary) {
      secondaryCoordinate = { lat: loc.coordinate.latitude, lng: loc.coordinate.longitude };
      yield put(mapActions.updateGeodeticsCoordinate(secondaryCoordinate));
    } else {

      // Update primary pin
      primaryCoordinate = { lat: loc.coordinate.latitude, lng: loc.coordinate.longitude };
      mapState.coordinate = primaryCoordinate;
      
      if (geodetics.enabled !== true) {
        mapState.center = { lat: loc.coordinate.latitude, lng: loc.coordinate.longitude };
        if (loc.span) {
          mapState.span = { lat: loc.span.latitude, lng: loc.span.longitude };
        } else {
          mapState.zoom = loc.zoom || 13;
        }
      }
    
      // We need to query for new elevation and time zone here
      yield put(mapActions.queryTimeZoneElevation(mapState.coordinate));

    }
    
    if (geodetics.enabled === true) {
      // Set a map span that will encompass both pin positions
      let span = currentMapState.span;
      if (!span) {
        span = {
          lat: currentMapState.halfSpan.lat * 2.0,
          lng: currentMapState.halfSpan.lng * 2.0
        }
      }
      const bounds = new PELatLngBounds(currentMapState.center, span);
      bounds.expandToInclude([primaryCoordinate, secondaryCoordinate]);
      if (bounds.span.lat !== span.lat || bounds.span.lng !== span.lng) {
        // Bounds were expanded - add some extra margin to avoid the map pins sitting on the very edges of the map
        bounds.expandByFactor(1.1);
      }
      mapState.center = bounds.center.toObject();
      mapState.span = bounds.span.toObject();
    }

    yield put(mapActions.updateMapState(mapState));

    // Back to the home page with our new location set
    yield put(push(sourcePath || '/'));

    // And update the query string
    yield put(rootActions.deriveUpdatedQueryString());
  
    logCentral.event({
      category: "Locations",
      action: "locations_move_to_saved_location"
    });

  } catch (e) {
    log.error(e.message);
    logCentral.error('Move to location failed', e);
  }

};

function* updateLocationSaga(action) {

  if (!locationsDB.isOpen()) {
    logCentral.event({
      category: "Locations",
      action: "locations_database_not_open",
      label: "Update location"
    });
    return;
  }

  try {
  
    yield put(locationActions.writeLocationsPending(true));

    const {uuid, changes } = action.payload;
    if (!uuid) {
      throw new Error('Update location: uuid is missing');
    }

    if (!changes) {
      throw new Error('Update location: changes not provided');
    }
    
    const lastUpdatedAt = moment().format(MOMENT_FORMAT);

    let updates = { ...changes, requiresSync: 1, lastUpdatedAt: lastUpdatedAt };

    let result = yield locationsDB.saved.update(uuid, updates);

    if (result !== 1) {
      throw new Error('Update location: unexpected record count: ' + result);
    }

    logCentral.event({
      category: "Locations",
      action: "locations_saved_location_updated"
    });

    yield put(locationActions.modifyLocationState('update', { uuid, ...updates }));

  } catch (e) {
    log.error(e.message, e.response || '');
    let errObj = {
      code: e.code,
      message: e.message
    }

    logCentral.error('Update location failed', e);
    
    yield put(locationActions.setLocationsError(errObj));  
  } finally {
    yield put(locationActions.writeLocationsPending(false));
  }
}

function* addLocationSaga(action) {

  if (!locationsDB.isOpen()) {
    logCentral.event({
      category: "Locations",
      action: "locations_database_not_open",
      label: "Add location"
    });
    return;
  }

  yield put(locationActions.writeLocationsPending(true));

  let { willEdit, location } = action.payload;

  if (!location) {

    const mapState = yield select(getMapState);
  
    // None specified, so derive it from the map state
    location = {
      coordinate: {
        latitude: mapState.coordinate.lat,
        longitude: mapState.coordinate.lng,
      },
      zoomLevel: mapState.zoom,
      title: i18n.t('locations.untitledLocation'), // https://github.com/i18next/react-i18next/issues/909#issuecomment-514604843
      timezoneId: mapState.timeZone.verified !== false ? mapState.timeZone.timeZoneId || '' : '',
      elevationAboveMSL: mapState.elevation.verified === true ? mapState.elevation.value :  -32768,
      notes: '',
    }

    // Note: .halfSpan may not be set if user has never viewed the map
    if (mapState.halfSpan) {
      location.span = {
        latitude: mapState.halfSpan.lat * 2,
        longitude: within360(mapState.halfSpan.lng * 2)
      }
    }
  }

  try {

    const createdAt = moment().format(MOMENT_FORMAT);
    const userProfile = yield select(getUserProfile);
    const saveFields = {
      useruuid: userProfile.uuid,
      createdAt: createdAt,
      lastUpdatedAt: createdAt,
      requiresSync: 1,
      uuid: uuidv4()
    }

    const newLocation = { ...location, ...saveFields };
  
    let result = yield locationsDB.saved.add(newLocation);

    if (result !== saveFields.uuid) {
      throw new Error('Add location: unexpected result for add operation: ' + result);
    }

    logCentral.event({
      category: "Locations",
      action: "locations_saved_location_added"
    });

    yield put(locationActions.modifyLocationState('add', newLocation));

    if (willEdit) {
      yield put(locationActions.editLocation(saveFields.uuid));
    }

  } catch (e) {
    log.error(e.message, e.response || '');
    let errObj = {
      code: e.code,
      message: e.message
    }

    logCentral.error('Add location failed', e);
    
    yield put(locationActions.setLocationsError(errObj));  
  } finally {
    yield put(locationActions.writeLocationsPending(false));
  }
}

function* deleteLocationSaga(action) {
 
  if (!locationsDB.isOpen()) {
    logCentral.event({
      category: "Locations",
      action: "locations_database_not_open",
      label: "Delete location"
    });
    return;
  }

  try {
  
    yield put(locationActions.writeLocationsPending(true));

    const { uuid } = action.payload;
    if (!uuid) {
      throw new Error('Delete location: uuid is missing');
    }

    const deletedAt = moment().format(MOMENT_FORMAT);
    const userProfile = yield select(getUserProfile);

    const tx = locationsDB.transaction('rw', locationsDB.saved, locationsDB.deleted, () => {
      
      locationsDB.deleted.add({ uuid: uuid, useruuid: userProfile.uuid, deletedAt: deletedAt })
       .then(() => {
        locationsDB.saved.delete(uuid);
       });
    });

    yield tx;

    logCentral.event({
      category: "Locations",
      action: "locations_saved_location_deleted"
    });

    // Clear state of information about the deleted location
    yield put(locationActions.postDeleteCleanup());

    yield put(locationActions.modifyLocationState('delete', { uuid: uuid }));

  } catch (e) {
    log.error(e.message, e.response || '');
    let errObj = {
      code: e.code,
      message: e.message
    }

    logCentral.error('Delete location failed', e);
    
    yield put(locationActions.setLocationsError(errObj));  
  } finally {
    yield put(locationActions.writeLocationsPending(false));
  }
}

function* deleteAllLocationsSaga() {

  if (!locationsDB.isOpen()) {
    logCentral.event({
      category: "Locations",
      action: "locations_database_not_open",
      label: "Delete all locations"
    });
    return;
  }

  try {
  
    yield put(locationActions.writeLocationsPending(true));

    const deletedAt = moment().format(MOMENT_FORMAT);
    const userProfile = yield select(getUserProfile);

    const collection = yield locationsDB.saved.where('useruuid').equals(userProfile.uuid)

    const tx = locationsDB.transaction('rw', locationsDB.saved, locationsDB.deleted, () => {
      
      collection.each((location) => {
        locationsDB.deleted.add({ uuid: location.uuid, useruuid: userProfile.uuid, deletedAt: deletedAt })
         .then(() => {
          locationsDB.saved.delete(location.uuid);
         });
      })
    });

    yield tx;

    logCentral.event({
      category: "Locations",
      action: "locations_all_saved_locations_deleted"
    });

    yield put(locationActions.synchLocations());  

  } catch (e) {
    log.error(e.message, e.response || '');
    let errObj = {
      code: e.code,
      message: e.message
    }

    logCentral.error('Delete location failed', e);
    
    yield put(locationActions.setLocationsError(errObj));  
  } finally {
    yield put(locationActions.writeLocationsPending(false));
  }
}

function* importLocationsSaga(action) {

  if (!locationsDB.isOpen()) {
    logCentral.event({
      category: "Locations",
      action: "locations_database_not_open",
      label: "Import locations"
    });
    return;
  }

  let storedCount = yield locationsDB.saved.count();

  try {

    const locations = action.payload;

    if (!Array.isArray(locations) || locations.length === 0) {
      return
    }
      
    yield put(locationActions.synchLocationsPending(true));
    
    const mo = moment();
    const createdAt = mo.format(MOMENT_FORMAT);
    const userProfile = yield select(getUserProfile);
    const importTag =  i18n.t('locations.importedAt', { 'timestamp': mo.format()}) 
    const saveFields = {
      useruuid: userProfile.uuid,
      createdAt: createdAt,
      lastUpdatedAt: createdAt,
      requiresSync: 1,
      importTag: importTag
    }

    const synchReadyLocations = locations.map(location => {
      const decoratedLocation = { ...location,  ...saveFields }
      if (!decoratedLocation.uuid) {
        // generate a unique ID
        decoratedLocation.uuid = uuidv4();
      }
      return decoratedLocation;
    })

    if (storedCount === 0) {
      // DB is empty - we can bulk add
      const result = yield locationsDB.saved.bulkAdd(synchReadyLocations);
      log.debug('Dexie bulkAdd: ', result);
    } else {
      const promises = synchReadyLocations.map( loc => {

        return locationsDB.saved.get(loc.uuid)
          .then(function (record) {
            // Only import records that do not already exist
            if (!record) {
              return locationsDB.saved.add(loc);
            }
          });
      });
      yield all(promises);
    }  
    
    yield put(locationActions.setSavedLocationsFilter("importTag", importTag, "importLocationsSaga"));
    
  } catch (e) {
    log.error(e.message, e.response || '');
    let errObj = {
      code: e.code,
      message: e.message
    }

    if (e.response && e.response.data && typeof(e.response.data) === 'object') {
      // Response from server
      errObj = e.response.data;
      errObj.source = 'server';
    } else {
      logCentral.error('Import locations failed', e);
    }

    yield put(locationActions.setLocationsError(errObj));  
  } finally {
    // Update the stored count:
    storedCount = yield locationsDB.saved.count();
    yield put(locationActions.synchLocationsPending(false, storedCount));

    // Load whatever we have stored into state:
    yield put(locationActions.requestLocationsInRange());
  }

}

/**
 saga watcher that is triggered when dispatching action of type 'SIGN_IN'
  */
 export function* locationSagas() {
  
  yield fork(initDB);
  yield fork(restoreSynchToken);

  yield takeLatest(actionTypes.RESET_LOCATIONS, resetLocationsSaga);
  yield takeLatest(actionTypes.SYNCH_LOCATIONS, synchLocationsSaga);
  yield takeLatest(actionTypes.REQUEST_LOCATIONS, requestLocationsSaga);
  yield takeLatest(actionTypes.MOVE_TO_LOCATION, moveToLocationSaga);

  yield takeEvery(actionTypes.UPDATE_LOCATION, updateLocationSaga);
  yield takeEvery(actionTypes.ADD_LOCATION, addLocationSaga);
  yield takeLatest(actionTypes.DELETE_LOCATION, deleteLocationSaga);
  yield takeLatest(actionTypes.DELETE_ALL_LOCATIONS, deleteAllLocationsSaga);

  yield takeLatest(actionTypes.IMPORT_LOCATIONS, importLocationsSaga);
}