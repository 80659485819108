import { createSelector } from 'reselect'
import moment from 'moment-timezone'
import { isNumber } from 'lodash'
import log from 'loglevel'

import RiseTransitSetEvents from '@stephent/meeusjs/lib/risetransitsetevents'
import SolarCoordinates from '@stephent/meeusjs/lib/solarcoordinates'
import LunarCoordinates from '@stephent/meeusjs/lib/lunarcoordinates'
import StarCoordinates from '@stephent/meeusjs/lib/starcoordinates'
import { dipOfTheHorizon } from '@stephent/meeusjs/lib/dipofhorizon'
import Ellipsoid from '@stephent/meeusjs/lib/vincenty/ellipsoid'

import { getRouter, getDateTime, getMapState, getCoordinate, getTimeZone, getElevation, getGeodetics, getHorizon, getSettings } from 'selectors'

import { buildQueryString } from 'lib/urls'

const wgs84 = Ellipsoid.WGS84;

/**
 * A memoized selector to return default sun/moon (and more) position calculators required
 * for getBodyPositions.
 */
export const getBodyCoordinateCalculators = createSelector(
  [getSettings],
  (settings) => {

    const calculators = {};

    if (settings.bodyKeys.includes(RiseTransitSetEvents.EVENT_KEYS.MESunPosition)) {
      calculators[RiseTransitSetEvents.EVENT_KEYS.MESunPosition] = new SolarCoordinates();
    }
    if (settings.bodyKeys.includes(RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition)) {
      calculators[RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition] = new LunarCoordinates();
    }
    if (settings.bodyKeys.includes(RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition)) {
      calculators[RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition] = StarCoordinates.GalacticCentre();
    }

    return calculators;
  }
)

/**
 * A memoized selector for body positions (az/alt/date). Depends on settings (indirectly to see which bodies are
 * enabled), date/time, coordinates, time zone, elevation.
 */
export const getBodyPositions = createSelector(
  [getBodyCoordinateCalculators, getDateTime, getCoordinate, getTimeZone, getElevation],
  (calculators, dateTime, coordinate, timeZoneObj, elevationObj) => {
    
    //log.debug("getBodyPositions called: ** calculating sun/moon position ** ");

    const tzid = timeZoneObj.timeZoneId;
    if (typeof tzid !== 'string') {
      log.error('getBodyPositions: timeZoneId is not a string', tzid, typeof(timeZoneId));
      return [];
    }

    var elevation = 0; // default to MSL
    if (isNumber(elevationObj.value)) {
      elevation = elevationObj.value;
    }

    let mo = moment(dateTime).tz(tzid);
    
    var positions = [];

    for (let [key, calculator] of Object.entries(calculators)) {
      // log.debug(`${key}: ${value}`);
      calculator.calculate(mo).calculateLocalHorizontal(coordinate.lat, -coordinate.lng, elevation);
      
      let result = {
        key: key,
        moment: mo.clone(),
        azimuth: calculator.azimuth,
        apparentAltitude: calculator.apparentAltitude
      };

      if (calculator instanceof LunarCoordinates) {
        result.illuminatedFraction = calculator.illuminatedFraction
        result.semidiameterInDegrees = calculator.semidiameterInDegrees
      } else if (calculator instanceof SolarCoordinates) {
        result.semidiameterInDegrees = calculator.semidiameterInDegrees
      }

      positions.push(result);
    }

    return positions;
  }
)

export const getElevationAboveHorizon = createSelector(
  [getElevation, getGeodetics, getHorizon],
  (elevation, geodetics, horizon) => {

    if (horizon.enabled !== true) {
      return 0;
    }

    // We'll use unverified values as 'best guess', but we check there is at least a numeric value available
    if (!isNumber(elevation.value)) {
      return 0;
    }

    if (horizon.useGeodetics === true) {
      if (!geodetics.elevation || !isNumber(geodetics.elevation.value)) {
        return 0;
      }

      return Math.max(0, elevation.value - geodetics.elevation.value);
    }

    return Math.max(0, elevation.value - horizon.elevationAtHorizon);
  }
)

export const getDipOfTheHorizon = createSelector(
  [getElevationAboveHorizon],
  (eah) => {

    return dipOfTheHorizon(eah)
  }
)

export const getDistanceToTheHorizon = createSelector(
  [getElevationAboveHorizon],
  (eah) => {

    // See http://mintaka.sdsu.edu/GF/explain/atmos_refr/horizon.html
    // and http://pollux.nss.nima.mil/calc/horizon.html
    
    // Distance to horizon, allowing for refraction, is d = sqrt(2 * 7/6 * R * h)
    // R = radius of earth, 6378 km on average
    // h = height above horizon, in metres
    // 7/6 is correction factor for refraction - creates a 'virtual earth radius' that compensates for larger bend radius of refracted ray of light
    
    const MIN_HEIGHT = 1.524; // metres = 5 feet - assume camera isn't lying on the ground.
    return Math.sqrt(2.0 * 7.0/6.0 * wgs84.semiMajorAxis * Math.max(eah, MIN_HEIGHT));	
  }
)

export const getQueryString = createSelector(
  [getRouter, getDateTime, getMapState],
  (router, dateTime, mapState) => {

    const qs = buildQueryString(mapState, dateTime, router.location.search);

    return qs
  }
)

export const getQueryStringIsValid = createSelector(
  [getQueryString, getRouter],
  (qs, router) => {

    const isValid = router.location.search === qs

    return isValid
  }
)