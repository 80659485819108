import { call, put, takeEvery, takeLatest, select, cancelled } from 'redux-saga/effects';
import { push } from 'redux-first-history';
import queryString from 'query-string';
import log from 'loglevel';
import { logCentral } from '../services/log-central';

//import { browserHistory } from 'react-router';
// Inspired by https://auth0.com/blog/beyond-create-react-app-react-router-redux-saga-and-more/
import { makeURL } from '../config';
import { actions, actionTypes } from 'actions/subscribeActions';
import { authorizedRequest, AuthorizedRequestError } from './authSaga';

import { getSelectedProductPlanId } from 'selectors';

/**
 * Constructs options for an axios call to our backend to obtain product plans and localized pricing for 
 * the current user, based on the caller IP address
 * @param {String} entitlement 
 */
function getUserPricingRequestOptions(entitlement) {
  
  return {
    method: 'get',
    url: makeURL('/subscriptions/' + entitlement + '/pricing'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };
}

/** Get profile function that returns an axios call */
function getCustomerApiOptions(refresh = false) {
  
  let params = {};
  if (refresh) {
    params.refresh = true;
  }

  return {
    method: 'get',
    url: makeURL('/subscriptions/customer'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    params: params
  };
}

/** Get profile function that returns an axios call */
function getSubscriptionPauseOptions(subscriptionID, shouldPause) {
  
  return {
    method: 'post',
    url: makeURL('/subscriptions/plan/change'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: {
      pause: shouldPause,
      subscriptionID: subscriptionID
    }
  };
}

/** Get profile function that returns an axios call */
function getPreviewPlanChangeOptions(subscriptionID, planID) {
  
  return {
    method: 'post',
    url: makeURL('/subscriptions/plan/preview_change'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: {
      planID: planID,
      subscriptionID: subscriptionID
    }
  };
}

/** Get profile function that returns an axios call */
function getExecutePlanChangeOptions(subscriptionID, planID) {
  
  return {
    method: 'post',
    url: makeURL('/subscriptions/plan/change'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: {
      planID: planID,
      subscriptionID: subscriptionID
    }
  };
}

function getPaymentMethodDetailsApiOptions(paymentMethodId) {

  return {
    method: 'get',
    url: makeURL('/subscriptions/paymentmethod/' + paymentMethodId),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };
}

function postPaymentMethodApiOptions(paymentMethod) {
  
  // Add the source of the sign up, i.e. this app
  let params = { 
    'src' : process.env.REACT_APP_SOURCE_NAME
  };

  return {
    method: 'post',
    url: makeURL('/subscriptions/paymentmethod/'),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    data: paymentMethod,
    params: params
  };
}

function postProductPlanIdOptions(planId) {
  
  return {
    method: 'post',
    url: makeURL('/subscriptions/plans/' + planId),
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    }
  };
}

function* synchToQueryStringSaga(action) {

  const { search } = action.payload;

  const parsed = queryString.parse(search, { arrayFormat: 'comma' });
  
  if (parsed.coupon) {
    const sanitized = parsed.coupon.trim().toUpperCase();
    yield put(actions.setCoupon(sanitized));
  }

  if (parsed.lead) {
    yield put(actions.setLeadSource(parsed.lead));
  }
}

function* showSubscriptionPage(action) {

  let { source } = action.payload;

  logCentral.event({
    category: "Subscriptions",
    action: "subscription_show_purchase_page",
    label: source + ''
  });

  // https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_promotion
  logCentral.eventGA4( "view_promotion", {
    creative_name: source,
    items: [
      // This is a dummy item - the user hasn't yet selected which plan they might want
      {
        item_id: 0,
        item_name: process.env.REACT_APP_ENTITLEMENT_PRO
      }
    ]
  });


  yield put(actions.setLeadSource(source))

  yield put(push("/subscribe"))
}

function* requestProductPlansSaga(action) {

  try {

    const { entitlement } = action.payload;

    let options = getUserPricingRequestOptions(entitlement);
    let request = yield call(authorizedRequest, options);
    
    let { data } = yield call(request);

    // dispatch action to change redux state
    yield put(actions.requestProductPlansSuccess(data));
    
  } catch (e) {
    let shouldLog = true;
    if ((e instanceof AuthorizedRequestError) === false) {
      shouldLog = false;
    } else  if (e.response && e.response.status && e.response.status === 401) {
      shouldLog = false;
    } else if (e.message === 'Request failed with status code 401') {
      shouldLog = false;
    }

    if (shouldLog) {
      logCentral.error('Request product plans failed', e);
    }

    // dispatch action to change redux state
    yield put(actions.requestProductPlansFailure(e));
  } finally {
    if (yield cancelled()) {
      log.debug('requestProductPlansSaga: cancelled');
    }
  }
}

function* pauseSubscription(action) {

  try {
    
    const { subscription_id } = action.payload;

    let request = yield call(authorizedRequest, getSubscriptionPauseOptions(subscription_id, true))
    let { data } = yield call(request);

    log.debug(data);
  } catch (e) {
    
    if ((e instanceof AuthorizedRequestError) === false) {
      log.error(e, e.response || '');
    }

    // Response from server, if available
    if (e.response?.data?.message) {
      log.error(e.response?.data?.message);
    } else {
      logCentral.error('Pause subscription failed', e);
    }

  }
}

function* resumeSubscription(action) {

  try {
    
    const { subscription_id } = action.payload;

    let request = yield call(authorizedRequest, getSubscriptionPauseOptions(subscription_id, false))
    let { data } = yield call(request);

    log.debug(data);
  } catch (e) {
    
    if (e instanceof AuthorizedRequestError) {
      log.error(e, e.response || '');
    }

    // Response from server, if available
    if (e.response?.data?.message) {
      log.error(e.response?.data?.message);
    } else {
      logCentral.error('Resmue subscription failed', e);
    }

  }
}

function* changePlan(action) {

  try {
    
    const { preview, subscriptionID, planID } = action.payload;

    let options;
    if (preview) {
      // Preview plan change
      options = getPreviewPlanChangeOptions(subscriptionID, planID);
    } else {
      // Request plan change
      options = getExecutePlanChangeOptions(subscriptionID, planID);
    }

    // Update state to show plan change request is pending
    yield put(actions.handlePlanChanges({
      preview: preview,
      subscriptionID: subscriptionID,
      planID: planID,
      pending: true
    }));

    // Send request
    let request = yield call(authorizedRequest, options);
    let { data } = yield call(request);

    // Handle response
    log.debug(data);

    if (preview) {
      // We preview the change of plan, but did not execute the change
      yield put(actions.handlePlanChanges({ pending: false, preview_changes: { success: true, ...data }}));
    } else {
      // We actually executed the change
      yield put(actions.handlePlanChanges({ pending: false, changes: { success: true, ...data }}));
    }

    /* Preview changes successful response:
    {
      "subscription_id": 245480,
      "user_id": 188965,
      "plan_id": 19364,
      "immediate_payment": {
        "amount": 12.76,
        "currency": "GBP",
        "date": "2022-05-19"
      },
      "next_payment": {
        "amount": 25,
        "currency": "GBP",
        "date": "2023-05-19"
      }
    }*/
    
  } catch (error) {
    
    // Paddle Error codes: https://developer.paddle.com/api-reference/ZG9jOjI1MzUzOTkw-api-error-codes
    /* 
      Preview changes 400 response with error code - examples:
      { "code": 108, "message": "Unable to find requested product" }
      { "code": 198, "message": "The selected new plan is invalid." }
      { "code": 194, "message": "Changes can not be made whilst the subscription is paused." }
    */

    // Response from server, if available
    if (error.response?.data) {
      if (action?.payload.preview) {
        yield put(actions.handlePlanChanges({ pending: false, preview_changes: { success: false, ...error.response?.data }}));
      } else {
        yield put(actions.handlePlanChanges({ pending: false, changes: { success: false, ...error.response?.data }}));
      }
      log.error(error.response?.data);
    } 

    logCentral.error('Change plan failed', error);

  }
}

function* requestCustomerSaga(action) {

  try {

    const { refresh } = action.payload;
    
    let request = yield call(authorizedRequest, getCustomerApiOptions(refresh));
    let { data } = yield call(request);

    // dispatch action to change redux state
    yield put(actions.paymentPlatformCustomerReceived(data));

  } catch (e) {
    if ((e instanceof AuthorizedRequestError) === false) {
      log.error(e, e.response || '');
    }

    // Response from server, if available
    if (e.response && e.response.data && e.response.data.message) {
      // msg = e.response.data.message;
    } else {
      logCentral.error('Get customer details failed', e);
    }

    // dispatch action to change redux state
    yield put(actions.requestPaymentPlatformCustomerFailed(e));
  } finally {
    if (yield cancelled()) {
      log.debug('requestCustomerSaga: cancelled');
    }
  }
}

function* requestPaymentMethodDetailsSaga(action) {

  try {
    
    let request = yield call(authorizedRequest, getPaymentMethodDetailsApiOptions(action.payload.paymentMethodId));
    let { data } = yield call(request);

    // dispatch action to change redux state
    yield put(actions.paymentMethodDetailsReceived(data));

    logCentral.event({
      category: "Subscriptions",
      action: "Get payment method details"
    });
  } catch (e) {
    if ((e instanceof AuthorizedRequestError) === false) {
      log.error(e, e.response || '');
    }

    // Response from server, if available
    if (e.response && e.response.data && e.response.data.message) {
      //msg = e.response.data.message;
    } else {
      logCentral.error('Get payment method details failed', e);
    }

    // dispatch action to change redux state
    yield put(actions.requestPaymentMethodDetailsFailed(e));
  } finally {
    if (yield cancelled()) {
      log.debug('requestPaymentMethodDetailsSaga: cancelled');
    }
  }
}

/**
 * Saga to set a payment method for a customer. If the signed in CPA user does not have an existing payment platform customer id
 * a payment platform customer record is created for them. If they do, then the default payment method is updated.
 * In either case, if successful, this saga updates redux state with the updated payment platform customer object.
 * @param {Object} action 
 */
function* submitCustomerPaymentMethodSaga(action) {

  // action.payload is object with a paymentMethod property (the paymentMethod that we submit to our server)
  try {
    
    let request = yield call(authorizedRequest, postPaymentMethodApiOptions(action.payload.paymentMethod));
    let { data } = yield call(request);

    // If successful, a new payment platform customer object is returned
    yield(put(actions.paymentPlatformCustomerReceived(data)));

    logCentral.event({
      category: "Subscriptions",
      action: "subscription_payment_method_submitted"
    });
  } catch (e) {
    if ((e instanceof AuthorizedRequestError) === false) {
      log.error(e, e.response || '');
    }

    var msg = e.message;

    // Response from server, if available
    if (e.response && e.response.data && e.response.data.raw) {
      // Dispatch action to update state with payment method error - this is a stripe error proxied back via CPA
      msg = e.response.data.raw.message;
      yield(put(actions.paymentMethodFailed(new Error(msg))));
    } else if (e.response && e.response.data && e.response.data.message) {
      // Dispatch action to update state with payment method error - this is an error response from CPA itself
      yield(put(actions.paymentMethodFailed(e.response.data)));
      msg = e.response.data.message;
    } else {
      yield(put(actions.paymentMethodFailed(e)));
    }

    logCentral.error('Submit payment method failed', e);

  } finally {
    if (yield cancelled()) {
      log.debug('submitCustomerPaymentMethodSaga: cancelled');
    }
  }
}

function* subscribeToPlanSaga() {

  try {

    const planId = yield select(getSelectedProductPlanId);

    if (!planId) {
      throw new Error('Cannot subscribe customer: no plan selected');
    }

    // Now we need to post the selected planId to create the subscription in the payment platform
    let request = yield call(authorizedRequest, postProductPlanIdOptions(planId));
    let subscriptionResult = yield call(request);

    yield(put(actions.subscriptionReceived(subscriptionResult.data)));

    logCentral.event({
      category: "Subscriptions",
      action: "subscription_subscribed_to_plan",
      label: planId
    });
  } catch (e) {
    if ((e instanceof AuthorizedRequestError) === false) {
      log.error(e, e.response || '');
    }

    logCentral.error('Subscribe to plan failed', e);

  } finally {
    if (yield cancelled()) {
      log.debug('subscribeToPlanSaga: cancelled');
    }
  }
}

/*
function* subscribeCustomerToStripePlanSaga(action) {

  try {
    yield(put(actions.submitCustomerPaymentMethod(action.payload.paymentMethod)));
    yield(put(actions.subscribeToPlan()));
  } catch (e) {
    log.error(e.message);
  }
}
*/

/**
 saga watcher that is triggered when dispatching action of type 'SIGN_IN'
  */
export function* subscribeSagas() {
  
  yield takeLatest(actionTypes.SYNCH_TO_QUERY_STRING, synchToQueryStringSaga);

  yield takeLatest(actionTypes.SHOW_SUBSCRIPTION_PAGE, showSubscriptionPage);

  yield takeLatest(actionTypes.REQUEST_PRODUCT_PLANS, requestProductPlansSaga);
  yield takeLatest(actionTypes.REQUEST_PAYMENT_PLATFORM_CUSTOMER, requestCustomerSaga);
  yield takeLatest(actionTypes.REQUEST_PAYMENT_METHOD_DETAILS, requestPaymentMethodDetailsSaga);

  yield takeEvery(actionTypes.SUBMIT_CUSTOMER_PAYMENT_METHOD, submitCustomerPaymentMethodSaga);
  yield takeEvery(actionTypes.SUBSCRIBE_TO_PLAN, subscribeToPlanSaga);

  yield takeEvery(actionTypes.PAUSE_SUBSCRIPTION, pauseSubscription);
  yield takeEvery(actionTypes.RESUME_SUBSCRIPTION, resumeSubscription);

  yield takeEvery(actionTypes.CHANGE_PLAN, changePlan);

  // yield takeEvery(actionTypes.SUBSCRIBE_CUSTOMER_TO_STRIPE_PLAN, subscribeCustomerToStripePlanSaga);
}

