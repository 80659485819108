import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { isMobileOnly } from 'react-device-detect'; // https://github.com/duskload/react-device-detect#readme

import { getSettings } from 'selectors';
import { getIsSignedIn } from 'reselectors/userProfile';

import SignOut from '../signout/SignOut';
import LanguageSelection from '../languageSelection/LanguageSelection';
import './header.css';

export default function Header() {
	
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { fullScreenEnabled } = useSelector(getSettings);
	const isSignedIn = useSelector(getIsSignedIn);

	// var conditionalLinkClassName = '';
	// if (isSignedIn !== true) {
	// 	conditionalLinkClassName = 'unauthorized';
	// }
	
	// create a ref for the menu input, so we can control its state via effects
	const menuInput = useRef();

	// On change of browser location, hide the menu, by setting the input state to false
	// The rest is done via CSS
	useEffect(() => {
		if (menuInput.current) {
			menuInput.current.checked = false;
		}
  }, [pathname]);

	// htmlFor="menu" - see https://github.com/evcohen/eslint-plugin-jsx-a11y/blob/master/docs/rules/label-has-associated-control.md
	return (
		<>
			
			{ (!fullScreenEnabled || pathname !== '/') &&  
				<header tabIndex="-1">
			
					<div className="app-logo"/>

					<div className="absolute top-0 right-0 mt-1 mr-2 flex flex-baseline gap-1 text-sm deemphasize">
						{ isSignedIn && <SignOut /> }
						{ !isSignedIn && pathname === '/' &&
							<div>
								{t('account.notSignedIn')}
							</div>
						}
						{ !isSignedIn && pathname !== '/signin' && !isMobileOnly &&
							<div>
								<Link to="/signin">{t('account.signIn')}</Link>
							</div>
						}
						<LanguageSelection />
					</div>

					<div className="headerContainer">					
						<h3>
							<a rel="noopener noreferrer" href="https://photoephemeris.com">
								<span className='logotype photo'>Photo</span>&thinsp;<span className='logotype ephemeris'>Ephemeris</span>
							</a>
						</h3>
						<div className="nav-links">
							<input type="checkbox" className='' id="menu" ref={menuInput} />
							<label htmlFor="menu">Menu</label>
							<div className="nav-links-content">
								<ul>
									{ isSignedIn &&
										<>	
											<li><Link to="/" className={ pathname === "/" ? 'unauthorized' : '' }>{t('page-titles.map')}</Link></li>
											<li><Link to="/sphere" className={ pathname === "/sphere" ? 'unauthorized' : '' }>{t('page-titles.3d')}</Link></li>
											<li>
												<Link to={{pathname: "/locations", state: { sourcePath: pathname }}} className={ pathname === "/locations" ? 'unauthorized' : '' }>{t('page-titles.locations')}</Link>
											</li>
											<li><Link to="/settings" className={ pathname === "/settings" ? 'unauthorized' : '' }>{t('page-titles.settings')}</Link></li>
										</>
									}
									<li>
											<a href="https://support.crookneckapps.com/hc/en-us/sections/360000070763-Tutorials" target="_blank" rel="noopener noreferrer">
												{t('page-titles.tutorials')}
											</a>
									</li>
									<li>
										<a href="https://support.crookneckapps.com/hc/en-us/articles/360043977172" target="_blank" rel="noopener noreferrer">
											{t('page-titles.whatsNew')}
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</header>
			
			}
		</>
	);
}
