import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';

import './styles/index.css';

// Import this first so that logging is initialized as early as possible - disabled for now, pending fix for https://github.com/logdna/logdna-browser/issues/15
// import 'services/logdna';

import App from './App';
import * as serviceWorker from './serviceWorker';
import './i18n';
import Loading from './components/suspense/Loading'
// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

// Initialize Stripe
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
// const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const app = (
  //<Elements stripe={stripePromise}>
  <Suspense fallback={<Loading />}>
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </Suspense>
  // </Elements>
)

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
