import React from 'react';
import * as Sentry from '@sentry/react';

function DefaultError({ eventId }) {
  return (
    <div className="p-4 bg-regent-gray-100 border border-cinnabar-500 border-l-6 my-4">
      <h4 className="text-cinnabar-500">Something's not working</h4>
      <p className="pb-2">
        We're sorry, it seems we've hit an error. Please let us know what happened:
      </p>
      <div className="pb-2 my-2 flex items-baseline gap-4">
        <div className="flex-1"></div>
        <button className='p-4 h-auto bg-cinnabar-500 text-white hover:text-regent-gray-100' onClick={() => Sentry.showReportDialog({ eventId: eventId })}>
          Send feedback
        </button>
        <div className="flex-1"></div>
      </div>
      <p className="pb-2 text-regent-gray-800">
        We review error reports regularly and will investigate further.
      </p>
      <h5 className="text-regent-gray-900">Other Possible Causes</h5>
      <h6 className="text-regent-gray-800">Old Browser versions are not supported</h6>
      <p className="pb-2 text-regent-gray-700">
        It's possible you're running an <strong>old web browser</strong> that is not supported. Please make sure you're running an <a href="https://browsehappy.com">up-to-date version</a>.
      </p>
      <p className="pb-2 text-regent-gray-700">
        However, if your browser is up to date, then we'll investigate the problem to see what went wrong.
      </p>
      <h6 className="text-regent-gray-800">Note</h6>
      <p className="pb-2 text-regent-gray-700">
        This site cannot be used with <strong>Google Translate</strong> or similar automatic translation tools. Please disable and reload.
      </p>
    </div>
  )
}

export default DefaultError