import { createReducer } from '@reduxjs/toolkit';

import { actionTypes } from '../actions/searchActions';

const initialState = {
  serviceCredentials: undefined, // these include an API key, plus other service behaviour details
  searchTerm: undefined,
  results: [],
  resultsPending: false
};

/**
 * Note: this uses redux-toolkit createReducer so we can write mutating code for state
 * See https://redux-toolkit.js.org/api/createReducer
 */
export const search = createReducer(initialState, {
  [actionTypes.UPDATE_SEARCH_TERM] : (state, action) => {
    return { ...state, searchTerm: action.payload.searchTerm };
  },
  [actionTypes.SUBMIT_SEARCH_RESULTS] : (state, action) => {
    
    // action.payload has a results property containing an array of search result objects, e.g.
    // [
    //   {
    //     "svc": "geonames",
    //     "place_id": "2841575",
    //     "lat": 49.22886,
    //     "lon": 7.01126,
    //     "boundingbox": [
    //       49.219870351248574,
    //       49.23785764875143,
    //       6.997484501600447,
    //       7.025033498399552
    //     ],
    //     "display_name": "Sankt Johann, Saarland, Deutschland",
    //     "timezone": "Europe/Berlin"
    //   },
    //   ...
    // ]
    
    // Mutate state to set the results
    state.results = action.payload.results || [];
  },
  [actionTypes.CLEAR_SEARCH_RESULTS] : (state, action) => {
    // Mutate state to clear the results
    state.results = [];
  },
  [actionTypes.RECEIVED_SERVICE_CREDENTIALS] : (state, action) => {

    // action.payload.credentials is an object as follows
    // {
    //   "service": "here",
    //   "supportsAutocomplete": true,
    //   "supportsLookup": true,
    //   "autocompleteDebounce": 600,
    //   "key": "[APIKEY]"
    // }

    // Mutate state to set the search service credentials
    state.serviceCredentials = action.payload.credentials;
  },
  [actionTypes.SELECT_SEARCH_RESULT] : (state, action) => {

    // Set the selected property on the search result corresponding to action.payload.placeId
    // Set selected to false on all other results - there can be only one.

    const reselectedResults = state.results.map( result => {
      if (result.place_id === action.payload.placeId) {
        result.isSelected = true;
      } else {
        result.isSelected = false;
      }
      return result;
    });

    state.results = reselectedResults;
  },
  [actionTypes.SEARCH_RESULTS_PENDING] : (state, action) => {

    return { ...state, resultsPending: action.payload.pending };
  }
});