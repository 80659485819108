import React from 'react'

const styling = {
  // fontFamily: "sans-serif",
  fontSize: "0.9rem",
  margin: "3rem", 
  padding: "1.4rem"
}

/**
 * This component is specifically designed to match the "pre-react" loading elements - see index.html for details
 */
export default function Loading() {
  return (
    <>
      <p style={styling}>Loading&hellip; <img src='ajax-loader.gif' alt=''/></p>
      <p style={styling}>Having trouble loading this site?&nbsp;
        {/* <a href="https://support.crookneckapps.com/hc/en-us/articles/360043783912-Why-won-t-the-site-load-" style={{color: "#1EAEDB"}}>Read more</a> */}
        <a rel="noopener noreferrer" href="https://support.crookneckapps.com/hc/en-us/articles/360043783912-Why-won-t-the-site-load-" target="_blank" >
          Read more
        </a>
      </p>
    </>
  )
}
