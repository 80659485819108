/*
 * action types
 */

export const actionTypes = {
  RESTORE_LAST_STATE: 'RESTORE_LAST_STATE',
  SYNCH_MAP_TO_QUERY_STRING: 'SYNCH_MAP_TO_QUERY_STRING',
  REQUEST_SVC_CREDENTIALS: 'REQUEST_SVC_CREDENTIALS',
  REQUEST_SVC_CREDENTIALS_SUCCESS: 'REQUEST_SVC_CREDENTIALS_SUCCESS',
  REQUEST_SVC_CREDENTIALS_FAILURE: 'REQUEST_SVC_CREDENTIALS_FAILURE',
  RESET_SVC_CREDENTIALS: 'RESET_SVC_CREDENTIALS',
  HANDLE_PRIMARY_PIN_DID_MOVE: 'HANDLE_PRIMARY_PIN_DID_MOVE',
  HANDLE_MAP_STATE_CHANGE: 'HANDLE_MAP_STATE_CHANGE', 
  UPDATE_MAP_STATE: 'UPDATE_MAP_STATE',
  UPDATE_ELEVATION: 'UPDATE_ELEVATION',
  UPDATE_TIMEZONE: 'UPDATE_TIMEZONE',
  QUERY_ELEVATION: 'QUERY_ELEVATION',
  QUERY_TIME_ZONE_ELEVATION: 'QUERY_TIME_ZONE_ELEVATION',
  QUERY_TIME_ZONE_ELEVATION_FAILURE: 'QUERY_TIME_ZONE_ELEVATION_FAILURE',
  WILL_LOAD_PREMIUM_MAPS: 'DID_LOAD_PREMIUM_MAPS',
  DID_LOAD_PREMIUM_MAPS: 'DID_LOAD_PREMIUM_MAPS',
  REQUEST_CURRENT_LOCATION: 'REQUEST_CURRENT_LOCATION',
  CURRENT_LOCATION_REQUEST_COMPLETE: 'CURRENT_LOCATION_REQUEST_COMPLETE',
  ENABLE_GEODETICS: 'ENABLE_GEODETICS',
  UPDATE_GEODETICS_COORDINATE: 'UPDATE_GEODETICS_COORDINATE',
  QUERY_SECONDARY_ELEVATION: 'QUERY_SECONDARY_ELEVATION',
  GEODETICS_ELEVATION_PENDING: 'GEODETICS_ELEVATION_PENDING', 
  UPDATE_GEODETICS: 'UPDATE_GEODETICS',
  SWAP_PIN_POSITIONS: 'SWAP_PIN_POSITIONS',
  UPDATE_HORIZON: 'UPDATE_HORIZON',
  CENTRE_MAP_ON_PRIMARY_PIN: 'CENTRE_MAP_ON_PRIMARY_PIN',
  EVALUATE_DEFAULT_COORDINATE: 'EVALUATE_DEFAULT_COORDINATE',
  VERIFY_ELEVATIONS: 'VERIFY_ELEVATIONS',
};

/*
* action creators
*/

export const actions = {
  restoreLastState: () => {
    return { type: actionTypes.RESTORE_LAST_STATE }
  },
  synchMapToQueryString: (search, source ) => {
    return { type: actionTypes.SYNCH_MAP_TO_QUERY_STRING, payload: { search: search, source: source } }
  },
  requestSvcCredentials: (serviceName) => {
    return { type: actionTypes.REQUEST_SVC_CREDENTIALS, payload: { service: serviceName } };
  },
  requestSvcCredentialsSuccess: (response) => {
    return { type: actionTypes.REQUEST_SVC_CREDENTIALS_SUCCESS, payload: response };
  }, /* TODO : no hard coded UI strings here thank you: */
  requestSvcCredentialsFailure: (errMsg = 'Failed to get required service details', resolution = '') => {
    return { type: actionTypes.REQUEST_SVC_CREDENTIALS_FAILURE, payload: { errMsg: errMsg, resolution: resolution } };
  },
  resetSvcCredentials: () => {
    return { type: actionTypes.RESET_SVC_CREDENTIALS };
  },
  handlePrimaryPinDidMove: (latLng) => {
    return {type: actionTypes.HANDLE_PRIMARY_PIN_DID_MOVE, payload: { lat: latLng.lat, lng: latLng.lng} };
  },
  handleMapStateChange: (changes) => {
    return { type: actionTypes.HANDLE_MAP_STATE_CHANGE, payload: changes };
  },
  updateMapState: (mapParams) => {
    return {type: actionTypes.UPDATE_MAP_STATE, payload: mapParams};
  },
  updateElevation: (elevationObj) => {
    return {type: actionTypes.UPDATE_ELEVATION, payload: elevationObj};
  },
  updateTimeZone: (timeZoneObj) => {
    return {type: actionTypes.UPDATE_TIMEZONE, payload: timeZoneObj};
  },
  queryElevation: () => {
    return {type: actionTypes.QUERY_ELEVATION };
  },
  queryTimeZoneElevation: (latLng) => {
    return {type: actionTypes.QUERY_TIME_ZONE_ELEVATION, payload: { lat: latLng.lat, lng: latLng.lng} };
  },
  queryTimeZoneElevationFailure: (errMsg) => {
    return {type: actionTypes.QUERY_TIME_ZONE_ELEVATION_FAILURE, payload: { errMsg: errMsg} };
  },
  willLoadPremiumMaps: () => {
    return { type: actionTypes.WILL_LOAD_PREMIUM_MAPS };
  },
  didLoadPremiumMaps: () => {
    return { type: actionTypes.DID_LOAD_PREMIUM_MAPS };
  },
  requestCurrentLocation: (requesting) => {
    return { type: actionTypes.REQUEST_CURRENT_LOCATION, payload: { requesting: requesting }};
  },
  currentLocationRequestComplete: () => {
    return { type: actionTypes.CURRENT_LOCATION_REQUEST_COMPLETE };
  },
  enableGeodetics: (enabled) => {
    return { type: actionTypes.ENABLE_GEODETICS, payload: { enabled: enabled }};
  },
  updateGeodetics: (changes) => {
    return { type: actionTypes.UPDATE_GEODETICS, payload: { geodetics: changes } };
  },
  updateGeodeticsCoordinate: (coordinate) => {
    return { type: actionTypes.UPDATE_GEODETICS_COORDINATE, payload: { coordinate: coordinate } };
  },
  querySecondaryElevation: () => {
    return { type: actionTypes.QUERY_SECONDARY_ELEVATION };
  },
  geodeticsElevationPending: (pending) => {
    return { type: actionTypes.GEODETICS_ELEVATION_PENDING, payload: { pending: pending }};
  },
  swapPinPositions: () => {
    return { type: actionTypes.SWAP_PIN_POSITIONS };
  },
  updateHorizon: (changes) => {
    return { type: actionTypes.UPDATE_HORIZON, payload: changes };
  },
  centreMapOnPrimaryPin: () => {
    return { type: actionTypes.CENTRE_MAP_ON_PRIMARY_PIN };
  },
  verifyElevations: () => {
    return { type: actionTypes.VERIFY_ELEVATIONS };
  }

}
