/*
 * action types
 */

export const actionTypes = {
  SET_DB_OPEN: 'SET_DB_OPEN',
  RESET_LOCATIONS: 'RESET_LOCATIONS',
  CLEAR_LOCATIONS: 'CLEAR_LOCATIONS',
  SYNCH_LOCATIONS: 'SYNCH_LOCATIONS',
  SYNCH_LOCATIONS_PENDING: 'SYNCH_LOCATIONS_PENDING',
  SET_LOCATIONS_ERROR: 'SET_LOCATIONS_ERROR',
  SET_SYNCH_TOKEN: 'SET_SYNCH_TOKEN',
  REQUEST_LOCATIONS: 'REQUEST_LOCATIONS',
  LOAD_LOCATIONS_FROM_STORE: 'LOAD_LOCATIONS_FROM_STORE',
  LOAD_LOCATIONS_PENDING: 'LOAD_LOCATIONS_PENDING',
  WRITE_LOCATIONS_PENDING: 'WRITE_LOCATIONS_PENDING',
  SELECT_LOCATION: 'SELECT_LOCATION',
  EDIT_LOCATION: 'EDIT_LOCATION',
  MOVE_TO_LOCATION: 'MOVE_TO_LOCATION',
  SET_SAVED_LOCATIONS_FILTER: 'SET_SAVED_LOCATIONS_FILTER',
  ADD_LOCATION: 'ADD_LOCATION',
  UPDATE_LOCATION: 'UPDATE_LOCATION',
  PRE_DELETE_LOCATION: 'PRE_DELETE_LOCATION',
  DELETE_LOCATION: 'DELETE_LOCATION',
  POST_DELETE_CLEANUP: 'POST_DELETE_CLEANUP',
  DELETE_ALL_LOCATIONS: 'DELETE_ALL_LOCATIONS',
  MODIFY_LOCATION_STATE: 'MODIFY_LOCATION_STATE',
  IMPORT_LOCATIONS: 'IMPORT_LOCATIONS'
}

/*
* action creators
*/
export const actions = {
  setDatabaseOpen: (dbIsOpen) => {
    return { type: actionTypes.SET_DB_OPEN, payload: { dbIsOpen: dbIsOpen } };
  },
  resetLocations: () => {
    return { type: actionTypes.RESET_LOCATIONS };
  },
  clearLocations: () => {
    return { type: actionTypes.CLEAR_LOCATIONS };
  },
  /**
   * Action creator to initiate a location synch. 
   */
  synchLocations: () => {
      return { type: actionTypes.SYNCH_LOCATIONS };
  },
  synchLocationsPending: (pending, storedCount) => {
    return { type: actionTypes.SYNCH_LOCATIONS_PENDING, payload: { pending: pending, storedCount: storedCount } }
  },
  setLocationsError: (error) => {
    return { type: actionTypes.SET_LOCATIONS_ERROR, payload: error }
  },
  setSynchToken: (synchToken, serverTime) => {
    return { type: actionTypes.SET_SYNCH_TOKEN, payload: { synchToken: synchToken, serverTime: serverTime }};
  },
  requestLocationsInRange: (start, end) => {
    return { type: actionTypes.REQUEST_LOCATIONS, payload: { startIndex: start, endIndex: end } };
  },
  loadLocationsFromStore: (locations) => {
    return { type: actionTypes.LOAD_LOCATIONS_FROM_STORE, payload: locations };
  },
  loadLocationsPending: (pending) => {
    return { type: actionTypes.LOAD_LOCATIONS_PENDING, payload: { pending: pending } };
  },
  writeLocationsPending: (pending) => {
    return { type: actionTypes.WRITE_LOCATIONS_PENDING, payload: { pending: pending } };
  },
  selectLocation: (locationUUID) => {
    return { type: actionTypes.SELECT_LOCATION, payload: { uuid: locationUUID } };
  },
  editLocation: (locationUUID) => {
    return { type: actionTypes.EDIT_LOCATION, payload: { uuid: locationUUID } };
  },
  moveToLocation: (locationUUID, useSecondary, sourcePath) => {
    return { type: actionTypes.MOVE_TO_LOCATION, payload: { uuid: locationUUID, useSecondary: useSecondary, sourcePath: sourcePath } };
  },
  setSavedLocationsFilter: (field, value, source, favourites = false) => {
    return { type: actionTypes.SET_SAVED_LOCATIONS_FILTER, payload: { field: field, value: value, source: source, favourites: favourites } };
  },
  /**
   * Action creator to add a new saved location. In all cases, the location uuid, useruuid, createdAt and lastUpdatedAt properties are set
   * by the saga that handles this action.
   * @param {Boolean} willEdit flag to indicate if newly created location should be set as the editing location after creation
   * @param {Object} location optional object containing data for the new object; if omitted, a new location is created from the current map state
   */
  addLocation: (willEdit, location) => {
    return { type: actionTypes.ADD_LOCATION, payload: { willEdit: willEdit, location: location } };
  },
  updateLocation: (uuid, changes) => {
    return { type: actionTypes.UPDATE_LOCATION, payload: { uuid: uuid, changes: changes } };
  },
  /**
   * Action creator to update state with the uuid of a location user intends to delete. This is provided so that
   * we have an intermediate state before actual deletion during which we can update the UI to confirm the user's intent.
   * Specifically, it allows us to close a modal edit form and pop-up an alert dialog without losing track of which location
   * we wanted to delete.
   * @param {String} uuid uuid of the location intended to be deleted
   * @param {Boolean} resumeEditingIfDeletionCancelled flag to indicate if editing of the location should be resumed if user cancels the deletion
   */
  preDeleteLocation: (uuid, resumeEditingIfDeletionCancelled) => {
    return { type: actionTypes.PRE_DELETE_LOCATION, payload: { uuid: uuid, resumeEditingIfDeletionCancelled: resumeEditingIfDeletionCancelled } };
  },
  deleteLocation: (uuid) => {
    return { type: actionTypes.DELETE_LOCATION, payload: { uuid: uuid} };
  },
  postDeleteCleanup: () => {
    return { type: actionTypes.POST_DELETE_CLEANUP };
  },
  /**
   * Action creator to update state with the given location data and operation. For an add operation, a complete
   * location object should be passed; for 'update' location may include only changes; for 'delete' only a uuid is required
   * @param {String} operation one of 'add', 'update', 'delete
   * @param {Object} location a partial or complete location object. Must include a uuid property. See method description for further details.
   */
  modifyLocationState: (operation, location ) => {
    return { type: actionTypes.MODIFY_LOCATION_STATE, payload: { operation: operation, location: location }};
  },
  importLocations: (locations) => {
    return { type: actionTypes.IMPORT_LOCATIONS, payload: locations };
  },
  deleteAllLocations: () => {
    return { type: actionTypes.DELETE_ALL_LOCATIONS };
  }
}