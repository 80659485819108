import { createReducer } from '@reduxjs/toolkit';
import JwtDecode from 'jwt-decode';
import log from 'loglevel';

import { actionTypes as userProfileActionTypes } from '../actions/userProfileActions';
import { actionTypes as authActionTypes } from '../actions/authActions';

const initialState = {
  auth: {
    pending: undefined
  },
  signIn: {
    pending: undefined,
    error: undefined
  },
  accountCreation: {
    pending: undefined,
    created: undefined,
    error: undefined,
    details: undefined
  },
  profileManagement: {
    source: undefined,
    pending: undefined,
    successful: undefined,
    error: undefined
  },
  help: {
    zendeskLoaded: undefined
  },
  cookies: {
    accepted: undefined
  }
};

const signInSuccessState = {
  signIn: {
    pending: undefined,
    error: undefined
  }
};

const processToken = (token) => {
	var decoded = {};

	try {
		decoded = JwtDecode(token);
	} catch (error) {
		log.error(error);
	}

	const result = {
		token: token,
		decodedToken: decoded
	};

	return result;
};

/**
 * Note: this uses redux-toolkit createReducer so we can write mutating code for state
 * See https://redux-toolkit.js.org/api/createReducer
 */
export const userProfile = createReducer(initialState, {
  [userProfileActionTypes.SIGN_IN_PENDING] : (state, action) => {

    const signIn = {
      pending: action.payload.pending,
      error: state.signIn.error
    }

    return { ...state, signIn: signIn };
  },
  [userProfileActionTypes.SIGN_IN_SUCCESS] : (state, action) => {

    return { ...state, uuid: action.payload.useruuid };
  },
  [userProfileActionTypes.SIGN_IN_FAILURE] : (state, action) => {
    
    const signIn = {
      pending: false,
      error: action.payload
    }

    return { ...state, signIn: signIn };
  },
  [userProfileActionTypes.GET_PROFILE_PENDING] : (state, action) => {

    const profileManagement = {
      source: action.payload.source,
      pending: true
    };

    return { ...state, profileManagement: profileManagement };
  },
  [userProfileActionTypes.UPDATE_WITH_PROFILE] : (state, action) => {

    const profileManagement = {
      source: state.profileManagement.source,
      pending: false,
      successful: true,
      error: undefined
    };

    return { ...state, ...action.payload, profileManagement: profileManagement };
  },
  [userProfileActionTypes.GET_PROFILE_FAILURE] : (state, action) => {

    const profileManagement = {
      source: state.profileManagement.source,
      pending: false,
      successful: false,
      error: action.payload
    };

    return { ...state, profileManagement: profileManagement };
  },
  [userProfileActionTypes.PUT_PROFILE_PENDING] : (state, action) => {

    const profileManagement = {
      source: action.payload.source,
      pending: true
    };

    return { ...state, profileManagement: profileManagement };
  },
  [userProfileActionTypes.PUT_PROFILE_SUCCESS] : (state, action) => {

    const profileManagement = {
      source: state.profileManagement.source,
      pending: false,
      successful: true,
      error: undefined
    };

    return { ...state, profileManagement: profileManagement };
  },
  [userProfileActionTypes.PUT_PROFILE_FAILURE] : (state, action) => {

    const profileManagement = {
      source: state.profileManagement.source,
      pending: false,
      successful: false,
      error: action.payload
    };

    return { ...state, profileManagement: profileManagement };
  },
  [userProfileActionTypes.PUT_PROFILE_FINISHED] : (state, action) => {
    // Reset
    const profileManagement = {
      source: undefined,
      pending: undefined,
      successful: undefined,
      error: undefined
    }

    return { ...state, profileManagement: profileManagement };
  },
  [userProfileActionTypes.CREATE_ACCOUNT_PENDING] : (state, action) => {
    
    const accountCreation = {
      pending: true,
      created: undefined,
      error: state.accountCreation.error,
      details: action.payload
    }
    
    return { ...state, accountCreation: accountCreation };
  },
  [userProfileActionTypes.CREATE_ACCOUNT_SUCCESS] : (state, action) => {

    const accountCreation = {
      pending: false,
      created: true,
      error: undefined,
      details: action.payload
    }

    return { ...state, accountCreation: accountCreation };
  },
  [userProfileActionTypes.CREATE_ACCOUNT_FAILURE] : (state, action) => {

    const accountCreation = {
      pending: false,
      created: false,
      error: action.payload,
      details: undefined
    }

    return { ...state, accountCreation: accountCreation };
  },
  [userProfileActionTypes.CLEAR_SIGN_IN_ERROR] : (state, action) => {

    // We reuse the signIn object for errors for this request, so reset it here when we make the API call in userProfileSaga
    const signIn = {
      pending: false
    }

    return { ...state, signIn: signIn };
  },
  [userProfileActionTypes.ZENDESK_LOADED] : (state, action) => {

    const help = { ...state.help, zendeskLoaded: action.payload.loaded };
    return { ...state, help: help };
  },
  [authActionTypes.REFRESH_AUTH_PENDING] : (state, action) => {
    return { ...state, auth: { pending: action.payload.pending } };
  },
  [authActionTypes.UPDATE_AUTH_INFO] : (state, action) => {
    
    if (action && action.payload && action.payload.token) {  
      // We have a token
      const processedTokenState = processToken(action.payload.token);
      if (processedTokenState.token) {
        return {
          ...state,
          ...processedTokenState,
          ...signInSuccessState
        };
      }
    } else {
      log.error('UPDATE_AUTH_INFO action: missing payload or token', action);
    }
    // default - return unmutated state
    return state;
  },
  [authActionTypes.CLEAR_AUTH_INFO] : (state, action) => {
    // Reset to initial state - this removes all user profile info also (desired)
    const profileManagement = state.profileManagement;
    const accountCreation = state.accountCreation;
    const help = state.help;

    return { ...initialState, profileManagement: profileManagement, accountCreation: accountCreation, help: help };
  }
});

