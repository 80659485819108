import queryString from 'query-string';
import { clamp, has, cloneDeep } from 'lodash';
import moment from 'moment-timezone'
import log from 'loglevel';

const URL_DATETIME_FORMAT = "YYYYMMDDHHmmssZZ";

export function buildQueryString(state, dateTime, search) {

  let qs = {};

  if (search) {
    const parsed = queryString.parse(search);
    // A copy that we can mutate
    qs = cloneDeep(parsed);
  }

  // Map zoom, span
  if (state.zoom) {
    qs.z = state.zoom.toFixed(0);
    // Remove any span - zoom overrides it
    delete qs.spn;
  } else if (state.span) {
    // Handle span only if no zoom is specified - e.g. from map bounds or a geocoding search results
    // See also searchSaga.js handleMoveToSearchResult()
    qs.spn = state.span.lat.toFixed(6) + ',' + state.span.lng.toFixed(6);
  }
  
  // Map center
  if (state.center) {
    qs.center = state.center.lat.toFixed(6) + ',' + state.center.lng.toFixed(6);
  }

  // Primary coordinate
  if (state.coordinate) {
    qs.ll = state.coordinate.lat.toFixed(6) + ',' + state.coordinate.lng.toFixed(6);
  }

  // Geodetics
  if (state.geodetics && state.geodetics.enabled) {
    qs.sll = state.geodetics.coordinate.lat.toFixed(6) + ',' + state.geodetics.coordinate.lng.toFixed(6);
  } else {
    delete qs.sll;
  }

  // Object Heights
  qs.sh = 0;
  if (state.objectHeights && !isNaN(state.objectHeights.primary)) {
    qs.sh = state.objectHeights.primary
  }
  qs.ch = 0;
  if (state.objectHeights && !isNaN(state.objectHeights.secondary)) {
    qs.ch = state.objectHeights.secondary
  }

  // Object footprints (width/length)
  qs.sw = 0;
  if (state.objectFootprint && !isNaN(state.objectFootprint.primary)) {
    qs.sw = state.objectFootprint.primary
  }
  qs.cw = 0;
  if (state.objectFootprint && !isNaN(state.objectFootprint.secondary)) {
    qs.cw = state.objectFootprint.secondary
  }

  // Date/time
  if (dateTime) {
    qs.dt = moment(dateTime).format(URL_DATETIME_FORMAT);
  }

  const result = queryString.stringify(qs, { encode: false })

  return result
}

export function deriveMapState(search, includeGeodetics = false) {

  var parsed = queryString.parse(search, { arrayFormat: 'comma' });
  log.debug("Parsed browser search:", parsed);

  var mapState = {}

  if (!parsed || !search) {
    return mapState;
  }

  if (parsed.ll && parsed.ll.length === 2) {
    mapState.coordinate = {
      lat: clamp(parseFloat(parsed.ll[0]), -90, 90),
      lng: clamp(parseFloat(parsed.ll[1]), -180, 180)
    };
  }

  if (parsed.z) {
    // Zoom must be an integer for this app - although TPE for iOS will spit out decimal point zoom levels
    mapState.zoom = clamp(parseInt(parsed.z), 1, 21);
  }

  if (parsed.center && parsed.center.length === 2) {
    mapState.center = {
      lat: clamp(parseFloat(parsed.center[0]), -90, 90),
      lng: clamp(parseFloat(parsed.center[1]), -180, 180)
    };
  }

  if (parsed.spn && parsed.spn.length === 2) {
    mapState.span = {
      lat: clamp(parseFloat(parsed.spn[0]), -90, 90),
      lng: clamp(parseFloat(parsed.spn[1]), -180, 180)
    };
  }

  // Object heights
  let objectHeights = {}
  let includeObjectHeights = false
  if (parsed.sh) {
    objectHeights.primary = parseFloat(parsed.sh)
    includeObjectHeights = true
  }

  if (parsed.ch) {
    objectHeights.secondary = parseFloat(parsed.ch)
    includeObjectHeights = true
  }

  if (includeObjectHeights === true) {
    mapState.objectHeights = objectHeights
  }

  // Object footprint
  let objectFootprint = {}
  let includeObjectFootprint = false
  if (parsed.sw) {
    objectFootprint.primary = parseFloat(parsed.sw)
    includeObjectFootprint = true
  }

  if (parsed.cw) {
    objectFootprint.secondary = parseFloat(parsed.cw)
    includeObjectFootprint = true
  }

  if (includeObjectFootprint === true) {
    mapState.objectFootprint = objectFootprint
  }

  // Catch all to center map on primary pin, if no center given
  if (has(mapState, "coordinate") && has(mapState, "center") === false) {
    mapState.center = mapState.coordinate;
  }

  // Check if secondary pin position is included
  if (parsed.sll && parsed.sll.length === 2) {
    const secondary = {
      lat: clamp(parseFloat(parsed.sll[0]), -90, 90),
      lng: clamp(parseFloat(parsed.sll[1]), -180, 180)
    }
    mapState.geodetics = {
      coordinate: secondary
    }
  }

  return mapState;
}