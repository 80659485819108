import React from "react";
import { useSelector, useDispatch } from 'react-redux';

import i18n from 'i18n';
import log from 'loglevel';
import { logCentral } from 'services/log-central';

import { getSettings } from 'selectors';
import { getIsSignedIn } from 'reselectors/userProfile';
import { actions } from 'actions/settingsActions';

import './languageSelection.css';

export default function LanguageSelection() {

  const dispatch = useDispatch();
  const { lang, availableLanguages } = useSelector(getSettings);
  const isSignedIn = useSelector(getIsSignedIn);
  
  var selectedValue;
  if (isSignedIn) {
    selectedValue = lang || i18n.languages[0] || 'en';
  } else {
    selectedValue = i18n.languages[0] || 'en';
  }

  const handleOnChange = (e) => {
    
    const result = e.target.value || 'en';

    if (result === selectedValue) {
      return;
    }

    if (isSignedIn) {
      dispatch(actions.deriveUpdatedSettings( { lang: result }));
    } else {
      i18n.changeLanguage(result)
      .then((t) => {
        log.debug('Language changed to ' + result);
      })
      .catch((reason) => {
        log.error(reason);
      })
    }

    logCentral.event({
      category: "UI",
      action: "language_selected",
      label: result
    });
    
  }

  return (

    <form className="inline-block">
      <select name="language" className="cursor-pointer w-20 h-5 py-0 text-sm bg-transparent border-1 border-regent-gray-600 focus:outline-none" value={selectedValue} onBlur={handleOnChange} onChange={handleOnChange}>
        {availableLanguages &&
          availableLanguages.map((language, index) => (
            <option key={index} value={language}>{language}</option>
          ))
        }
      </select>
    </form>
  );
}
