import { createReducer } from '@reduxjs/toolkit'
import moment from "moment-timezone"

import { actionTypes } from "../actions/visualSearchActions"

import RiseTransitSetEvents from '@stephent/meeusjs/lib/risetransitsetevents'

const initialState = {
  parameters: {
    body: RiseTransitSetEvents.EVENT_KEYS.MESunPosition,
    azimuth: 90,
    apparentAltitude: 6,
    azimuthTolerance: 4,
    altitudeTolerance: 4,
    duration: moment.duration(6, 'months').asMilliseconds(),
    altitudePriority: true,
    rangeMode: true,
    discAlignment: 0 /* -1: lower limb; 0: centre; +1: upper limb */
  },
  results: [],
  rangeResults: [],
  isSearching: false,
  filters: []
}

/**
 * Note: this uses redux-toolkit createReducer so we can write mutating code for state
 * See https://redux-toolkit.js.org/api/createReducer
 */
export const visualSearch = createReducer(initialState, {
  [actionTypes.UPDATE_SEARCH_PARAMETERS] : (state, action) => {

    const updatedParams = { ...state.parameters, ...action.payload }

    return { ...state, parameters: updatedParams }
  },
  [actionTypes.CLEAR_RESULTS] : (state, action) => {
    
    state.results = []
    state.rangeResults = []
  },
  [actionTypes.ADD_RESULTS] : (state, action) => {
    
    state.results = [ ...state.results, ...action.payload ]
  },
  [actionTypes.ADD_RANGE_RESULTS] : (state, action) => {
    
    state.rangeResults = [ ...state.rangeResults, ...action.payload ]
  },
  [actionTypes.SET_FILTERS] : (state, action) => {

    state.filters = action.payload
  }
});