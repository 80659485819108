import { takeEvery, put, select } from 'redux-saga/effects'
import moment from 'moment-timezone'

import { AzAltSearch } from '@stephent/meeusjs/lib/azaltsearch'

import SolarCoordinates from '@stephent/meeusjs/lib/solarcoordinates'
import LunarCoordinates from '@stephent/meeusjs/lib/lunarcoordinates'
import StarCoordinates from '@stephent/meeusjs/lib/starcoordinates'
import RiseTransitSetEvents from '@stephent/meeusjs/lib/risetransitsetevents'
// import { AzAltSearch } from '@stephent/meeusjs/lib/azaltsearch'

import { logCentral } from 'services/log-central'

import { getCoordinate, getElevation, getVisualSearch } from 'selectors'
import { getElevationAboveHorizon } from 'reselectors'
import { getDateTimeAsMoment } from 'reselectors/dateTime'
import { actionTypes, actions } from 'actions/visualSearchActions'

function* executeSearch(action) {

  yield put(actions.clearResults())
  yield put(actions.updateSearchParameters({ isSearching: true }))

  const mo = yield select(getDateTimeAsMoment)
  const coord = yield select(getCoordinate)
  const elev = yield select(getElevation)
  const eah = yield select(getElevationAboveHorizon)

  const { duration, fromToday } = action.payload

  // It's important to set the time zone if we are searching from today - can't call utcOffset deeper in the code otherwise. Bad juju...
  const moFrom = fromToday ? moment().tz(mo.tz()).startOf('day') : mo.clone().startOf('day')
  const moTo = moFrom.clone().add(duration, 'milliseconds')

  const vss = yield select(getVisualSearch)
  const { body, azimuth, apparentAltitude, azimuthTolerance, altitudeTolerance, altitudePriority, rangeMode, discAlignment } = vss.parameters

  var coordinatesProvider
  switch (body) {
    case (RiseTransitSetEvents.EVENT_KEYS.MEGalacticCentrePosition):
      coordinatesProvider = StarCoordinates.GalacticCentre()
      break;
    case (RiseTransitSetEvents.EVENT_KEYS.MEMoonPosition):
      coordinatesProvider = new LunarCoordinates()
      break;
    case (RiseTransitSetEvents.EVENT_KEYS.MESunPosition):
    default:
      coordinatesProvider = new SolarCoordinates()
      break;
  }

  const targets = {
    azimuth: parseFloat(azimuth),
    apparentAltitude: parseFloat(apparentAltitude)
  }
  const tolerances = {
    azimuth: parseFloat(azimuthTolerance),
    apparentAltitude: parseFloat(altitudeTolerance)
  }
  const azAltSearch = new AzAltSearch(coordinatesProvider, moFrom, moTo, targets, tolerances, discAlignment)

  try {
    if (rangeMode === true) {
      const results = azAltSearch.findRangeMatches({ lat: coord.lat, lng: -coord.lng, observerElevationAboveMSL: elev.value || 0, heightAboveHorizon: eah })
      const cleanResults = results.map(result => result.hits).filter(hits => hits.length > 0)
      yield put(actions.addRangeResults(cleanResults))
    } else {
      const interpolationKey = altitudePriority ? 'apparentAltitude' : 'azimuth'
      const results = azAltSearch.findMatches({ lat: coord.lat, lng: -coord.lng, observerElevationAboveMSL: elev.value || 0, heightAboveHorizon: eah }, interpolationKey)
      const cleanResults = results.map(result => result.hits).flat().filter(hit => hit)
      yield put(actions.addResults(cleanResults))
    }
  } catch (e) {
    logCentral.error(rangeMode === true ? 'Find range matches' : 'Find matches', e);
  } finally {
    yield put(actions.updateSearchParameters({ isSearching: false }))
  }

}

/**
 saga watcher that is triggered when dispatching action of type 'SIGN_IN'
  */
export function* visualSearchSagas() {

  // Visual Search actions
  yield takeEvery(actionTypes.EXECUTE_SEARCH, executeSearch)
}
