import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { logCentral } from 'services/log-central';

import { getUserProfile } from 'selectors';

export function useProfitwell() {

  const profitwellRef = useRef();
  const [isStarted, setIsStarted] = useState(false);

  const { email } = useSelector(getUserProfile);

  useEffect(() => {

    if (email && !isStarted) {
      const profitwell = window?.profitwell;
      if (profitwell) {
        profitwellRef.current = profitwell;

        try {
          profitwell('user_email', email);
          setIsStarted(true);  
        } catch (error) {
          logCentral('Could not set profitwell user email', error);
        }
      }
    }

  }, [email, isStarted])
  
  return profitwellRef.current;
  
}