import { takeEvery, takeLatest, put, select } from "redux-saga/effects";
import queryString from "query-string";
import moment from 'moment-timezone';
import { has } from 'lodash';
import log from 'loglevel';

import { getDateTime } from "selectors";
import { getNextEvent, getPreviousEvent } from "reselectors/dateTime";
import { actionTypes as dateActionTypes, actions as dateActions } from "actions/dateActions";
import { actions as rootActions, QS_SOURCES } from "actions/rootActions";
import { logCentral } from "services/log-central";

const URL_DATETIME_FORMAT = "YYYYMMDDHHmmssZZ";

const YEAR_3000_DATETIME_MS = 32503705200000 // Jan 1 3000

function* updateDate(action) {

  try {
    const dateTime = action?.payload?.dateTime;
    if (!dateTime) {
      throw new Error('dateTime missing from action payload');
    }

    if (dateTime > YEAR_3000_DATETIME_MS) {
      log.warn('updateDate: discarding far future date', dateTime);
      return;
    }

    yield put(dateActions.setDateTime(action.payload.dateTime, QS_SOURCES.userInteraction));
    yield put(rootActions.deriveUpdatedQueryString());
  } catch (error) {
    logCentral.error('dateSaga.updateDate', error);
  }

}

function* updateTimeOfDay(action) {

  yield put(dateActions.setDateTime(action.payload.dateTime, QS_SOURCES.userInteraction));

  if (action.payload.isDone === true) {
    // Don't try to push every single change in time of day to the browser history
    yield put(rootActions.deriveUpdatedQueryString());
  }
}

function* nextDay(action) {

  const dt = yield select(getDateTime);

  const next = moment(dt).add(1, 'day');
  log.debug("nextDay", dt, next.format());
  yield put(dateActions.setDate(next.valueOf()));
}

function* previousDay(action) {

  const dt = yield select(getDateTime);

  const prev = moment(dt).add(-1, 'day');
  yield put(dateActions.setDate(prev.valueOf()));
}

function* nextEvent(action) {

  const nextEvent = yield select(getNextEvent);

  if (nextEvent) {
    yield put(dateActions.setDate(nextEvent.moment.valueOf()));
  } else {
    log.warn('nextEvent: no event found');
  }

}

function* previousEvent(action) {

  const previousEvent = yield select(getPreviousEvent);

  if (previousEvent) {
    yield put(dateActions.setDate(previousEvent.moment.valueOf()));
  } else {
    log.warn('previousEvent: no event found');
  }

}

/**
 * A saga that is run at app start-up to read the map state from the browser location query parameters, if any, and to synchronize state
 */
function* synchDateToQueryString(action) {

  const { search, source } = action.payload;
  const parsed = queryString.parse(search, { arrayFormat: 'comma' });
  // log.debug("Parsed browser search:", parsed);

  if (has(parsed, "dt")) {
    log.debug(`Synching date to query string ${search} from ${source}`);

    // So TPE for iOS (and probably all the other versions) are spitting out URLs without properly encoding the '+' symbol in
    // dt param value (see https://github.com/sindresorhus/query-string/issues/305). Hence, here, to maintain backward compatibility
    // we stuff the '+' back in, replacing the space that queryString parses from it. This ensures we actually pick up the correct time 
    // zone from the URL. And if the '+' was already properly encoded, the next line is a no-op anyway:
    const fixedDt = parsed.dt.replace(' ', '+');

    const mo = moment(fixedDt, URL_DATETIME_FORMAT);
    if (mo.isValid()) {
      log.debug("Updating dateTime state from query params:", mo);
      yield put(dateActions.setDateTime(mo.valueOf(), source));
    }
  }

}

/**
 saga watcher that is triggered when dispatching action of type 'SIGN_IN'
  */
export function* dateSagas() {
  // yield fork(synchDateToBrowserLocation);

  // Top level actions
  yield takeEvery(dateActionTypes.SYNCH_DATE_TO_QUERY_STRING, synchDateToQueryString);

  // Date actions
  yield takeEvery(dateActionTypes.SET_DATE, updateDate);
  yield takeLatest(dateActionTypes.SET_TIME_OF_DAY, updateTimeOfDay);
  yield takeEvery(dateActionTypes.NEXT_DAY, nextDay);
  yield takeEvery(dateActionTypes.PREVIOUS_DAY, previousDay);
  yield takeEvery(dateActionTypes.NEXT_EVENT, nextEvent);
  yield takeEvery(dateActionTypes.PREVIOUS_EVENT, previousEvent);
}
