import React from 'react';
import StillHavingTrouble from './StillHavingTrouble';

function SecurityError({ eventId }) {
  return (
    <div className="p-8 bg-regent-gray-100 border border-cinnabar-500 border-l-6 my-4">
      <h4 className="text-cinnabar-500">Partially downloaded</h4>
      <p className="pb-2 my-4">
        It looks like you may have attempted to open this page from a file saved to disk, which is not supported.
      </p>
      <p className="pb-2 my-4">
        Please access the site through the correct URL at <a href='https://app.photoephemeris.com'>app.photoephemeris.com</a>.
      </p>
      <hr className="my-4" />
      <StillHavingTrouble eventId={eventId} />
    </div>
  )
}

export default SecurityError