import React from 'react';

// Adapted from code linked on this page: https://threejs.org/docs/index.html#manual/en/introduction/WebGL-compatibility-check

const WEBGL = {

	isWebGLAvailable: function () {

		try {
			var canvas = document.createElement( 'canvas' );
      const result = !! ( window.WebGLRenderingContext && ( canvas.getContext( 'webgl' ) || canvas.getContext( 'experimental-webgl' ) ) );
      canvas.remove();
      return result;
		} catch ( e ) {
			return false;
		}
	},

	isWebGL2Available: function () {

		try {
			var canvas = document.createElement( 'canvas' );
			const result = !! ( window.WebGL2RenderingContext && canvas.getContext( 'webgl2' ) );
      canvas.remove();
      return result;
		} catch ( e ) {
			return false;
		}
	},

	getWebGLErrorMessage: function () {
		return this.getErrorMessage( 1 );
	},

	getWebGL2ErrorMessage: function () {
		return this.getErrorMessage( 2 );
	},

  getErrorMessage: function( version ) {

    const names = {
			1: 'WebGL',
			2: 'WebGL 2'
		};

		const contexts = {
			1: window.WebGLRenderingContext,
			2: window.WebGL2RenderingContext
		};

    var thing = 'browser';
    if (contexts[version]) {
			thing = 'graphics card';
		}

    const result = (
      <>Your {thing} does not seem to support <a href="http://khronos.org/webgl/wiki/Getting_a_WebGL_Implementation" target="_blank" rel="noreferrer">{names[version]}</a></>
    )

    return result;
  }

};

export { WEBGL };