import React from 'react';
import StillHavingTrouble from './StillHavingTrouble';

function ChunkLoadError({ eventId }) {
  return (
    <div className="p-8 bg-regent-gray-100 border border-picton-blue-500 border-l-6 my-4">
      <h4 className="text-picton-blue-500">Application updated</h4>
      <p className="pb-2 my-4">
        <strong>Please reload</strong> this page to view the current version.
      </p>
      <hr className="my-4" />
      <StillHavingTrouble eventId={eventId} />
    </div>
  )
}

export default ChunkLoadError