import { createSelector } from 'reselect';
import JwtDecode from 'jwt-decode';
import log from 'loglevel';

import { getUserProfile, getAuthToken } from '../selectors/';

export const getIsSignedIn = createSelector(
  [getUserProfile],
  (userProfile) => {
    
    // User is deemed to be signed in if they have managed to obtain a
    // token and a user UUID from CPA. Whether the token is valid or not
    // doesn't matter as with each request, we check expiry and refresh it if necessary
    // If refresh fails, we update state to signed out, which clears these properties
    // anyway.
    if (userProfile && userProfile.token && userProfile.uuid) {
      return true;
    }

    // No token or no uuid
    return false;
  }
)

/**
 * Heuristic to assess whether user is a new visitor or not. Intended to help determine whether to direct user to 
 * an acquisition landing page or not.
 * @returns {Boolean} true if user has previously signed in on this device and has accepted cookies
 */
export const getHasVisitedPreviously = createSelector(
  [getUserProfile],
  (userProfile) => {

    // If user has not signed in before, return false
    try {
      const userHasSignedIn = localStorage.getItem('userHasPreviouslySignedIn');
      if (!userHasSignedIn) {
        return false;
      }  
    } catch (error) {
      log.error('Could not get item from localStorage');
      return false;
    }
    
    return true;
  }
)

export const getDecodedJWT = createSelector(
  [getAuthToken],
  (jwt) => {

    if (!jwt) {
      return null;
    }

    var decoded = {};

    try {
      decoded = JwtDecode(jwt);
    } catch (error) {
      log.error('decodedJWT: ' + error.message);
    }

    return decoded;
  }
)

export const getEntitlements = createSelector(
  [getDecodedJWT],
  (token) => {
    
    if (token && token.entitlements && Array.isArray(token.entitlements)) {
      return token.entitlements;
    }

    return [];

  }
)

export const getHasProEntitlement = createSelector(
  [getEntitlements],
  (entitlements) => {

    if (entitlements.includes(process.env.REACT_APP_ENTITLEMENT_PRO)) {
      return true;
    }

    return false;
  }
)

/**
 * Returns the highest level Skyfire entitlement found for the user, or null
 * @returns {String|null} REACT_APP_ENTITLEMENT_SKYFIRE_PLUS, REACT_APP_ENTITLEMENT_SKYFIRE, or null
 */
export const getSkyfireEntitlement = createSelector(
  [getEntitlements],
  (entitlements) => {

    if (entitlements.includes(process.env.REACT_APP_ENTITLEMENT_SKYFIRE_PLUS)) {
      return process.env.REACT_APP_ENTITLEMENT_SKYFIRE_PLUS;
    }

    if (entitlements.includes(process.env.REACT_APP_ENTITLEMENT_SKYFIRE)) {
      return process.env.REACT_APP_ENTITLEMENT_SKYFIRE;
    }

    return null;
  }
)
