import { createReducer } from '@reduxjs/toolkit';
import moment from "moment-timezone";

import { actionTypes } from "../actions/dateActions";

const initialState = {
  source: null,
  selectedDateTime: moment().valueOf(),
  isAdjustingTimeOfDay: false
}

/**
 * Note: this uses redux-toolkit createReducer so we can write mutating code for state
 * See https://redux-toolkit.js.org/api/createReducer
 */
export const dateTime = createReducer(initialState, {
  [actionTypes.SET_DATETIME] : (state, action) => {
    return { ...state, selectedDateTime: action.payload.dateTime, source: action.payload.source };
  },
  [actionTypes.UPDATE_IS_ADJUSTING_TIME_OF_DAY] : (state, action) => {
    state.isAdjustingTimeOfDay = action.payload.isAdjusting;

    if (action.payload.refresh === true) {
      // add/subtract a millisecond to force re-render for anything dependent on date
      state.selectedDateTime = state.selectedDateTime + (action.payload.isAdjusting ? 1 : -1); 
    }
   
  } 
});